import styled from "styled-components"

export const ContainerStyled = styled.div`
    padding 100px 0 40px 0;
    text-align: center;
`;

export const TitleStyled = styled.div`
    font-weight: 700;
    font-size: 24px;
    color: #0C1D59;
    margin-bottom: 20px;
`;

export const SubTitleStyled = styled.div`
    margin-bottom: 20px;
`;