import { PlansCardContainer } from "./styles"
import Button from "../Button"

export default function PlansCard( { number, price, content, route } ){

    return (
        <PlansCardContainer>
            <span className="quantity">{ number }</span>
            <h4>R$ { price.toLocaleString('PT') }</h4>
            <p>por profissional médico</p>
            <ul>
                {
                    content.map((el, index ) => {
                        return(
                            <li key={ index }>
                                <div>
                                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13 24.25C19.2132 24.25 24.25 19.2132 24.25 13C24.25 6.7868 19.2132 1.75 13 1.75C6.7868 1.75 1.75 6.7868 1.75 13C1.75 19.2132 6.7868 24.25 13 24.25Z" stroke="#00C2FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M9.25 13L11.75 15.5L16.75 10.5" stroke="#00C2FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </div>
                                <div>
                                    { el }
                                </div>
                            </li>
                        )
                    })
                }
            </ul>
            <Button
                text="Quero contratar"
                theme="primary"
                width="70%"
                height="15%"
                route={route}
            />
        </PlansCardContainer>
    )
}