export default function ScrollTo(id, el) {
    const violation = document.getElementById(id);
    let actives = [...document.getElementsByTagName("a")];
    actives.forEach(element => {
        element.classList.remove('active');
    });
    if(el){
        el.target.parentElement.classList.add('active');
    }
    window.scrollTo({
        top: violation.offsetTop - 130,
        behavior: "smooth"
    });
}