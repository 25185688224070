import { TermsStyled } from "./styles";
import ScrollTo from "../../utils/scrollTo";

export default function PrivacyPolicies() {
    return (
        <TermsStyled>
            <div className="container">
                <div className="list" data-wow-delay=".6s">
                    <ul className="menu">
                        <li className="list-item">
                            <a onClick={() => ScrollTo("declaracao-de-privacidade")} title="1. Declaração de privacidade" alt="1. Declaração de privacidade"><strong>1. Declaração de privacidade</strong></a>
                            {/* <li>
                                <a onClick={() => ScrollTo("visita-ao-nosso-website")} title="1.1. Visita ao nosso website" alt="1.1. Visita ao nosso website">1.1. Visita ao nosso website</a>
                            </li>
                            <li>
                                <a onClick={() => ScrollTo("o-que-sao-dados-pessoais")} title="1.2. O que são “dados pessoais”?" alt="1.2. O que são “dados pessoais”?">1.2. O que são “dados pessoais”?</a>
                            </li>
                            <li>
                                <a onClick={() => ScrollTo("dados-pessoais-de-criancas")} title="1.3. Dados Pessoais de Crianças e Adolescentes" alt="1.3. Dados Pessoais de Crianças e Adolescentes">1.3. Dados Pessoais de Crianças e Adolescentes</a>
                            </li>
                            <li>
                                <a onClick={() => ScrollTo("arquivos-de-log")} title="1.4. Arquivos de log" alt="1.4. Arquivos de log">1.4. Arquivos de log</a>
                            </li>
                            <li>
                                <a onClick={() => ScrollTo("dados-que-coletamos")} title="1.5. Dados que coletamos de terceiros" alt="1.5. Dados que coletamos de terceiros">1.5. Dados que coletamos de terceiros</a>
                            </li> */}
                        </li>

                        <li className="list-item">
                            <a onClick={() => ScrollTo("Quais são as informações pessoais que coletamos")} title="2. Quais são as informações pessoais que coletamos" alt="2. Quais são as informações pessoais que coletamos"><strong>2. Quais são as informações pessoais que coletamos</strong></a>
                            {/* <li>
                                <a onClick={() => ScrollTo("conformidade-com-nossa-politica-de-privacidade")} title="2.1. Conformidade com nossa Política de Privacidade" alt="2.1. Conformidade com nossa Política de Privacidade">2.1. Conformidade com nossa Política de Privacidade</a>
                            </li>
                            <li>
                                <a onClick={() => ScrollTo("uso-de-dados-pessoais")} title="2.2. Uso de dados pessoais" alt="2.2. Uso de dados pessoais">2.2. Uso de dados pessoais</a>
                            </li>
                            <li>
                                <a onClick={() => ScrollTo("uso-dos-dados-de-navegacao")} title="2.3. Uso dos dados de navegação" alt="2.3. Uso dos dados de navegação">2.3. Uso dos dados de navegação</a>
                            </li>
                            <li>
                                <a onClick={() => ScrollTo("comentarios")} title="2.4. Comentários e depoimentos de usuários" alt="2.4. Comentários e depoimentos de usuários">2.4. Comentários e depoimentos de usuários</a>
                            </li>
                            <li>
                                <a onClick={() => ScrollTo("uso-de-dados")} title="2.5. Uso de dados de cartão de crédito" alt="2.5. Uso de dados de cartão de crédito">2.5. Uso de dados de cartão de crédito</a>
                            </li>
                            <li>
                                <a onClick={() => ScrollTo("seguranca-dos-seus-dados-pessoais")} title="2.6. Segurança dos seus dados pessoais" alt="2.6. Segurança dos seus dados pessoais">2.6. Segurança dos seus dados pessoais</a>
                            </li>
                            <li>
                                <a onClick={() => ScrollTo("recursos-de-midias")} title="2.7. Recursos de mídias sociais" alt="2.7. Recursos de mídias sociais">2.7. Recursos de mídias sociais</a>
                            </li>
                            <li>
                                <a onClick={() => ScrollTo("sites-externos")} title="2.8. Sites externos" alt="2.8. Sites externos">2.8. Sites externos</a>
                            </li>
                            <li>
                                <a onClick={() => ScrollTo("retencao-de-dados")} title="2.9. Retenção de Dados Pessoais" alt="2.9. Retenção de Dados Pessoais">2.9. Retenção de Dados Pessoais</a>
                            </li> */}
                        </li>

                        <li className="list-item">
                            <a onClick={() => ScrollTo("Mídias Sociais")} title="3. Mídias Sociais" alt="3. Mídias Sociais"><strong>3. Mídias Sociais</strong></a>
                            {/* <li>
                                <a onClick={() => ScrollTo("prestadores-de-servicos")} title="3.1. Prestadores de serviços" alt="3.1. Prestadores de serviços">3.1. Prestadores de serviços</a>
                            </li>
                            <li>
                                <a onClick={() => ScrollTo("parceiros-de-eyecare")} title="3.2. Parceiros da Eyecare" alt="3.2. Parceiros da Eyecare">3.2. Parceiros da Eyecare</a>
                            </li>
                            <li>
                                <a onClick={() => ScrollTo("reestruturacoes-de-societarias")} title="3.3. Reestruturações societárias" alt="3.3. Reestruturações societárias">3.3. Reestruturações societárias</a>
                            </li>
                            <li>
                                <a onClick={() => ScrollTo("obrigacao-de-divulgacao")} title="3.4. Obrigação de divulgação" alt="3.4. Obrigação de divulgação">3.4. Obrigação de divulgação</a>
                            </li>
                            <li>
                                <a onClick={() => ScrollTo("transferencia-internacional")} title="3.5. Transferência internacional de dados" alt="3.5. Transferência internacional de dados">3.5. Transferência internacional de dados</a>
                            </li> */}
                        </li>

                        <li className="list-item">
                            <a onClick={() => ScrollTo("Finalidades e bases legais")} title="4. Finalidades e bases legais" alt="4. Finalidades e bases legais"><strong>4. Finalidades e bases legais</strong></a>
                            {/* <li>
                                <a onClick={() => ScrollTo("cookies")} title="4.1. Cookies" alt="4.1. Cookies">4.1. Cookies</a>
                            </li> */}
                        </li>

                        <li className="list-item">
                            <a onClick={() => ScrollTo("Sobre o compartilhamento e cessão de informações")} title="5. Sobre o compartilhamento e cessão de informações" alt="5. Sobre o compartilhamento e cessão de informações"><strong>5. Sobre o compartilhamento e cessão de informações</strong></a>
                            {/* <li>
                                <a onClick={() => ScrollTo("revisao-correcao")} title="5.2.	Para cancelar a assinatura de nossas comunicações" alt="5.1.	Revisão, correção e eliminação de seus dados pessoais">5.1.	Revisão, correção e eliminação de seus dados pessoais</a>
                            </li>
                            <li>
                                <a onClick={() => ScrollTo("para-cancelar")} title="5.2. Para cancelar a assinatura de nossas comunicações" alt="5.2. Para cancelar a assinatura de nossas comunicações">5.2. Para cancelar a assinatura de nossas comunicações</a>
                            </li> */}
                        </li>

                        <li className="list-item">
                            <a onClick={() => ScrollTo("Segurança da Informação")} title="6. Segurança da Informação" alt="6. Segurança da Informação"><strong>6. Segurança da Informação</strong></a>
                            {/* <li>
                                <a onClick={() => ScrollTo("alteracoes-na-politica")} title="6.1. Alterações na Política de Privacidade" alt="6.1. Alterações na Política de Privacidade">6.1. Alterações na Política de Privacidade</a>
                            </li>
                            <li>
                                <a onClick={() => ScrollTo("aceitacao-tacita")} title="6.2. Aceitação Tácita" alt="6.2. Aceitação Tácita">6.2. Aceitação Tácita</a>
                            </li>
                            <li>
                                <a onClick={() => ScrollTo("resolucao-de-conflitos")} title="6.3. Resolução de Conflitos" alt="6.3. Resolução de Conflitos">6.3. Resolução de Conflitos</a>
                            </li> */}
                        </li>

                        <li className="list-item">
                            <a onClick={() => ScrollTo("Direitos dos Titulares")} title="7. Direitos dos Titulares" alt="6. Direitos dos Titulares"><strong>6. Direitos dos Titulares</strong></a>
                        </li>

                        <li className="list-item">
                            <a onClick={() => ScrollTo("Término de Tratamento")} title="8. Término de Tratamento" alt="6. Término de Tratamento"><strong>6. Término de Tratamento</strong></a>
                        </li>

                        <li className="list-item">
                            <a onClick={() => ScrollTo("Encarregado de Dados Pessoais")} title="9. Encarregado de Dados Pessoais (DPO)" alt="6. Encarregado de Dados Pessoais (DPO)"><strong>6. Encarregado de Dados Pessoais (DPO)</strong></a>
                        </li>

                        <li className="list-item">
                            <a onClick={() => ScrollTo("Alterações na Política de Privacidade")} title="10. Alterações na Política de Privacidade" alt="6. Alterações na Política de Privacidade"><strong>6. Alterações na Política de Privacidade</strong></a>
                        </li>

                        <div className="color">
                            <span>Última atualização: 20 de junho de 2023</span>
                        </div>
                    </ul>
                </div>
                <div className="term" data-wow-delay=".7s">
                    <p id="declaracao-de-privacidade">Atualizada em 20 de junho de 2023.</p>

                    <h3>1. Declaração de Privacidade</h3>
                    <p>
                        A <b>EYECARE</b> sabe o quanto é importante conhecer e estar seguro sobre a utilização dos dados pessoais que tem em seu poder. Por isso, nos preocupamos em esclarecer e 
                        explicar a nossa Política de Privacidade.
                        Nós reconhecemos a necessidade de proteção e de gerenciamento adequado das informações pessoais coletadas em nosso site, bem como aquelas que nos são compartilhadas por 
                        nossos clientes. A presente Política de Privacidade auxiliará a compreender quais os tipos de informações que podemos coletar, como essas informações poderão ser utilizadas, 
                        com quem as informações poderão ser compartilhadas e quais os direitos dos titulares no que diz respeito à proteção de seus dados pessoais.
                    </p>
                    <h3 id="Quais são as informações pessoais que coletamos">2. Quais são as informações pessoais que coletamos</h3>
                    <p>
                        As informações pessoais que obtemos podem ser coletadas das seguintes formas:
                    </p>
                    <p>
                        <b>Site:</b> Informações pessoais são coletadas quando o usuário interage em nosso site por meio de formulário de contato ou quando há o preenchimento do formulário de anúncios 
                        de publicidade. Essas informações pessoais incluem nome, e-mail, CRM do médico, telefone e, quando o caso, nome da empresa. 
                        Somente é necessária a coleta de dados básicos quando o usuário desejar o retorno de seu contato. Todas as informações pessoais são colhidas de maneira justa e não invasiva, 
                        com o seu consentimento voluntário. As informações pessoais não são acessíveis a qualquer pessoa fora da função específica para a qual foram coletadas.<br/>
                        <b>Aplicativos da Eyecare:</b> disponibilizamos aos nossos clientes os aplicativos Benefício Visão, Glauco, Oculli e o Eyecare BI, os quais possuem sistemas que coletam 
                        informações e atividades dos usuários por meio do acesso aos aplicativos/softwares em seus smartphones e computadores. 
                        Os aplicativos podem registrar informações como: dados de login (e-mail e senha), quantas vezes o usuário usa o sistema e o tempo de utilização do aplicativo, as 
                        atividades que ocorrem dentro do aplicativo, uso agregado, dados de desempenho e de onde o aplicativo foi baixado ou utilizado. 
                    </p>
                    <p>
                        Quando você usa nossos aplicativos, podemos também coletar a sua geolocalização, o modelo e a versão do dispositivo, o identificador de dispositivo (ou “UDID”) e a 
                        versão do sistema operacional. Geolocalização: sua localização está sujeita às configurações ajustadas no seu dispositivo e pode ser determinada por GPS, endereço IP, 
                        dados do sensor do seu dispositivo ou por meio de informações transmitidas por itens próximos do dispositivo como, por exemplo, pontos de acesso Wi-Fi, dispositivos 
                        com bluetooth ativado e torres de celular.<br/>
                        Enviamos notificações push de vez em quando para manter você informado sobre alertas programados, eventos ou promoções. Caso não queira receber essas comunicações, 
                        desative-as no seu dispositivo.<br/>
                        Nós podemos vincular os dados que armazenamos no software a dados pessoais que você envia pelo aplicativo. Fazemos isso para aprimorar os serviços que prestamos e 
                        melhorar a funcionalidade do nosso marketing, das nossas análises de dados e das Plataformas.<br/>
                        <b>Newsletter:</b> O Usuário pode optar por receber nosso newsletter, contendo novidades, artigos e promoções, por meio do preenchimento do e-mail em formulário próprio 
                        em nosso site. O usuário pode, a qualquer momento, deixar de receber nossas comunicações clicando no link de cancelamento contido no rodapé dos próprios e-mails enviados.<br/>
                        <b>Canais de atendimento:</b> O usuário poderá entrar em contato por meio do WhatsApp e chats das plataformas e aplicativos. Os dados pessoais são coletados com o objetivo de 
                        identificar o usuário, bem como para atender sua solicitação e prestar suporte. Esses podem incluir, dentre outros, o nome, e-mail e telefone. Além destes, coletamos qualquer 
                        dado pessoal que seja informado espontaneamente pelo usuário.<br/>
                        <b>Cookies:</b> Dados pessoais podem ser coletados por meio de cookies. Para mais informações, consulte nossa Política de Cookies. <br/>
                        <b>Cadastro:</b> Coletamos os dados de nossos clientes necessários para efetuar o cadastro em nosso site ou aplicativos. Os dados são necessários para identificar o usuário e 
                        permitir que ele adquira produtos ou serviços através de nossas plataformas. Os dados coletados para cadastro são nome, sobrenome, e-mail e CPF do usuário.<br/>
                        <b>Contrato:</b> Quando o usuário adquire nossos produtos ou serviços, coletamos os dados necessários para a confecção do contrato e emissão de recibo ou nota fiscal. Estes dados
                        são, entre outros, o e-mail para contato e eventual envio de Nota Fiscal Eletrônica, nome completo ou razão social, nome completo do Responsável, CPF, CNPJ, telefone, endereço
                        comercial completo com CEP, Bairro, Cidade e Estado e a Inscrição Estadual, quando aplicável.<br/>
                        <b>Telemedicina:</b> disponibilizamos o agendamento de consultas on-line com um de nossos médicos, especialistas em telemedicina, por meio das nossas plataformas. Neste caso, coletamos
                        os dados necessários para a confecção do agendamento. Estes dados são, entre outros como: nome completo ou o nome completo do responsável (em caso de titulares menores de idade),
                        CPF, telefone, endereço, e-mail e dados relacionados à saúde do paciente.<br/>
                        <b>Comentários e depoimentos de usuários em nosso site:</b> Nós postamos em nossas Plataformas depoimentos e comentários de usuários como você, que podem conter dados pessoais.
                        Nós obtemos o consentimento de cada usuário, via e-mail, antes de postar seu nome e depoimento.<br/>
                        <b>Compartilhamento:</b> Eventualmente, teremos acesso a dados pessoais compartilhados por meio de fontes externas, como parceiros com os quais atuamos, de forma conjunta, em co-branding,
                        publicidade ou marketing ou de fontes publicamente disponíveis, como redes sociais e sites ou órgãos governamentais.<br/>
                        <b>Eventos:</b> Informações pessoais, como nome, telefone e e-mail de contato, também podem ser coletadas em meio físico ou digital pelos nossos empregados em feiras, campanhas,
                        exposições e eventos promovidos pela EYECARE ou dos quais a EYECARE participa como convidada ou expositora.<br/>
                        <b>Candidatos a vagas de emprego:</b> Podemos ter acesso a informações pessoais de candidatos a vagas de emprego, tais como nome completo, telefone, e-mail, área de formação, município
                        de interesse, cargo desejado e quaisquer outras informações fornecidas voluntariamente pelo candidato.<br/>
                        <b>Empregados:</b> Quanto aos dados pessoais coletados de nossos empregados, estes são necessários para que seja efetuado o registro do empregado, em atendimento à legislação trabalhista
                        e execução do contrato de trabalho.
                    </p>

                    <h3 id="Mídias Sociais">3. Mídias Sociais</h3>

                    <p>
                        A <b>EYECARE</b> se utiliza também da Mídia Social para se comunicar e interagir com seus clientes e consumidores, por meio de websites de terceiros como, por exemplo, o Facebook, 
                        Instagram, LinkedIn, Facebook e Widgets, como o botão de compartilhar ou mini-programas interativos que são executados em nossos sites. Estes websites de terceiros são uma 
                        tecnologia baseada na Internet que não é operada ou controlada pela <b>EYECARE</b>. Ao interagir, compartilhar ou “Curtir” a página da <b>EYECARE</b> no Facebook, Instagram, LinkedIn, 
                        Facebook ou outra mídia social, você poderá revelar determinadas informações pessoais a <b>EYECARE</b> ou a terceiros.<br/>
                        Usamos os “botões sociais” para permitir que os nossos usuários compartilhem ou marquem páginas da web. São botões de sites terceiros de mídias sociais e que podem registrar informações sobre suas atividades na internet, incluindo este site. Por favor, reveja os respectivos termos de uso e políticas de privacidade dessas plataformas para entender exatamente como elas usam suas informações, bem como optar por não receber ou excluir tais informações.
                        A quantidade de informações pessoais visíveis dependerá das suas próprias configurações de privacidade no Facebook, Instagram, LinkedIn e demais mídias sociais.
                    </p>

                    <h3 id="Finalidades e bases legais">4. Finalidades e bases legais</h3>
                    <p>
                        Relacionamos a seguir as finalidades e bases legais dos tratamentos de dados da <b>EYECARE</b>:
                    </p>

                    <table>
                        <thead>
                            <tr>
                                <th style={{ padding: '0.25rem', border: '1px solid #dee2e6', background: '#f8f8f8' }}>Fins</th>
                                <th style={{ padding: '0.25rem', border: '1px solid #dee2e6', background: '#f8f8f8' }}>Base legal</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td style={{ padding: '0.25rem', border: '1px solid #dee2e6' }}>Cumprir o contrato estabelecido com nossos clientes.</td>
                                <td style={{ padding: '0.25rem', border: '1px solid #dee2e6' }}>Será determinada pelo nosso cliente, na qualidade de controlador dos dados pessoais.</td>
                            </tr>
                            <tr>
                                <td style={{ padding: '0.25rem', border: '1px solid #dee2e6' }}>Cumprir com termos e condições estabelecidos na contratação de serviços médicos por nossos pacientes ou seus responsáveis legais</td>
                                <td style={{ padding: '0.25rem', border: '1px solid #dee2e6' }}>Necessário para execução de contrato no qual o titular de dados pessoais seja parte, após autorização em Termo de Consentimento Livre e Esclarecido para tratamento de dados relacionados à saúde dos titulares; Ou para a tutela da saúde, em procedimento realizado por profissionais da área da saúde ou por entidades sanitárias. </td>
                            </tr>
                            <tr>
                                <td style={{ padding: '0.25rem', border: '1px solid #dee2e6' }}>Recrutamento e avaliação de aderência à vaga de trabalho.</td>
                                <td style={{ padding: '0.25rem', border: '1px solid #dee2e6' }}>Justificado com base em nossos legítimos interesses para assegurar que recrutamos os empregados adequados.</td>
                            </tr>
                            <tr>
                                <td style={{ padding: '0.25rem', border: '1px solid #dee2e6' }}>Facilitar a comunicação com você (inclusive em casos de emergência e para fornecer a você informações solicitadas).</td>
                                <td style={{ padding: '0.25rem', border: '1px solid #dee2e6' }}>Justifica-se com base em nossos interesses legítimos de assegurar comunicação e gestão de emergências adequadas na organização.</td>
                            </tr>
                            <tr>
                                <td style={{ padding: '0.25rem', border: '1px solid #dee2e6' }}>Cumprir requisitos legais.</td>
                                <td style={{ padding: '0.25rem', border: '1px solid #dee2e6' }}>Necessário para a conformidade com uma obrigação legal à qual estamos sujeitos.</td>
                            </tr>
                            <tr>
                                <td style={{ padding: '0.25rem', border: '1px solid #dee2e6' }}>Monitorar o uso que você faz dos nossos sistemas (incluindo monitorar o uso do nosso site e de quaisquer aplicativos e ferramentas que você utilizar).</td>
                                <td style={{ padding: '0.25rem', border: '1px solid #dee2e6' }}>Justifica-se com base nos nossos interesses legítimos de evitar não conformidades e proteger nossa reputação.</td>
                            </tr>
                            <tr>
                                <td style={{ padding: '0.25rem', border: '1px solid #dee2e6' }}>Escuta social (identificando e analisando o que está sendo dito sobre a <b>EYECARE</b> nas mídias sociais [somente conteúdo acessível publicamente] a fim de perceber sentimento, intenção, disposição e tendências de mercado, além das necessidades de nossos stakeholders para, dessa forma, melhorar nossos serviços).</td>
                                <td style={{ padding: '0.25rem', border: '1px solid #dee2e6' }}>Justifica-se com base em nossos legítimos interesses em proteger nossos ativos e nossa marca nas mídias sociais.</td>
                            </tr>
                            <tr>
                                <td style={{ padding: '0.25rem', border: '1px solid #dee2e6' }}>Melhorar a segurança e o funcionamento do nosso website, aplicativo, redes e informações.</td>
                                <td style={{ padding: '0.25rem', border: '1px solid #dee2e6' }}>Justifica-se com base em nossos legítimos interesses de assegurar que você terá uma experiência de usuário excelente e que nossas redes e informações são seguras.</td>
                            </tr>
                            <tr>
                                <td style={{ padding: '0.25rem', border: '1px solid #dee2e6' }}>Oferecer nossos produtos e serviços para você (a menos que você tenha mostrado objeção em relação a tal tratamento).</td>
                                <td style={{ padding: '0.25rem', border: '1px solid #dee2e6' }}>Justifica-se com base em nossos legítimos interesses de promover nosso negócio.</td>
                            </tr>
                            <tr>
                                <td style={{ padding: '0.25rem', border: '1px solid #dee2e6' }}>Comentários e depoimentos de usuários</td>
                                <td style={{ padding: '0.25rem', border: '1px solid #dee2e6' }}>Baseia-se no consentimento do titular do dado pessoal.</td>
                            </tr>
                            <tr>
                                <td style={{ padding: '0.25rem', border: '1px solid #dee2e6' }}>Administrar benefícios aos empregados.</td>
                                <td style={{ padding: '0.25rem', border: '1px solid #dee2e6' }}>Justifica-se com base no consentimento do empregado, caso seja de seu interesse o recebimento de determinado benefício.</td>
                            </tr>
                            <tr>
                                <td style={{ padding: '0.25rem', border: '1px solid #dee2e6' }}>Entrega de informações necessárias aos órgãos governamentais.</td>
                                <td style={{ padding: '0.25rem', border: '1px solid #dee2e6' }}>Para cumprimento de uma obrigação legal.</td>
                            </tr>
                            <tr>
                                <td style={{ padding: '0.25rem', border: '1px solid #dee2e6' }}>Pesquisas na área da saúde</td>
                                <td style={{ padding: '0.25rem', border: '1px solid #dee2e6' }}>Baseia-se no consentimento do titular do dado pessoal, ou poderá ser realizado com a anonimização dos dados pessoais.</td>
                            </tr>
                            <tr>
                                <td style={{ padding: '0.25rem', border: '1px solid #dee2e6' }}>Telemedicina</td>
                                <td style={{ padding: '0.25rem', border: '1px solid #dee2e6' }}>Para a garantia da prevenção à fraude e à segurança dos titulares, nos processos de identificação e autenticação de cadastro em sistemas eletrônicos</td>
                            </tr>
                        </tbody>
                    </table>
                    <br/>

                    <p>
                        Apenas nos baseamos em nosso legítimo interesse para finalidades específicas. Estes tratamentos de dados pessoais não se sobrepõem de forma alguma aos interesses, direitos e liberdades dos titulares de dados pessoais.
                    </p>

                    <h3 id="Sobre o compartilhamento e cessão de informações">5. Sobre o compartilhamento e cessão de informações</h3>
                    <p>
                        A <b>EYECARE</b> não tem como prática a divulgação de informações que podem identificar o usuário e jamais compartilha informações ou vende e aluga esses dados pessoais a 
                        terceiros. Estes dados são de uso exclusivo interno da empresa para atingir as finalidades expressas no item anterior.<br/>
                        Os dados poderão ser compartilhados com terceiros apenas nas seguintes condições:<br/>
                        <ul>
                            <li>Mediante decisão judicial ou solicitação pelos órgãos de fiscalização governamentais;</li>
                            <li>Dados transferidos a órgãos públicos para atender a legislação vigente, por exemplo dados pessoais que constem das notas fiscais eletrônicas e respectivos XMLs, ou dados dos nossos empregados necessários para o pagamento do INSS ou FGTS;</li>
                            <li>Dados transferidos a prestadores de serviço de contabilidade ou recursos humanos para o atendimento de obrigações fiscais e trabalhistas;</li>
                            <li>Dados transferidos a instituições financeiras para viabilizar opções de pagamento aos nossos clientes, ou para pagamento de salários aos nossos empregados e prestadores;</li>
                            <li>Dados compartilhados com parceiros, operadores e fornecedores de serviços que participam, direta ou indiretamente, do desenvolvimento das atividades comerciais da <b>EYECARE</b>, tais como: serviços de atendimento ao cliente/paciente, cobranças, processos de pagamento, profissionais da área da saúde, clínicas médicas e hospitais oftalmológicos, órgãos de pesquisa da Administração Pública Direta ou Indireta ou pessoa jurídica de direito privado sem fins lucrativos legalmente constituída sob as leis brasileiras, serviços de envio de e-mails, publicidade e marketing, segurança e monitoramento de desempenho, serviços de reparos e manutenção, processamento e cumprimento de ordens e transações, verificação de informações cadastrais de consumidores, entidades que atuam na área de pesquisa de mercado, guarda e processamento de dados e auditoria interna ou externa;</li>
                            <li>Dados transferidos para fins de execução do contrato, bem como para a proteção dos interesses da <b>EYECARE</b> em qualquer tipo de conflito, incluindo ações judiciais.</li>
                            <li>Em caso de operações societárias como fusão, aquisição, transformação, cisão, ou uma venda parcial ou total de ativos, poderemos compartilhar, divulgar ou transferir todos os dados dos titulares para a organização sucessora.</li>
                        </ul>
                    </p>
                    <p>
                        Alguns dos prestadores de serviços mencionados acima podem estar localizados no exterior e, nesse caso, a <b>EYECARE</b> adota salvaguardas adicionais para a garantia de um nível adequado de proteção de dados pessoais, de acordo com o disposto na legislação brasileira pertinente.
                    </p>

                    <h3 id="Segurança da Informação">6. Segurança da Informação</h3>
                    <p>
                        Para assegurar-se de que as suas informações pessoais estão seguras, nós comunicamos as nossas diretrizes de privacidade e segurança aos empregados e parceiros comerciais da <b>EYECARE</b> e seguimos estritamente as medidas de precaução com relação à privacidade dentro da empresa. <br/>
                        Nós nos empenhamos em proteger as suas informações pessoais, e aquelas confiadas a nós pelos nossos clientes, por meio de medidas físicas, técnicas e organizacionais que visem reduzir os riscos de perda, mau uso, acesso não autorizado, divulgação e alteração indevida destes dados. 
                    </p>

                    <h3 id="Direitos dos Titulares">7. Direitos dos Titulares</h3>
                    <p>
                        Os Titulares de dados pessoais têm alguns direitos no que se refere aos seus dados pessoais e podem exercê-los clicando <a href="https://forms.monday.com/forms/513dc5246a7e171b4504a94197820626?r=use1" target="_blank">aqui</a> para acessar o Formulário de Requisição de Dados (DSAR) ou por meio do e-mail: dpo@eyecarehealth.com.br 
                    </p>
                    <p>
                        São direitos dos titulares:<br/>
                        <ul>
                            <li>Confirmação da existência de tratamento de dados pessoais;</li>
                            <li>Acesso aos dados pessoais, nos termos da legislação aplicável;</li>
                            <li>Correção de dados pessoais incompletos, inexatos ou desatualizados;</li>
                            <li>Anonimização em atividades realizadas em razão de pesquisa, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto nesta Lei;</li>
                            <li>Portabilidade dos dados pessoais;</li>
                            <li>Exclusão de dados pessoais, quando este forem tratados com base no consentimento do titular ou quando os dados forem desnecessários, excessivos ou tratados em desconformidade com a legislação aplicável;</li>
                            <li>Solicitação de informações sobre o uso compartilhado de dados pessoais;</li>
                            <li>Revogação do consentimento, quando aplicável.</li>
                        </ul>
                    </p>
                    <p>
                        A <b>EYECARE</b> sempre avaliará a melhor forma de cumprir a solicitação de exercício de algum de seus direitos. No entanto, a <b>EYECARE</b> poderá deixar de atender a sua solicitação, total ou parcialmente, em situações específicas resguardadas pela legislação, como, por exemplo, para o cumprimento de uma obrigação legal ou de um contrato que mantém com você.<br/>
                        Ressaltamos a importância de manter seus dados pessoais precisos e atualizados. Para tanto, mantenha a <b>EYECARE</b> sempre informada se seus dados pessoais mudarem ou estiverem incorretos. <br/>
                        Por motivos de segurança, para as requisições que sejam feitas por meio do e-mail dpo@eyecarehealth.com.br, a solicitação será atendida quando tivermos certeza da identidade do usuário. Sendo assim, poderemos solicitar dados ou informações adicionais para a confirmação da identidade e da autenticidade do titular. Estes dados e informações serão protegidos durante o período de armazenamento e eliminados, após transcurso do prazo legal para eventual exercício regular de direitos.<br/>
                        Nos casos em que a <b>EYECARE</b> for Operador de dados pessoais, as requisições serão repassadas ao Controlador para que este avalie e decida quanto às providências a serem tomadas.
                    </p>

                    <h3 id="Término de Tratamento">8. Término de Tratamento</h3>
                    <p>
                        Esta Política de Privacidade se aplica às circunstâncias acima mencionadas durante todo o período em que a <b>EYECARE</b> armazenar os dados pessoais. Nós armazenamos e mantemos suas informações: (a) pelo tempo exigido por lei; (b) até o término do tratamento de dados pessoais, conforme mencionado abaixo; ou (c) pelo tempo necessário para resguardar os direitos da <b>EYECARE</b>. Assim, trataremos seus dados, por exemplo, durante os prazos prescricionais aplicáveis ou enquanto necessário para cumprimento de obrigação legal ou regulatória.<br/>
                        O término do tratamento de dados pessoais ocorrerá nos seguintes casos: (a) quando a finalidade para qual o dado pessoal foi coletado for alcançada, e/ou os dados pessoais coletados deixarem de ser necessários ou pertinentes ao alcance de tal finalidade; (b) quando o Titular solicitar a exclusão de seus dados; (c) quando houver uma determinação legal neste sentido e (d) quando o término do tratamento for determinado pelo Controlador.<br/>
                        Nos casos de término de tratamento de dados pessoais, ressalvadas as hipóteses estabelecidas pela legislação aplicável ou pela presente Política de Privacidade, os dados pessoais serão eliminados.
                    </p>

                    <h3 id="Encarregado de Dados Pessoais">9. Encarregado de Dados Pessoais (DPO)</h3>
                    <p>
                        A <b>EYECARE</b> disponibiliza abaixo os dados de contato do Encarregado de Dados Pessoais (DPO), sendo este o responsável por atender toda e qualquer solicitação por parte dos titulares ou da Autoridade Nacional, que estejam relacionados com dados pessoais.<br/>
                        Para qualquer dúvida, solicitação ou reclamação referente ao tratamento de dados pessoais, favor entrar em contato com o nosso Encarregado de Dados Pessoais:
                        DPO EXPERT – (<a href="https://www.dpoexpert.com.br" target="_blank">www.dpoexpert.com.br</a>) – contato: dpo@eyecarehealth.com.br
                        Se, apesar do nosso compromisso e esforços para proteger seus dados, você achar que seus direitos de proteção de dados não foram atendidos, solicitamos que entre em contato com o nosso DPO. Além disso, você tem o direito, a qualquer momento, de registrar uma queixa diretamente com a Autoridade Nacional de Proteção de Dados, caso entenda que os direitos sobre os seus dados pessoais foram infringidos.
                    </p>

                    <h3 id="Alterações na Política de Privacidade">10. Alterações na Política de Privacidade</h3>
                    <p>
                        Apesar de nossa Política de Privacidade ter sido apresentada de forma clara, concisa e objetiva, não hesite em consultar o DPO da <b>EYECARE</b>, caso sobrevenham dúvidas sobre este importante documento ou mesmo sobre as atividades de tratamento de dados pessoais que realizamos.<br/>
                        A <b>EYECARE</b> se reserva no direito de atualizar ou modificar esta Política, em qualquer época e sem aviso prévio. No entanto, publicaremos sempre a nova versão revisada em nosso website ou aplicativos. Caso existam alterações na forma em que tratamos dados pessoais, você será informado para verificar se pretende continuar adquirindo nossos produtos ou utilizando nossos serviços.
                    </p>





                        


                        {/* <h1>Política de privacidade</h1>
                        <p>Prezado usuário,</p>
                        <p>Agradecemos a você pelo acesso às plataformas da Eyecare!</p>
                        <p>Antes de começar a navegar pela nossa plataforma e pelo nosso aplicativo, você deve ler e compreender nossos termos de uso, que está disponível em <a href="/termos-e-politicas/termos-de-uso" className="link-styled">Termos de uso</a> e a nossa política de privacidade, de agora em diante chamada de “Política de Privacidade”, prosseguindo somente se estiver de acordo com estes documentos.</p>
                        <p>Para nós, da Eyecare DN Soluções Digitais Ltda., sociedade empresária de responsabilidade limitada inscrita no CNPJ/ME sob o nº 35.800.459/0001-54, com sede na Alameda Ministro Rocha Azevedo, 859, apto. 31, sala 01, Cerqueira César, São Paulo, SP, CEP 01410-003 (doravante denominada “Eyecare”), proteger a sua informação privada é uma prioridade. Esta Política de Privacidade se aplica às informações e dados coletados pela Eyecare, através de todas as nossas plataformas, sejam elas o website, aplicativos de celular ou qualquer outra pela qual nossos usuários utilizem para acessar nossos serviços (doravante denominadas, indiscriminadamente, de “Plataformas”).</p>
                        <p>Esta Política de Privacidade rege nossas práticas de coleta, tratamento e uso de dados. Ela também descreve suas opções sobre o uso, o acesso e correção de seus dados pessoais. Ao acessar nossas Plataformas, você concorda com esta Política de Privacidade.</p>
                        <p>SE VOCÊ NÃO CONCORDA COM OS TERMOS DESTA POLÍTICA DE PRIVACIDADE, VOCÊ NÃO PODE USAR NEM ACESSAR AS NOSSAS PLATAFORMAS.</p>
                        <p>Atualizamos de tempos em tempos esta Política de Privacidade. Publicaremos eventuais alterações à Política de Privacidade nesta página, e incentivamos você a consultá-la periodicamente.</p>
                        <p>Se você tiver qualquer dúvida sobre esta Política de Privacidade ou o tratamento que damos aos dados que você nos fornece, por favor envie um e-mail para <a href="mailto:sistemas@eyecarehealth.com.br" title="sistemas@eyecarehealth.com.br" alt="sistemas@eyecarehealth.com.br" rel="noreferrer" target="_blank" className="link-styled">sistemas@eyecarehealth.com.br.</a></p>
                        <div id="dados-que-coletamos">
                            <h2>1. Dados que coletamos</h2>
                            <h3 id="visita-ao-nosso-website">1.1. Visita ao nosso website</h3>
                            <p>Você tem liberdade para explorar o website da Eyecare sem fornecer nenhum dado pessoal. Ao visitar o website, nós solicitamos que você forneça dados pessoais sobre si, e coletamos dados de navegação.</p>
                            <p>Já os aplicativos da Eyecare, em especial o Glauco e o Eyecare B.I., possuem softwares que coletam informações dos usuários para entendermos melhor a funcionalidade dos nossos Aplicativos/Softwares em seu smartphone.</p>
                            <p>Este software pode registrar informações como quantas vezes você usa o aplicativo e o tempo de utilização, os eventos que ocorrem dentro do aplicativo, uso agregado, dados de desempenho e de onde o aplicativo foi baixado. Além dos dados que coletamos em nosso site, quando você usa nosso aplicativo, podemos também coletar a sua geolocalização, o modelo e a versão do dispositivo, o identificador de dispositivo (ou “UDID”) e a versão do sistema operacional.   Enviamos notificações push de vez em quando para manter você informado sobre alertas programados, eventos ou promoções. Caso não queira receber essas comunicações, desative-as no seu dispositivo.</p>
                            <p>Nós podemos vincular os dados que armazenamos no software a dados pessoais que você envia pelo aplicativo. Fazemos isso para aprimorar os serviços que prestamos e melhorar a funcionalidade do nosso marketing, das nossas análises de dados e das Plataformas.</p>
                            <h3 id="o-que-sao-dados-pessoais">1.2. O que são “dados pessoais”?</h3>
                            <p>Referem-se a qualquer dado que você envie voluntariamente para nós e que identifique você pessoalmente, incluindo dados de contato, como nome, documentos de identidade, endereço de e-mail, endereço, número de telefone e outras informações sobre você.  Os dados pessoais também podem incluir informações sobre eventuais transações, gratuitas e pagas, inseridas no nosso site ou aplicativo, além de informações sobre você que estiverem disponíveis na Internet, em sites como Facebook, LinkedIn, Twitter e Google, ou ainda, informações publicamente disponíveis que adquirirmos ou obtivermos de prestadores de serviços.</p>
                            <p>Os dados pessoais também incluem dados de navegação ou dados de pagamento nos casos em que tais informações forem suficientes para identificar alguém de forma direta ou indireta. Os dados de navegação se referem a informações sobre seu computador e suas visitas a este site como o seu endereço IP, a localização geográfica, o tipo de navegador, a fonte de referência, a duração da visita e as páginas visualizadas.</p>
                            <h3 id="dados-pessoais-de-criancas">1.3. Dados Pessoais de Crianças e Adolescentes</h3>
                            <p>As Plataformas são voltadas ao público com capacidade jurídica, contudo, é possível que menores de idade utilizem as Plataformas. Nesses casos, solicitaremos, por meios digitais, que os pais dos menores autorizem a utilização e o fornecimento de dados. As Plataformas são direcionados a indivíduos que têm permissão para compartilhar seus dados pessoais. Se acreditar que podemos ter dados pessoais de crianças ou adolescentes, sem o consentimento dos pais, entre em contato conosco para tomarmos as providências cabíveis, através do e-mail <a href="mailto:sistemas@eyecarehealth.com.br" title="sistemas@eyecarehealth.com.br" alt="sistemas@eyecarehealth.com.br" rel="noreferrer" target="_blank" className="link-styled">sistemas@eyecarehealth.com.br.</a></p>
                            <h3 id="arquivos-de-log">1.4. Arquivos de log</h3>
                            <p>Quando você usa os nossos serviços ou visualiza conteúdos que nós fornecemos, nós coletamos automaticamente informações sobre o hardware e o software do seu computador. Essas informações podem incluir, além das já acima citadas, também nomes de domínio, provedor de serviços de Internet (ISP), os arquivos visualizados no nosso site (por exemplo, páginas HTML, gráficos etc.), sistema operacional e dados de clickstream.</p>
                            <p>Essas informações são usadas pela Eyecare para manter a qualidade do nosso serviço e para gerar estatísticas gerais a respeito do uso do site e do aplicativo. Para esses fins, vinculamos esses dados coletados automaticamente a dados pessoais como nome, endereço de e-mail, endereço e número de telefone.</p>
                            <h3 id="dados-que-coletamos">1.5. Dados que coletamos de terceiros</h3>
                            <p>Eventualmente, podemos receber dados pessoais sobre você de fontes externas, como parceiros com os quais atuamos, de forma conjunta, em co-branding, publicidade ou marketing, e de fontes publicamente disponíveis, como redes sociais e sites governamentais.</p>
                            <h2 id="como-usamos-os-dados-coletados">2. Como usamos os dados coletados</h2>
                            <h3 id="dados-que-coletamos">2.1. Conformidade com nossa Política de Privacidade</h3>
                            <p>Usamos os dados que coletamos somente em conformidade com esta Política de Privacidade e, ainda, nos termos da legislação brasileira aplicável à Proteção de Dados Pessoais (em especial a Lei nº 13.709 de 14/08/2018).</p>
                            <h3 id="uso-de-dados-pessoais">2.2. Uso de dados pessoais</h3>
                            <p>A Eyecare coleta e usa suas informações pessoais para operar uma plataforma tecnológica de serviços. Os dados coletados são usados para fornecer, manter, proteger, melhorar, desenvolver novos serviços, e proteger a Eyecare e seus usuários. Também usamos essas informações para oferecer ao usuário um conteúdo específico, direcionado às suas necessidades individuais, em benefício da saúde. Ao se cadastrar na nossa Plataforma e utilizá-la, solicitaremos que nos forneça seu nome, endereço de e-mail, bem como alguns dados pessoais e de saúde.   VOCÊ SEMPRE PODERÁ ESCOLHER SE DESEJA OU NÃO NOS FORNECER TAIS INFORMAÇÕES. NA VERDADE, SEM O SEU CONSENTIMENTO NENHUM DADO SEU PODERÁ SER COLETADO. Se você optar por não fornecer as informações solicitadas, poderá não ser capaz de usar e receber certos benefícios, recursos ou funções das nossas Plataformas.</p>
                            <p>Além dos usos já mencionados da presente Política de Privacidade, podemos usar seus Dados Pessoais para:</p>
                            <ul>
                                <li> Enviar a você informações ou conteúdo da Eyecare que achamos ser do seu interesse por meio de publicações, e-mails ou outros meios, bem como enviar comunicações de marketing relativas à nossa empresa, obedecendo sempre às suas preferências de comunicação;</li>
                                <li> Permitir a integração com outros usuários das Plataformas, especialmente no que se refere ao acompanhamento médico de eventuais tratamentos realizados através da Plataforma; </li>
                                <li> Fornecer a outras empresas parceiras estatísticas sobre os nossos usuários, sendo que essas informações não serão usadas para identificar nenhum usuário específico; </li>
                                <li> Enviar a você informações sobre mudanças nos nossos Termos de Serviço, Política de Privacidade, ou outros documentos jurídicos;</li>
                                <li> Atender a requisitos legais. </li>
                            </ul>
                            <p>AO UTILIZAR AS PLATAFORMAS EYECARE, VOCÊ CONCORDA QUE A EYECARE PODERÁ COMPARTILHAR SEUS DADOS COM PROFISSIONAIS E/OU INSTITUIÇÕES DA ÁREA DE SAÚDE COMO ENFERMEIROS, MÉDICOS, CLÍNICAS E HOSPITAIS, PARA QUE ESTES ENTREM, EVENTUALMENTE, EM CONTATO COM VOCÊ PARA FORNECER INFORMAÇÕES DE ASSISTÊNCIA À SAÚDE RELACIONADOS À PLATAFORMA DE SERVIÇOS, PARA OFERECER OUTROS SERVIÇOS OU PARA REALIZAR PESQUISAS. A Eyecare pode acompanhar os sites e páginas que você visita e outros aplicativos que você utiliza, a fim de determinar quais serviços de saúde são os mais populares. Poderá também monitorar seu nível de utilização do telefone. Esta informação é usada para mostrar conteúdo dentro das nossas Plataformas para clientes cujo comportamento indique interesse por áreas específicas.</p>
                            <h3 id="uso-dos-dados-de-navegacao">2.3. Uso dos dados de navegação</h3>
                            <p>Nós usamos dados de navegação para operar e aprimorar as nossas Plataformas. Também utilizaremos os dados de navegação de maneira isolada ou em combinação com os dados pessoais para fornecer a você informações personalizadas sobre a Eyecare.</p>
                            <h3 id="comentarios">2.4. Comentários e depoimentos de usuários</h3>
                            <p>Nós postamos em nossas Plataformas depoimentos e comentários de usuários como você, que podem conter dados pessoais. Nós obtemos o consentimento de cada usuário, via e-mail, antes de postar seu nome e depoimento.</p>
                            <h3 id="uso-de-dados">2.5. Uso de dados de cartão de crédito</h3>
                            <p>Se você fornecer dados de cartão de crédito em qualquer conteúdo que eventualmente seja pago nas Plataformas da Eyecare, nós os usaremos exclusivamente para verificar suas qualificações financeiras e para fins de pagamento do serviço ou produto contratado. Nenhum prestador de serviços da Eyecare ou de suas parceiras tem permissão para armazenar, reter ou usar os dados que você fornecer, exceto para a única finalidade de processamento do cartão de crédito em nosso nome.</p>
                            <h3 id="seguranca-dos-seus-dados-pessoais">2.6. Segurança dos seus dados pessoais</h3>
                            <p>Nós usamos várias tecnologias e procedimentos de segurança para ajudar a proteger seus dados pessoais contra acesso, uso ou divulgação não autorizados. Protegemos os dados pessoais que você fornece em servidores de computador em um ambiente controlado, seguro e protegido contra acesso, uso ou divulgação não autorizados. Todos os dados pessoais são protegidos por medidas físicas, técnicas e organizacionais.</p>
                            <h3 id="recursos-de-midias">2.7. Recursos de mídias sociais</h3>
                            <p>Nossas Plataformas poderão incluir recursos de mídias sociais, como o botão de curtir do Facebook e Widgets como o botão de compartilhar, ou mini-programas interativos que são executados em nossos sites. Esses recursos podem coletar seu endereço IP, a página que você está visitando em nossos sites e pode definir um cookie para permitir que o recurso funcione corretamente. Esses recursos de mídias sociais e os widgets ficam hospedados por um terceiro ou diretamente em nossas Plataformas. Esta Política de Privacidade não se aplica a esses recursos. Suas interações com esses recursos são regidas pela política de privacidade e por outras políticas das empresas que os fornecem.</p>
                            <h3 id="sites-externos">2.8. Sites externos</h3>
                            <p>Nosso site fornece links para outros sites. Nós não controlamos e não somos responsáveis pelo conteúdo ou práticas desses outros sites. O fato de disponibilizarmos esses links não constitui nosso endosso desses outros sites nem do conteúdo, dos proprietários ou das práticas deles. Esta Política de Privacidade não se aplica a esses outros sites, que estão sujeitos a outras políticas de privacidade e outras políticas pertinentes. </p>
                            <h3 id="retencao-de-dados">2.9. Retenção de Dados Pessoais</h3>
                            <p>O prazo pelo qual retemos dados que coletamos sobre você será o estabelecido em lei, ou quando se verificar que a finalidade pela qual ele coletado foi alcançada ou que os dados coletados deixaram de ser necessários ou pertinentes. Nestes casos, excluiremos ou anonimizaremos seus dados ou, se isso não for possível, os armazenaremos em segurança e os isolaremos para que não sejam usados até que a exclusão seja possível.</p>
                            <p>Nós retemos dados pessoais que você nos fornece quando tivermos uma necessidade comercial legítima contínua para tal (por exemplo, se necessário para entrar em contato com você para falar sobre algum serviço pago ou outros serviços nossos, ou conforme o necessário para cumprir com nossas obrigações legais, resolver disputas e fazer valer os nossos contratos).</p>
                            <p>Quando não tivermos uma necessidade comercial legítima contínua de tratar seus dados pessoais, excluiremos ou anonimizaremos seus dados de maneira irreversível ou, se isso não for possível, armazenaremos seus dados pessoais em segurança e os isolaremos para que não sejam tratados até que a exclusão seja possível.</p>
                            <p>Caso você tenha optado por receber nossas comunicações de marketing, reteremos informações sobre as suas preferências de marketing por um período razoável a partir da última data em que você expressou interesse em nossos conteúdos, produtos ou serviços, como a última vez em que você abriu um e-mail enviado por nós ou parou de usar sua conta da Eyecare em nossas Plataformas. Nós retemos informações derivadas de cookies e outras tecnologias de rastreamento por um período razoável a partir da data em que tais informações foram criadas.</p>
                            <h2 id="como-compartilhamos">3. Como compartilhamos os dados que coletamos</h2>
                            <h3 id="prestadores-de-servicos">3.1. Prestadores de serviços</h3>
                            <p>Nós contratamos outras empresas e pessoas físicas para prestar serviços para os visitantes de nossas Plataformas, podendo ser necessário compartilhar suas informações com eles com o fim de fornecer informações, produtos ou serviços para você. Os exemplos podem incluir a remoção de informações repetitivas de listas de prospectos, a realização de análise estatísticas de dados, a prestação de assistência de marketing, o processamento de pagamentos de cartão de crédito e a complementação das informações que você nos fornece, a fim de aprimorarmos o nosso serviço e prestar suporte ou atendimento ao cliente a você. Em todos os casos em que podemos compartilhar suas informações com esses agentes, exigimos explicitamente que ele reconheça e respeite as nossas políticas de privacidade e tratamento de dados do cliente.</p>
                            <h3 id="parceiros-de-eyecare">3.2. Parceiros da Eyecare</h3>
                            <p>Além disso, podemos compartilhar dados com parceiros de confiança para entrar em contato com você com base em um pedido seu para receber esse tipo de comunicação, nos ajudar a realizar a análise estatística ou fornecer suporte ao cliente. Esses terceiros são proibidos de usar seus dados pessoais exceto para esses fins, e eles são obrigados a preservar a confidencialidade dos seus dados. </p>
                            <h3 id="reestruturacoes-de-societarias">3.3. Reestruturações societárias</h3>
                            <p>Se nós (ou nossos ativos) forem adquiridos por outra empresa, seja através de fusão, aquisição ou qualquer outra forma, essa empresa receberá todos os dados reunidos pela Eyecare sobre as Plataformas. Nesse caso, você será notificado via e-mail e/ou um aviso em destaque em nossa Plataforma sobre qualquer alteração na propriedade da empresa, no uso dos seus dados pessoais e nas escolhas que você possa ter em relação a seus dados pessoais.</p>
                            <h3 id="obrigacao-de-divulgacao">3.4. Obrigação de divulgação</h3>
                            <p>Nós nos reservamos o direito de usar ou divulgar seus dados pessoais se isso for exigido por lei ou se razoavelmente acreditarmos que o uso ou divulgação seja necessária para proteger nossos direitos, proteger sua segurança ou a segurança de outros, investigar fraudes ou cumprir uma lei, ordem judicial ou processo legal.</p>
                            <h3 id="transferencia-internacional">3.5. Transferência internacional de dados</h3>
                            <p>Esta Política de Privacidade aplica-se mesmo se transferirmos dados pessoais para empresas afiliadas ou parceiras localizadas em outros países. Tomamos as devidas medidas para garantir que seus dados pessoais permaneçam protegidos. Caso venhamos a compartilhar dados sobre você com eventuais parceiros sediados em outros países, usaremos as cláusulas contratuais padrão específicas para determinadas transferências, normas corporativas globais, e/ou selos, certificados e códigos de conduta regularmente emitidos.</p>
                            <h2 id="cookies-e-tecnologias">4. Cookies e tecnologias semelhantes</h2>
                            <h3 id="cookies">4.1. Cookies</h3>
                            <p>A Eyecare e seus parceiros usam cookies ou tecnologias semelhantes (como web beacons) para analisar tendências, administrar a Plataforma, rastrear os movimentos dos usuários no site e reunir informações demográficas sobre nossa base de usuários como um todo.</p>
                            <h2 id="os-seus-direitos">5. Os seus direitos: como acessar e controlar seus dados pessoais</h2>
                            <h3 id="revisao-correcao">5.1. Revisão, correção e eliminação de seus dados pessoais</h3>
                            <p>Você tem os seguintes direitos de proteção de dados:</p>
                            <ul>
                                <li> Você pode solicitar acesso, correção, atualização ou exclusão de seus dados pessoais;</li>
                                <li> Você pode se opor ao tratamento de seus dados pessoais, pedir para restringirmos o tratamento dos seus dados pessoais ou solicitar a portabilidade de seus dados pessoais;</li>
                                <li> Caso tenhamos coletado e tratado seus dados pessoais com o seu consentimento, você pode anular o seu consentimento quando quiser. A anulação do seu consentimento não afetará a legalidade de nenhum tratamento que conduzimos antes da anulação nem afetará o tratamento dos seus dados pessoais conduzidos de acordo com o tratamento lícito independentemente do seu consentimento;</li>
                                <li> Você tem o direito de reclamar com uma autoridade de proteção de dados sobre nossa coleta e uso de suas informações pessoais.</li>
                            </ul>
                            <h3 id="para-cancelar">5.2. Para cancelar a assinatura de nossas comunicações</h3>
                            <p>Você pode cancelar a assinatura de nossas comunicações de marketing, clicando no link “cancelar assinatura” localizado na parte inferior de nossos e-mails, atualizando suas preferências de comunicação. Para exercer qualquer um desses direitos, envie um e-mail sistemas@eyecarehealth.com.br. Nós responderemos ao seu pedido para alterar, corrigir, ou excluir seus dados dentro de um prazo razoável e notificaremos sobre a ação que tomamos.</p>
                            <h2 id="disposicoes-gerais">6. Disposições gerais</h2>
                            <h3 id="alteracoes-na-politica">6.1. Alterações na Política de Privacidade</h3>
                            <p>Poderemos alterar a Política de Privacidade das Plataformas de tempos em tempos. O uso de informações coletadas agora está sujeito à Política de Privacidade em vigor.  Mudanças na forma como utilizamos os dados pessoais serão notificadas aos usuários através de anúncio na Plataforma e, em caso de alterações substanciais, notificaremos mediante envio de e-mail ao usuário.</p>
                            <p>Pequenos ajustes nesta Política de Privacidade podem ocorrer sem afetar significativamente as formas em que tratamos as informações coletadas, de forma que estes não precisarão ser informados.</p>
                            <h3 id="aceitacao-tacita">6.2. Aceitação Tácita</h3>
                            <p>O uso contínuo dos serviços após a data da entrada em vigor de qualquer alteração realizada e notificada previamente constitui aceitação da Política de Privacidade alterada ou modificada. Por esta razão, é recomendado a consulta à Política de Privacidade sempre que forem utilizar os serviços.</p>
                            <h3 id="resolucao-de-conflitos">6.3. Resolução de Conflitos</h3>
                            <p>A presente Política de Privacidade e todas as relações dela decorrentes são submetidas à leis da República Federativa do Brasil, sendo eleito o foro da Comarca da Capital do estado de São Paulo para dirimir qualquer controvérsia decorrente deste instrumento.</p> */}
                        {/* </div> */}
                </div>
            </div>
        </TermsStyled>
    );
}