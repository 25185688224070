import styled from "styled-components";

export const TestimonialStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 20px;
    padding: 35px;
    width: 100%;
    height: 500px !important;
    margin-bottom: 10% !important;
    margin: 0 auto;
    transition: 1s;
    cursor: grab;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    height: 5%;
    user-select: none;
    :hover {
        transition: 1s;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        background-color: var(--secondary);
    }

    @media(max-width: 1000px) {
        width: 80%;
        margin: 0 auto;
    }

    .container-svg {
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: top;

        .div-svg {
            margin-right: auto;
            svg {
                width: 100%;
            }
        }
    }

    .container {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: top;
        .div-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 270px;
            margin-left: 10% !important;
            overflow: auto;

            h3 {
                font-weight: 500;
                /* font-size: 18px; */
                font-size: 16px;
                text-overflow: ellipsis;
                overflow: hidden;
                line-height: 130%;
                letter-spacing: -0.02em;
                color: var(--dark);
            }
        }
    }

    .flex-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;
        div {
            margin-left: 10% !important;
        }
    }

    .photo {
        margin: 5% 0% 2% 10%;
    }
`;

export const TextStyled = styled.h3`
    font-weight: 500;
    font-size: 18px;
    font-size: 1.5vh;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: var(--dark);

    /* @media(max-width: 1000px) {
        font-size: 16px;
    } */
`;

export const NameStyled = styled.h4`
    font-weight: 700;
    font-size: 15px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: var(--dark);
    margin-bottom: 5%;
`;

export const StyledTitle = styled.h5`
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
    letter-spacing: -0.02em;
    color: var(--black);
`;