import { TermsStyled } from "./styles";
import ScrollTo from "../../utils/scrollTo";

export default function CookiesPolicy() {
    return (
        <TermsStyled>
            <div className="container">
                <div className="list" data-wow-delay=".6s">
                    <ul className="menu">
                        <li className="list-item">
                            <a 
                                onClick={() => ScrollTo("o-que-sao-cookies")} 
                                title="o-que-sao-cookies" 
                                alt="o-que-sao-cookies"
                            >
                                O que são cookies?
                            </a>
                        </li>
                        <li className="list-item">
                            <a 
                                onClick={() => ScrollTo("por-que-usamos-cookies")} 
                                title="por-que-usamos-cookies" 
                                alt="por-que-usamos-cookies"
                            >
                                Por que usamos cookies?
                            </a>
                        </li>
                        <li className="list-item">
                            <a 
                                onClick={() => ScrollTo("como-os-cookies-sao-utilizados-pela-eyecare-health")} 
                                title="como-os-cookies-sao-utilizados-pela-eyecare-health" 
                                alt="como-os-cookies-sao-utilizados-pela-eyecare-health"
                            >
                                Como os cookies são utilizados pela Eyecare Health?
                            </a>
                        </li>
                        <li className="list-item">
                            <a 
                                onClick={() => ScrollTo("nos-usamos-cookies-de-terceiros")} 
                                title="nos-usamos-cookies-de-terceiros" 
                                alt="nos-usamos-cookies-de-terceiros"
                            >
                                Nós usamos cookies de terceiros?
                            </a>
                        </li>
                        <li className="list-item">
                            <a 
                                onClick={() => ScrollTo("que-outras-tecnologias-de-rastreamento-utilizamos")} 
                                title="que-outras-tecnologias-de-rastreamento-utilizamos" 
                                alt="que-outras-tecnologias-de-rastreamento-utilizamos"
                            >
                                Que outras tecnologias de rastreamento utilizamos?
                            </a>
                        </li>
                        <li className="list-item">
                            <a 
                                onClick={() => ScrollTo("e-se-eu-nao-quiser-os-cookies")} 
                                title="e-se-eu-nao-quiser-os-cookies" 
                                alt="e-se-eu-nao-quiser-os-cookies"
                            >
                                E se eu não quiser os cookies?
                            </a>
                        </li>
                        
                    
                        <div className="color">
                            <span>Atualizada em 18 de Outubro de 2024</span>
                        </div>
                    </ul>
                </div>
                <div className="term" data-wow-delay=".7s">
                    <h1>Política de Cookies</h1>
                    <p>
                        Nosso site usa cookies para melhorar o desempenho e aprimorar a sua experiência de navegação. Certas áreas do nosso site também utilizam cookies para entender mais sobre você e para que possamos oferecer uma experiência de navegação mais personalizada. Ao continuar a usar este website, você toma conhecimento de que nós poderemos instalar cookies ou outras tecnologias similares em seu equipamento, inclusive dispositivo móvel.
                    </p>

                    <div id="o-que-sao-cookies">
                        <h3>O que são cookies?</h3>
                        <p>Cookies são pequenos arquivos, contendo algumas poucas informações sobre seu acesso, e que são armazenados em seu dispositivo quando você visita um site. O termo "cookie" pode ser usado para descrever uma série de tecnologias, incluindo, mas não se limitando a pixel tags, web beacons e identificadores de dispositivo móvel.</p>
                        <p>Estes arquivos permitem ao site reconhecer o seu equipamento e registrar sua interação nas páginas do próprio site. Eles são amplamente utilizados na web para melhorar a experiência do usuário, fornecer informações aos proprietários do site e oferecer funcionalidades avançadas.</p>
                        <p>Esses cookies são enviados de volta ao site de origem a cada visita subsequente ou a outros sites que reconhecem esse cookie. Os cookies permitem que o site reconheça seu dispositivo e se lembre de informações importantes, como suas preferências, itens no carrinho de compras e dados de login.</p>
                    </div>
                    <div id="por-que-usamos-cookies">
                        <h3>Por que usamos cookies?</h3>
                        <p>Os cookies exercem muitas tarefas diferentes, como otimizar sua navegação pelas páginas, lembrar suas preferências e personalizar sua experiência durante a navegação. Eles ainda ajudam a garantir que os anúncios e outros conteúdos que você acessar online sejam relevantes para você. Além disso, são utilizados para coletar dados estatísticos que são utilizados para implementar melhorias em nosso site e garantir a melhor experiência do usuário.</p>
                    </div>
                    <div id="como-os-cookies-sao-utilizados-pela-eyecare-health">
                        <h3>Como os Cookies são utilizados pela Eyecare Health?</h3>
                        <p>A Eyecare Health utiliza os seguintes tipos de Cookies:</p>
                        <p><strong>Cookies Essenciais</strong> - Os cookies essenciais são necessários para o funcionamento dos Sites e prestação dos serviços a você, com base no nosso legítimo interesse. Sem estes cookies, os Sites não funcionarão tão bem como nós gostaríamos e podemos não ser capazes de fornecer determinados serviços ou recursos a eles associados.</p>
                        <p><strong>Cookies Analíticos/Estatísticos</strong> - Os cookies analíticos coletam informações sobre a utilização do site, permitindo-nos aperfeiçoar o seu funcionamento. Os cookies analíticos, por exemplo, mostram-nos quais são as páginas mais populares do site, quanto tempo é gasto em cada página, dificuldades dos usuários na navegação e outros dados analíticos. Essas informações nos ajudam a melhorar continuamente o desempenho e a usabilidade do nosso site.</p>
                        <p><strong>Cookies de Publicidade/Marketing</strong> - Os cookies de publicidade são utilizados para fins de marketing, permitindo o direcionamento dos anúncios que aparecem para você com base nos seus interesses. O objetivo é tornar o conteúdo publicitário mais relevante para você, melhorar os relatórios sobre o desempenho da campanha e evitar a exibição de anúncios repetitivos.</p>
                        <p>Dentro destas categorias, os cookies podem ser classificados como cookies “de sessão” ou “persistentes”. Cookies “de sessão” são temporários e, uma vez que você fechar a janela do navegador, eles serão eliminados de seu dispositivo. Já, os “persistentes” permanecem no seu dispositivo até sua data de expiração, ou até que você os exclua, e são usados pelo site para reconhecer seu dispositivo em suas visitas subsequentes.</p>
                    </div>
                    <div id="nos-usamos-cookies-de-terceiros">
                        <h3>Nós usamos cookies de terceiros?</h3>
                        <p>Alguns cookies que usamos são de outras empresas que fornecem serviços em nosso site, como ferramentas de análise ou plugins de mídia social, como o Google Analytics. Estas companhias usam códigos de programação para coletar informações sobre sua interação com nossos sites, como as páginas que você visita, os links nos quais você clica e por quanto tempo você permanece conectado em nossos sites.</p>
                    </div>
                    <div id="que-outras-tecnologias-de-rastreamento-utilizamos">
                        <h3>Que outras tecnologias de rastreamento utilizamos?</h3>
                        <p>Podemos também usar web beacons (inclusive pixels de conversão) ou outras tecnologias para fins semelhantes e podemos incluí-los em nossos sites, em mensagens de e-mail de marketing ou em nossos boletins informativos e websites de afiliadas, para determinar se as mensagens foram abertas e se os links foram clicados. Os web beacons não instalam informações em seu dispositivo, mas podem trabalhar em conjunto com os cookies para monitorar a atividade do site. Pixels de conversão são pequenos códigos localizados numa página web em particular que são alterados quando alguém visita a página, resultando num aumento da contagem de conversão.</p>
                    </div>
                    <div id="e-se-eu-nao-quiser-os-cookies">
                        <h3>E se eu não quiser os cookies?</h3>
                        <p>Ao utilizar nosso site, você concorda que nós podemos utilizar cookies em seu dispositivo. No seu primeiro acesso, surgirá um “banner de cookies” informando sobre a utilização de cookies em nosso site. As configurações quanto ao uso de cookies podem ser alteradas em seu navegador. Consulte a seção de ajuda do seu navegador para obter informações sobre como alterar as configurações de cookies. Lembre-se de que a desativação de cookies pode afetar negativamente a funcionalidade de certos recursos em nosso site.</p>
                        <p>Caso tenha alguma pergunta acerca deste Política de Cookies, por favor contate-nos por meio do e-mail: sistemas@eyecarehealth.com.br</p>
                        <p>Cookies que utilizamos em nosso site:</p>

                        <table>
                            <thead>
                                <tr>
                                    <td colSpan={4} className="td-title">Cookies essenciais</td>
                                </tr>
                                <tr className="tr-head">
                                    <td>Nome</td>
                                    <td>Provedor</td>
                                    <td>Função</td>
                                    <td>Prazo</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>lidc</td>
                                    <td>linkedin.com</td>
                                    <td>O LinkedIn define o cookie lidc para facilitar a seleção do data center.</td>
                                    <td>1 dia</td>
                                </tr>
                                <tr>
                                    <td>Histórico de UserMatch</td>
                                    <td>linkedin.com</td>
                                    <td>O LinkedIn define este cookie para sincronização de ID de anúncios do LinkedIn.</td>
                                    <td>1 mês</td>
                                </tr>
                                <tr>
                                    <td>li_gc</td>
                                    <td>linkedin.com</td>
                                    <td>O Linkedin define este cookie para armazenar o consentimento do visitante em relação ao uso de cookies para fins não essenciais.</td>
                                    <td>6 meses</td>
                                </tr>
                                <tr>
                                    <td>_gcl_au</td>
                                    <td>eyecarehealth.com.br</td>
                                    <td>O Gerenciador de tags do Google define o cookie para testar a eficiência da publicidade de sites que usam seus serviços.</td>
                                    <td>3 meses</td>
                                </tr>
                                <tr>
                                    <td>CLID</td>
                                    <td>www.clarity.ms</td>
                                    <td>O Microsoft Clarity define esse cookie para armazenar informações sobre como os visitantes interagem com o site. O cookie ajuda a fornecer um relatório de análise. A recolha de dados inclui o número de visitantes, onde visitam o site e as páginas visitadas.</td>
                                    <td>1 ano</td>
                                </tr>
                                <tr>
                                    <td>_ga_*</td>
                                    <td>eyecarehealth.com.br</td>
                                    <td>O Google Analytics define este cookie para armazenar e contar visualizações de páginas.</td>
                                    <td>1 ano 1 mês 4 dias</td>
                                </tr>
                                <tr>
                                    <td>_ga</td>
                                    <td>eyecarehealth.com.br</td>
                                    <td>O Google Analytics define este cookie para calcular dados de visitantes, sessões e campanhas e rastrear o uso do site para o relatório analítico do site. O cookie armazena informações anonimamente e atribui um número gerado aleatoriamente para reconhecer visitantes únicos.</td>
                                    <td>1 ano 1 mês 4 dias</td>
                                </tr>
                                <tr>
                                    <td>Histórico de sincronização do Analytics</td>
                                    <td>linkedin.com</td>
                                    <td>O Linkedin definiu este cookie para armazenar informações sobre a hora em que ocorreu uma sincronização com o cookie lms_analytics.</td>
                                    <td>1 mês</td>
                                </tr>
                                <tr>
                                    <td>_gid</td>
                                    <td>eyecarehealth.com.br</td>
                                    <td>O Google Analytics define este cookie para armazenar informações sobre como os visitantes usam um site, ao mesmo tempo que cria um relatório analítico do desempenho do site. Alguns dos dados coletados incluem o número de visitantes, sua origem e as páginas que visitam anonimamente.</td>
                                    <td>1 dia</td>
                                </tr>
                                <tr>
                                    <td>_gat_UA-*</td>
                                    <td>eyecarehealth.com.br</td>
                                    <td>O Google Analytics define este cookie para rastrear o comportamento do usuário.</td>
                                    <td>1 minuto</td>
                                </tr>
                                <tr>
                                    <td>_fbp</td>
                                    <td>eyecarehealth.com.br</td>
                                    <td>O Facebook define este cookie para exibir anúncios quando estiver no Facebook ou em uma plataforma digital alimentada por publicidade do Facebook após visitar o site.</td>
                                    <td>3 meses</td>
                                </tr>
                                <tr>
                                    <td>_clck</td>
                                    <td>eyecarehealth.com.br</td>
                                    <td>O Microsoft Clarity define esse cookie para reter o ID de usuário do Clarity do navegador e as configurações exclusivas desse site. Isto garante que as ações realizadas durante visitas subsequentes ao mesmo site estarão vinculadas ao mesmo ID de usuário.</td>
                                    <td>1 ano</td>
                                </tr>
                                <tr>
                                    <td>_clsk</td>
                                    <td>eyecarehealth.com.br</td>
                                    <td>O Microsoft Clarity define esse cookie para armazenar e consolidar as visualizações de página de um usuário em uma única gravação de sessão.</td>
                                    <td>1 dia</td>
                                </tr>
                                <tr>
                                    <td>SRM_B</td>
                                    <td>c.bing.com</td>
                                    <td>Usado pelo Microsoft Advertising como um ID exclusivo para visitantes.</td>
                                    <td>1 ano 24 dias</td>
                                </tr>
                                <tr>
                                    <td>SM</td>
                                    <td>c.clareza.ms</td>
                                    <td>O cookie Microsoft Clarity define esse cookie para sincronizar o MUID entre domínios da Microsof</td>
                                    <td>Sessão</td>
                                </tr>
                            </tbody>
                        </table>


                        <table>
                            <thead>
                                <tr>
                                    <td colSpan={4} className="td-title">Cookies de Marketing</td>
                                </tr>
                                <tr className="tr-head">
                                    <td>Nome</td>
                                    <td>Provedor</td>
                                    <td>Função</td>
                                    <td>Prazo</td>
                                </tr>
                            </thead>
                            <tbody>
                            <tr>
                                    <td>li_sugr</td>
                                    <td>linkedin.com</td>
                                    <td>O LinkedIn define este cookie para coletar dados de comportamento do usuário para otimizar o site e tornar os anúncios no site mais relevantes.</td>
                                    <td>3 meses</td>
                                </tr>
                                <tr>
                                    <td>cookie</td>
                                    <td>linkedin.com</td>
                                    <td>O LinkedIn define esse cookie a partir dos botões de compartilhamento e tags de anúncios do LinkedIn para reconhecer IDs de navegador.</td>
                                    <td>1 ano</td>
                                </tr>
                                <tr>
                                    <td>Cookie</td>
                                    <td>www.linkedin.com</td>
                                    <td>O LinkedIn define este cookie para armazenar ações realizadas no site.</td>
                                    <td>1 ano</td>
                                </tr>
                                <tr>
                                    <td>teste_cookie</td>
                                    <td>doubleclick.net</td>
                                    <td>doubleclick.net define este cookie para determinar se o navegador do usuário suporta cookies.</td>
                                    <td>15 minutos</td>
                                </tr>
                                <tr>
                                    <td>MUID</td>
                                    <td>bing.com</td>
                                    <td>O Bing define esse cookie para reconhecer navegadores exclusivos que visitam sites da Microsoft. Este cookie é usado para publicidade, análise de sites e outras operações.</td>
                                    <td>1 ano 24 dias</td>
                                </tr>
                                <tr>
                                    <td>SRM_B</td>
                                    <td>c.bing.com</td>
                                    <td>Usado pelo Microsoft Advertising como um ID exclusivo para visitantes</td>
                                    <td>1 ano 24 dias</td>
                                </tr>
                                <tr>
                                    <td>ANONCHK</td>
                                    <td>c.clareza.ms</td>
                                    <td>O cookie ANONCHK, definido pelo Bing, é usado para armazenar o ID de sessão de um usuário e verificar os cliques dos anúncios no mecanismo de pesquisa do Bing. O cookie também ajuda na geração de relatórios e na personalização.</td>
                                    <td>10 minutos</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    
                    {/* <div id="dados-que-coletamos">
                        <h2>1. Dados que coletamos</h2>
                        <p>AO UTILIZAR AS PLATAFORMAS EYECARE, VOCÊ CONCORDA QUE A EYECARE PODERÁ COMPARTILHAR SEUS DADOS COM PROFISSIONAIS E/OU INSTITUIÇÕES DA ÁREA DE SAÚDE COMO ENFERMEIROS, MÉDICOS, CLÍNICAS E HOSPITAIS, PARA QUE ESTES ENTREM, EVENTUALMENTE, EM CONTATO COM VOCÊ PARA FORNECER INFORMAÇÕES DE ASSISTÊNCIA À SAÚDE RELACIONADOS À PLATAFORMA DE SERVIÇOS, PARA OFERECER OUTROS SERVIÇOS OU PARA REALIZAR PESQUISAS. A Eyecare pode acompanhar os sites e páginas que você visita e outros aplicativos que você utiliza, a fim de determinar quais serviços de saúde são os mais populares. Poderá também monitorar seu nível de utilização do telefone. Esta informação é usada para mostrar conteúdo dentro das nossas Plataformas para clientes cujo comportamento indique interesse por áreas específicas.</p>
                        <h2 id="cookies-e-tecnologias">1. Cookies e tecnologias semelhantes</h2>
                        <h3 id="cookies">1.1. Cookies</h3>
                        <p>A Eyecare e seus parceiros usam cookies ou tecnologias semelhantes (como web beacons) para analisar tendências, administrar a Plataforma, rastrear os movimentos dos usuários no site e reunir informações demográficas sobre nossa base de usuários como um todo.</p>
                    </div> */}
                </div>
            </div>
        </TermsStyled>
    );
}