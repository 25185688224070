import styled from "styled-components"

export const ContainerStyle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: top;
    background-color: #fff;  
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, rgba(12, 29, 89, 0.62) 12.16%, rgba(12, 29, 89, 0) 44.08%), url(${props => props.image});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-x: center;
    background-position-y: center;
    background-attachment: fixed;
    margin-bottom: -20%;

    .link {
        margin-top: 5%;
        a {
            padding-top: 5%;
            font-weight: 600;
            font-size: 20px;
            line-height: 150%;
            letter-spacing: -0.02em;
            color: #FFFFFF;
            text-decoration-line: underline !important;
        }
    }

    @media(max-width: 1000px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-size: auto !important;
        div {
            width: 100%;
        }
    }

    .grid-item {
        margin-top: 5%;
        margin-left: 9%;
        width: 30%;
        @media(max-width: 1000px) {
            margin-bottom: 15%;
            width: 80%;
        }
        h1 {
            font-style: normal;
            font-weight: 800;
            font-size: 45px;
            line-height: 130%;
            letter-spacing: -0.02em;
            color: #FFFFFF;
            @media(max-width: 1000px) {
                font-size: 40px !important;
                margin-top: 25% !important;
                margin-bottom: 0 !important;
            }
        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 130%;
            letter-spacing: -0.02em;
            color: #FFFFFF;
        }
    }

    .grid-form {
        margin-right: 9%;
        margin-left: 9%;
        margin-top: 5%;
        margin-bottom: 10%;
        width: 40%;
        @media(max-width: 1000px) {
            margin: 0 auto;
            width: 90%;
            margin-bottom: 5%;
        }
    }
`;