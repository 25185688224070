export default function Logo( { color, width, height }){
    return (
        <>
            <svg width={ width } height={ height } viewBox="0 0 204 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M29.8564 0H36.4859L44.8382 20.8802L53.3461 0H59.929L42.6498 40H36.1103L41.5998 27.86L43.9987 22.5387C41.7104 23.3155 39.2108 22.1923 38.2722 19.9656L29.8564 0Z" fill={ color }/>
            <path fillRule="evenodd" clipRule="evenodd" d="M14.731 0C10.3493 0 6.71999 1.45043 3.92874 4.38628L3.92307 4.3923C1.29315 7.20876 0 10.7779 0 15.0247C0 19.2495 1.35872 22.8205 4.10032 25.6679C6.92874 28.6009 10.5932 30.0495 15.0094 30.0495C18.4532 30.0495 21.3508 29.251 23.6267 27.5766C25.8874 25.9404 27.5361 23.7925 28.5639 21.1536L29.0517 19.9011H22.5893L22.3298 20.3875C21.5846 21.784 20.6193 22.7962 19.4422 23.4656C18.2612 24.1373 16.8143 24.4936 15.0651 24.4936C12.5205 24.4936 10.4903 23.7242 8.89996 22.228C7.56239 20.9708 6.69133 19.4326 6.29057 17.584H29.4619V15.0247C29.4619 10.7779 28.1688 7.20874 25.5389 4.39227L25.5332 4.38628C22.7419 1.45043 19.1126 0 14.731 0ZM8.88773 7.66834C10.4474 6.26886 12.3747 5.55547 14.7309 5.55547C17.0872 5.55547 19.0144 6.26886 20.5742 7.66834C21.7951 8.78627 22.6325 10.1924 23.0749 11.9184H6.387C6.82939 10.1924 7.66681 8.78627 8.88773 7.66834Z" fill={ color }/>
            <path fillRule="evenodd" clipRule="evenodd" d="M136.493 0C132.078 0 128.46 1.44897 125.738 4.39598L125.733 4.40153C123.114 7.28375 121.818 10.8457 121.818 15.0247C121.818 19.2038 123.114 22.7657 125.733 25.6479L125.738 25.6535C128.451 28.5913 131.971 30.0495 136.215 30.0495C139.186 30.0495 141.733 29.3562 143.818 27.9545C144.328 27.6115 144.811 27.226 145.265 26.7978V29.6121H151.28V0.437388H145.265V3.29193C142.977 1.09664 140.034 0 136.493 0ZM127.833 15.0244C127.833 12.2678 128.641 10.0557 130.212 8.31593C131.88 6.48254 134.038 5.55547 136.772 5.55547C139.547 5.55547 141.654 6.48441 143.202 8.29536L143.205 8.29934C144.71 10.0385 145.488 12.2563 145.488 15.0244C145.488 17.7924 144.71 20.0103 143.205 21.7494L143.202 21.7534C141.654 23.5643 139.547 24.4932 136.772 24.4932C134.038 24.4932 131.88 23.5662 130.212 21.7329C128.641 19.9931 127.833 17.781 127.833 15.0244Z" fill={ color }/>
            <path d="M94.2223 4.39108C96.9746 1.45421 100.549 0 104.862 0C108.295 0 111.307 0.883771 113.857 2.68308L113.866 2.6896C116.293 4.44676 117.969 6.78013 118.882 9.66272L119.261 10.8592H112.874L112.642 10.2834C112.076 8.87818 111.184 7.74253 109.952 6.8601C108.743 5.9941 107.354 5.55585 105.753 5.55585C104.063 5.55585 102.674 5.81516 101.556 6.29831C100.444 6.79527 99.5126 7.46758 98.7502 8.31374L98.7424 8.32239C97.0944 10.1052 96.2575 12.3179 96.2575 15.0247C96.2575 17.7315 97.0944 19.9443 98.7423 21.7271L98.7473 21.7326C100.397 23.5468 102.693 24.4936 105.753 24.4936C107.354 24.4936 108.743 24.0554 109.952 23.1894C111.184 22.3069 112.076 21.1713 112.642 19.766L112.874 19.1903H119.261L118.882 20.3867C117.969 23.2693 116.293 25.6027 113.866 27.3598L113.857 27.3664C111.307 29.1658 108.295 30.0495 104.862 30.0495C100.549 30.0495 96.9746 28.5953 94.2223 25.6584L94.2216 25.6576C91.5582 22.8088 90.2424 19.2416 90.2424 15.0247C90.2424 10.8078 91.5582 7.24066 94.2216 4.39188L94.2223 4.39108Z" fill={ color }/>
            <path d="M167.15 0.960373C168.527 0.306645 170.152 0 171.993 0H173.19V5.93856H171.77C169.045 5.93856 166.859 6.99331 165.138 9.14513C163.497 11.2275 162.664 13.7185 162.664 16.6649V29.6121H156.649V0.437388H162.664V4.366C163.785 2.9435 165.286 1.80765 167.15 0.960373Z" fill={ color }/>
            <path fillRule="evenodd" clipRule="evenodd" d="M73.9722 0C69.5905 0 65.9612 1.45043 63.1699 4.38628L63.1643 4.3923C60.5344 7.20876 59.2412 10.7779 59.2412 15.0247C59.2412 19.2495 60.5999 22.8205 63.3415 25.6679C66.1699 28.6009 69.8344 30.0495 74.2506 30.0495C77.6946 30.0495 80.5922 29.2509 82.8682 27.5764C85.1288 25.9402 86.7773 23.7924 87.8051 21.1536L88.2929 19.9011H81.8306L81.571 20.3875C80.8258 21.784 79.8605 22.7962 78.6834 23.4656C77.5024 24.1373 76.0555 24.4936 74.3063 24.4936C71.7617 24.4936 69.7315 23.7242 68.1412 22.228C66.8036 20.9708 65.9325 19.4326 65.5318 17.584H88.7031V15.0247C88.7031 10.7779 87.41 7.20876 84.7801 4.39227L84.7744 4.38628C81.9831 1.45043 78.3538 0 73.9722 0ZM68.1288 7.66826C69.6885 6.26883 71.6157 5.55547 73.9719 5.55547C76.3281 5.55547 78.2553 6.26883 79.815 7.66826C81.036 8.78621 81.8735 10.1924 82.3159 11.9184H73.9719H65.628C66.0704 10.1924 66.9078 8.7862 68.1288 7.66826Z" fill={ color }/>
            <path fillRule="evenodd" clipRule="evenodd" d="M189.116 0C184.734 0 181.105 1.45043 178.314 4.38628L178.308 4.3923C175.678 7.20876 174.385 10.7779 174.385 15.0247C174.385 19.2495 175.743 22.8205 178.485 25.6679C181.314 28.6009 184.978 30.0495 189.394 30.0495C192.838 30.0495 195.735 29.251 198.011 27.5767C200.272 25.9405 201.921 23.7926 202.949 21.1536L203.436 19.9011H196.974L196.715 20.3875C195.969 21.784 195.004 22.7962 193.827 23.4656C192.646 24.1373 191.199 24.4936 189.45 24.4936C186.905 24.4936 184.875 23.7242 183.285 22.228C181.947 20.9708 181.076 19.4326 180.675 17.584H203.847V15.0247C203.847 10.7779 202.554 7.20876 199.924 4.39227L199.918 4.38628C197.127 1.45043 193.497 0 189.116 0ZM183.372 7.62698C184.931 6.22755 186.859 5.51419 189.215 5.51419C191.571 5.51419 193.498 6.22755 195.058 7.62698C196.279 8.74492 197.116 10.1511 197.559 11.8771H189.215H180.871C181.313 10.1511 182.151 8.74492 183.372 7.62698Z" fill={ color }/>
            </svg>
        </>
    )
}