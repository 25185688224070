import marco from '../../assets/images/AboutUs/marco.png';
import ariadne from '../../assets/images/AboutUs/ariadne.png';
import daniel from '../../assets/images/AboutUs/daniel.png';

export default [
    {
        "name": "Marco Negreiros",
        "avatar": marco,
        "role": "CEO e co-fundador",
        "linkedin": "https://www.linkedin.com/in/marco-antonio-negreiros/",
        "description": "Médico pela Universidade Federal do Rio de Janeiro, oftalmologista, empreendedor, com expertise e formação em gestão e inteligência artificial.",
        "experience": "Teve experiências em empresas de saúde renomadas e sempre foi focado em estratégia, inovação, eficiência e resultados concretos."
    },
    {
        "name": "Ariadne Dias",
        "avatar": ariadne,
        "role": "COO e co-fundadora",
        "linkedin": "https://www.linkedin.com/in/ariadne-porto-dias/",
        "description": "Médica pela Universidade do Estado do Pará, administradora em saúde pelo Hospital Sírio-Libanês e empreendedora.",
        "experience": "Desde a sua formação é focada na transformação do cuidado e da experiência dos pacientes e dos profissionais de saúde por meio da tecnologia."
    },
    {
        "name": "Daniel Lessa",
        "avatar": daniel,
        "role": "CTO",
        "linkedin": "https://www.linkedin.com/in/daniel-lessa-271582a8/",
        "description": "Engenheiro de software, com uma extensa carreira dividida entre enfermagem e tecnologia.",
        "experience": "Trabalhou em projetos importantes que resolveram problemas na área da saúde pública, além disso, foi integrante da área de inovação do Einstein."
    }
]