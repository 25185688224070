import { useEffect } from "react";
import { getQueryVariable } from '../../utils/queryHelper';
import { FormStyled } from "./styles";

export default function FormB2B() {

    useEffect(() => {
        const type = getQueryVariable('type');
        if (type === 'empresa') {
            window.hbspt.forms.create({
                region: "na1",
                portalId: "39956071",
                formId: "26f11250-7201-4e9b-9236-9da3b021bf04",
                target: '#hubspotForm'
              });
        } else if (type === 'hospital') {
            window.hbspt && window.hbspt.forms.create({
                region: "na1",
                portalId: "39956071",
                formId: "1d333f10-b907-42de-8e68-16cfdf592b2c",
                target: '#hubspotForm'
            })
        }
    }, [window.hbspt])


    return (
        <FormStyled>
            <div id='hubspotForm'></div>
        </FormStyled >
    );
}