import { TermsStyled } from "./styles";
import ScrollTo from "../../utils/scrollTo";

export default function UseTerms() {
    return (
        <TermsStyled>
            <div className="container">
                <div className="list" data-wow-delay=".6s">
                    <ul className="menu">
                        {/* <li className="list-item">
                            <a onClick={() => ScrollTo("abrangencia")} title="1. Abrangência" alt="1. Abrangência"><strong>1. Abrangência</strong></a>
                        </li> */}
                        {/* <li className="list-item">
                            <a onClick={() => ScrollTo("funcionalidade")} title="2. Funcionalidade" alt="2. Funcionalidade"><strong>2. Funcionalidade</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={() => ScrollTo("acesso-aos-servicos")} title="3. Acesso aos serviços" alt="3. Acesso aos serviços"><strong>3. Acesso aos serviços</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={() => ScrollTo("das-informacoes-dos-usuarios")} title="4. Das informações dos usuários" alt="4. Das informações dos usuários"><strong>4. Das informações dos usuários</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={() => ScrollTo("das-obrigacoes")} title="5. Das obrigações do usuário" alt="5. Das obrigações do usuário"><strong>5. Das obrigações do usuário</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={() => ScrollTo("das-obrigacoes-aos-medicos")} title="6. Das obrigações dos médicos e demais profissionais da área da saúde" alt="6. Das obrigações dos médicos e demais profissionais da área da saúde"><strong>6. Das obrigações dos médicos e demais profissionais da área da saúde</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={() => ScrollTo("propriedade-intelectual")} title="7. Propriedade intelectual" alt="7. Propriedade intelectual"><strong>7. Propriedade intelectual</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={() => ScrollTo("publicidade-divulgacao")} title="8. Publicidade, divulgação e outros websites" alt="8. Publicidade, divulgação e outros websites"><strong>8. Publicidade, divulgação e outros websites</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={() => ScrollTo("suporte")} title="9. Suporte" alt="9. Suporte"><strong>9. Suporte</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={() => ScrollTo("da-responsabilidade-da-eyecare")} title="10. Da responsabilidade da Eyecare" alt="10. Da responsabilidade da Eyecare"><strong>10. Da responsabilidade da Eyecare</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={() => ScrollTo("disposicoes-finais")} title="11. Disposições finais" alt="11. Disposições finais"><strong>11. Disposições finais</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={() => ScrollTo("legislacao-aplicavel")} title="12. Legislação aplicável" alt="12. Legislação aplicável"><strong>12. Legislação aplicável</strong></a>
                        </li> */}
                        <div className="color">
                            <span>Última atualização: 22 de fevereiro de 2021</span>
                        </div>
                    </ul>
                </div>
                <div className="term" data-wow-delay=".7s">
                    <h3>EYECARE DN SOLUÇÕES DIGITAIS</h3>
                    <h1>Termo de consentimento livre e esclarecido</h1>
                    <p>
                        Estou ciente e concordo através do presente  <b>TERMO DE CONSENTIMENTO
                        LIVRE E ESCLARECIDO</b> que a <i>EYECARE DN SOLUÇÕES DIGITAIS LTDA</i>,
                        pessoa jurídica de direito privado, inscrita no CNPJ sob o nº 35.800.459/0001-54, colete
                        os dados pessoais, realize seu tratamento e tome decisões referentes aos dados com a
                        finalidade de gerir o cadastro nos procedimentos oftalmológicos a serem realizados,
                        bem como conduza o tratamento com a base legal disposta no artigo 11, inciso II, alínea
                        “f” da Lei n.º 13.709/2018, que determina a utilização por médicos ou serviços clínicos
                        com a finalidade da tutela da saúde.
                    </p>
                </div>
            </div>
        </TermsStyled>

    );
}