import { css } from 'styled-components'

export const LogoStyle = css`
    color: var(--primary);
    font-weight: 600;
    font-size: 20px !important;
    z-index: 2;
`;

export const NavStyle = css` 
    position: fixed;
    width: 100%;   
    padding-bottom: 12%;
    background-color: #fff;
    z-index: 10;
    
    @media (min-width: 1440px){
        padding-bottom: 1% !important;
    }
    
    @media (max-width: 1440px){
        padding-bottom: 7% !important;
    }

    @media (max-width: 1200px){
        padding-bottom: 8% !important;
    }
    
    @media (min-width: 1000px){
        height: 8%;
    }

    @media (max-width: 1000px){
        height: 2%;
    }

    ul {
        list-style: none;
    }

    .blue {
        color: var(--primary) !important;
    }

    .tag {
        color: var(--primary);
        background-color: var(--white);
        border-radius: 20px;
        font-size: 13px;
        padding: 8px 12px;
        margin: 6%;
    }

    @media (min-width: 1000px) {
        .mobile { display: none; }
        .desktop {
            display: flex;
            flex-direction: row;
            justify-content: center;
            
            ul {  
                width: 50%;
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                list-style: none;

                li {
                    display: flex;
                    align-items: center;
                    min-height: 100px;
                    line-height: 100px;
                    text-align: center;  
                }

                li:nth-child(1){
                    cursor: pointer;
                }

                .buttonContainer {
                    width: 30%;
                    transform: translateY(-5%);

                    button:hover {
                        transition: .3s;
                        background-color: var(--blue);
                    }
                }
                
                li > a {
                    text-align: center;
                    vertical-align: middle;
                    text-decoration: none;
                    font-size: 16px;
                    font-weight: 500;
                    color: var(--dark);
                }

                .dropbtn {
                    background-color: #fff;
                    color: var(--dark);       
                    font-size: 16px;
                    border: none;
                    transform: translateY(-5%);
                    cursor: pointer;
                }

                .dropdown {
                    position: relative;
                    display: inline-block;

                    button {
                        display: contents;
                    }
                }

                .dropdown-content {
                    display: none;
                    position: fixed;
                    background-color: #fff;
                    margin-left: -1% !important;
                    margin-top: -2% !important;
                    min-width: 300px;
                    border-radius: 20px;
                    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
                    z-index: 1;
                    text-align: start;
                }

                .dropdown-content a {
                    color: var(--dark);
                    height: 70px;
                    padding: 3px 28px;
                    font: 10px !important;
                    text-decoration: none;
                    display: block;
                    transform: translateY(-25%);
                }

                .dropdown:hover .dropdown-content {display: block;}

                .dropdown .dropbtn .icon {
                    transition: .3s;
                    vertical-align: middle;
                }

                .dropdown:hover .dropbtn .icon{
                    transition: .3s;
                    transform: rotate(180deg) ;
                }
            }
        }
    }

    @media (max-width: 999px){
        .desktop {
            display: none;
        }

        button {
            border: none;  
            display: flex;
            background-color: var(--secondary);
            cursor: pointer;
            padding: 8px;
            border-radius: 50% !important;  
            z-index: 1;    
        }

        .mobile {   
            background-color: #fff;
            padding: 4%; 
            
            nav {
                background-color: #fff;
            }

            .topMenu {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                
                li {
                    display: flex;
                    align-items: center;
                }
            }

            .show {        
                position: absolute;
                width: 90%;
                height: 30%;
                display: contents;
                justify-content: space-between;
                background: #FFFFFF;
                border-bottom: solid 1px rgba(0,0,0,0.2);   
                animation: expand .1s linear;  
            }

            .hidden {
                display: none;
            }

            @keyframes expand {
                0% {
                    height: 0%;
                }
                100% {
                    height: 30%;
                }
            }

            @media (max-width: 330px) {
                .mobileOption {
                     margin: 10% 0%;
                    }
                }

            @media (max-width: 700px) {
                .mobileOption {
                     margin: 7% 0%;
                    }
                }

            @media (min-width: 701px) {
                .mobileOption {
                    margin: 3% 0%;
                    }
                }
           
            .mobileOption .option {
                font-size: 1.2em;
                font-weight: 800;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin: 0px 2px;

                ul li {
                    font-size: 17px;
                    font-weight: 700;
                }

                a {
                    height: 36px;
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    color: var(--primary);
                }

                button {
                    display: flex;
                    background-color: var(--secondary);
                    padding: 8px;
                    border-radius: 50%;
                    border: none;
                    cursor: pointer;
                }
            }

            .mobileOption .optionsSubmenu {
                margin: 1% 2%;

                li {
                    font-weight: 700;
                    margin: 25px;

                    a {
                        color: var(--dark);
                        text-decoration: none;
                    }
                }
            }

            .mobileContractButton {
                display: flex;
                justify-content: center;
                margin-top: 10%;            
                button {
                    transition: .3s;
                    border-radius: 50px !important;
                    background-color: var(--primary);
                    justify-content: center;
                    display: block;
                }

                button:hover {
                    transition: .3s;
                    background-color: var(--blue);
                }

                @media (max-width: 700px) {
                    button {
                        width: 100%;
                        padding: 3%;
                    }
                }

                @media (min-width: 701px) {
                    button {
                        width: 50%;
                        padding: 2%;
                    }
                }
            }
        }
    }
`;