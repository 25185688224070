import FormContainer from '../../components/FormContainer'
import image from '../../assets/images/Products/formb2b.png';

export default function formb2b() {
    return (
        <FormContainer 
            type="b2b"
            title='Invista na saúde ocular do futuro'
            text='Seja para melhorar a gestão da sua clínica ou hospital ou a saúde dos seus colaboradores, nossa equipe está sempre pronta para ajudar'
            image={image}
            href="/produtos/meu-cuidado-ocular/quero-contratar"
            textLink="É paciente? Clique aqui."
        />
    );
}