import { css } from 'styled-components'


export const ButtonStyle = css`
    font-weight: 500;
    font-size: 16px;
    border-radius: 50px;
    height: 53px !important;
    border: none;
    cursor: pointer;
`