import FormContainer from '../../components/FormContainer'
import image from '../../assets/images/Products/formb2c.png';

export default function MyEyeCareWantHire() {
    return (
        <FormContainer type="b2c"
            title='Deixe a sua saúde ocular em boas mãos'
            text='Somos especialistas em saúde ocular e podemos ajudar você a viver plenamente todas as fases da sua vida'
            image={image}
            href="/produtos/empresas/quero-contratar?type=hospital"
            textLink="É empresa, clínica ou hospital? Clique aqui."
        />
    );
}