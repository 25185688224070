import styled from 'styled-components'

export const AboutContainer = styled.div`
    .desktop {
        @media(max-width: 1000px){
            display: none !important;
        }
    }

    .mobile {
        @media(max-width: 1000px){
            display: flex !important;
        }
        @media(min-width: 1000px){
            display: none !important;
        }
    }
    .topBanner {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 500px;
        background-color: var(--dark-blue);

        .imageContainer {
            margin: 0 auto;
            margin-bottom: 5% !important;
            overflow: hidden;

            @media(max-width: 1000px){
                svg {
                    width: 300;
                    height: 80;
                }
            }
            @media(max-width: 600px){
                svg {
                    display: none;
                }
            }
        }

        .titleContainer {
            width: 80%;
            margin: 0 auto;
            @media(max-width: 600px){
                margin-top: 30%;
            }
            @media(max-width: 400px){
                margin-top: 30%;
            }

            h1 {
                text-align: center;
                color: #fff;
                font-size: 45px;
                font-weight: 800;

                @media (min-width: 1440px){
                    font-size: 50px;
                }

                @media (max-width: 1000px){
                    font-size: 30px;
                }
            }

            h3 {
                text-align: center;
                color: #fff;
                font-weight: 500;
                font-size: 18px;
                width: 50%;
                margin: 3% auto;
                @media (min-width: 1440px){
                    font-size: 20px;
                }

                @media (max-width: 1000px){
                    font-size: 14px;
                }
            }
        }
    }

    .container {
        width: 80%;
        margin: 10% auto;

        h2 {
            font-size: 36px;
            font-weight: 800;
            text-align: center;
            margin: 0 auto;
            /* margin: 10% auto 4% auto; */
            width: 70%;
            -webkit-touch-callout: none;  /* iPhone OS, Safari */
            -webkit-user-select: none;    /* Chrome, Safari 3 */
            -khtml-user-select: none;     /* Safari 2 */
            -moz-user-select: none;       /* Firefox */
            -ms-user-select: none;        /* IE10+ */
            user-select: none;       

            @media (min-width: 1440px){
                font-size: 45px;
            }

            @media (max-width: 1000px){
                font-size: 30px;
                width: 100% !important;
            }
        }

    }
    .leadership {
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        @media(min-width: 1000px){
            margin-top: -8% !important;
        }

        @media(max-width: 1000px){
            flex-direction: column;
        }

        .leader {
            text-align: center;
            padding: 5%;
            * {
                margin: 2% auto;
            }

            h4 {
                font-size: 25px;
            }

            svg {
                cursor: pointer;
            }

            img {
                @media(max-width: 1000px) {
                    width: 80%;
                }
                border-radius: 50%;
            }

            .content {
                p {
                    text-align: left;
                }
                p:last-child {
                    margin-top: 5%;
                }
            }
        }
    }

    .concil {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 75%;
        height: 100%;
        margin: -7% auto;
        @media(max-width: 1000px) {
            margin-top: 0% !important;
        }
        align-items: center;

        & > svg {
            cursor: pointer;               
        }

        .message {
            width: 80%;
            -webkit-touch-callout: none;  /* iPhone OS, Safari */
            -webkit-user-select: none;    /* Chrome, Safari 3 */
            -khtml-user-select: none;     /* Safari 2 */
            -moz-user-select: none;       /* Firefox */
            -ms-user-select: none;        /* IE10+ */
            user-select: none; 
            
            @media(max-width: 1000px){
                width: 100% !important;
            }

            .text {
                width: 80%;
                margin: 0 auto;
                display: flex;
                flex-direction: row;
                align-items: flex-end;  
                svg {
                    margin-right: 20px;
                }  

                @media(max-width: 1000px){
                    width: 100% !important;
                }
            }

            .specialist {
                margin: 10% auto;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 60%;
                @media(max-width: 1000px){
                    width: 100% !important;
                    margin-bottom: 80px;
                }

                p {
                    margin-left: 5%;
                    span { font-weight: 800; }
                }
            }
        }
    }

    .mobileConcilButtons{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 80%;
        margin: 0 auto;
        margin-bottom: 80px !important;
    }

    .supporters {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin: 8% auto;
        @media(max-width: 1000px){
            flex-direction: column;
            margin: 10% auto !important;
            img {
                width: 60% !important;
                margin: 5% auto !important;
            }
        }

        a {
            text-decoration: none !important;
        }
    }

    .award {
        width: 80%;
        display: flex;
        flex-direction: row;
        background-color: var(--brand-color-3);
        margin: 8% auto;
        padding: 5%;
        border-radius: 20px;

        @media(min-width: 1000px){
            div {
                width: 50%;
            }
        }

        @media(max-width: 1000px){
            flex-direction: column;
            align-items: center;
        }

        div:nth-child(1){
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            @media(max-width: 1000px){
                text-align: center;
                p, a, h3 {
                    margin: 5% auto;
                    width: 80%;
                }
            }
            

            h3 {
                color: #fff;
                font-size: 40px;
                font-weight: 800;
                @media (max-width: 600px) {
                    font-size: 30px;
                }
            }

            p { color: #fff; }

            a {
                color: #fff;
                text-decoration: underline;
            }                   
        }

        .download {   
            @media (min-width: 1000px) {
                display: flex;
                flex-direction: row !important;
                align-items: center !important;
                padding: 5%;
                a {
                    margin-right: 8%;
                    cursor: pointer;
                }
            }

            @media (max-width: 1000px) {
                display: flex;
                flex-direction: column !important;
                align-items: center !important;
                padding: 7%;
                a {
                    margin-top: 8%;
                    cursor: pointer;
                }
            }
        }

        .div-glauco {
            margin-top: 5%;
            img {
                @media(max-width: 1000px) {
                    width: 100%;
                }
            }
        }
    }

    .social {
        width: 15%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 2% auto;
        margin-bottom: 12%; 

        @media(max-width: 1000px){
            justify-content: space-around !important;

            a {
                margin: 5px 10px;
            }
        }

        svg {
            cursor: pointer;
        }
    }

    .cards {
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        margin: 2% auto;

        @media (max-width: 1000px){
            flex-direction: column;
        }
    }
`;