import styled from 'styled-components'

export const CardContainer = styled.a`
    height: 300px;
    width: 100%; 
    border-radius: 20px;
    padding: 5% 4%;
    margin: 1%;
    cursor: pointer;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-decoration: none !important;
    @media(max-width: 1000px){
        justify-content: space-evenly;
    }

    p {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 50%;
        font-size: 25px;
        font-weight: 800; 
        color: #1A1E2B;
    }

    .imgContainer {
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: flex-end;
        width: 100% !important;
        margin: 5% auto;
        @media(max-width: 1000px){
                justify-content: center;
            }
        img {
            margin: 0 auto;

            @media(max-width: 1000px){
                width: 40% !important;
            }
        }
    }
`;