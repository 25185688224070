import styled from "styled-components";

export const StyledCard = styled.div`
    
    margin: 5% auto;
    width: 80%;
    background: var(--secondary);
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 800px){
        width: 90%;
        padding: 8% 0%;
    }

    @media (min-width: 800px){
        padding: 4% 5%;
    }

    & > div:nth-child(1) { 
        width: 65%;

        @media (max-width: 1000px) {
            width: 90%;
        }
    }

    button { transition: .3s; }
    button:hover {
        transition: .3s;
        background-color: var(--blue);
    }

    @media (max-width: 1000px){ 
        display: flex;
        flex-direction: column;
        align-items: center;
        & > div:nth-child(2) {
            display: flex;
            flex-direction: row;
            align-content: center;
            width: 100%;
            padding: 5%;
           
        }
    }

    @media(max-width: 1000px) {
        button {
            margin: 0 auto;
        }
    }
`;

export const TitleStyled = styled.h3`
    font-style: normal;
    font-weight: 800;
    font-size: 35px;

    @media(max-width: 1000px) {
        display: flex;
        flex-direction: column;
        font-size: 28px;
        text-align: center !important;
        margin: 0 auto;
        width: 100% !important;
    }

    @media(max-width: 400px) {
        font-size: 25px !important;
        
    }

    .Typewriter {
        display: unset;
       
    }
`;
