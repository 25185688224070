import { TermsStyled } from "./styles";
import ScrollTo from "../../utils/scrollTo";

export default function EyecareFinanciaTerms() {
    return (
        <TermsStyled>
            <div className="container">
                <div className="list" data-wow-delay=".6s">
                    <ul className="menu">
                        <li className="list-item">
                            <a onClick={(e) => {ScrollTo("aceitacao", e)}} title="1. Aceitação" alt="1. Aceitação"><strong>1. Aceitação</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("servicos", e)} title="2. Serviços disponibilizados da plataforma Eyecare Financia" alt="2. Funcionalidade"><strong>2. Serviços disponibilizados da plataforma Eyecare Financia</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("abertura", e)} title="3. Abertura e movimentação da conta de movimentação restrita" alt="3. Acesso aos serviços"><strong>3. Abertura e movimentação da conta de movimentação restrita</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("informacoes-gerais", e)} title="4. Informações gerais sobre a plataforma Eyecare Financia" alt="4. Das informações dos usuários"><strong>4. Informações gerais sobre a plataforma Eyecare Financia</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("cadastro-e-uso", e)} title="5. Cadastro e uso da plataforma Eyecare Financia" alt="5. Das obrigações do usuário"><strong>5. Cadastro e uso da plataforma Eyecare Financia</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("cancelamento", e)} title="6. Cancelamento, suspensão e descontinuação do cadastro" alt="6. Cancelamento, suspensão e descontinuação do cadastro"><strong>6. Cancelamento, suspensão e descontinuação do cadastro</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("propriedade-intelectual", e)} title="7. Propriedade intelectual e licença de uso" alt="7. Propriedade intelectual e licença de uso"><strong>7. Propriedade intelectual e licença de uso</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("remuneracao", e)} title="8. Remuneração e tarifas" alt="8. Remuneração e tarifas"><strong>8. Remuneração e tarifas</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("responsabilidades", e)} title="9. Responsabilidades" alt="9. Responsabilidades"><strong>9. Suporte</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("protecao-de-dados", e)} title="10. Proteção de dados e confidencialidade" alt="10. Proteção de dados e confidencialidade"><strong>10. Proteção de dados e confidencialidade</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("prazos-e-recisao", e)} title="11. Prazos e recisão contratual" alt="11. Prazos e recisão contratual"><strong>11. Prazos e recisão contratual</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("prevencao-a-corrupcao", e)} title="12. Prevenção à corrupção, lavagem de dinheiro e financiamento do terrorismo" alt="12. Prevenção à corrupção, lavagem de dinheiro e financiamento do terrorismo"><strong>12. Prevenção à corrupção, lavagem de dinheiro e financiamento do terrorismo</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("disposicoes-gerais", e)} title="13. Disposições gerais" alt="13. Disposições gerais"><strong>13. Disposições gerais</strong></a>
                        </li>
                        <div className="color">
                            <span>Última atualização: 01 de Agosto de 2024</span>
                        </div>
                        <li className="list-item second title">
                            <a onClick={(e) => ScrollTo("anexo-I", e)} title="Anexo I - Eyecare Financia" alt="Anexo I - Eyecare Financia"><strong>Anexo I - Eyecare Financia</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("anexo-solucao", e)} title="1. Eyecare Financia - Solução" alt="1. Eyecare Financia - Solução"><strong>1. Eyecare Financia - Solução</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("anexo-aceitacao", e)} title="2. Aceitação" alt="2. Aceitação"><strong>2. Aceitação</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("anexo-operacoes", e)} title="3. Operações de antecipação, crédito, empréstimo, financiamento e/ou de buy now pay later" alt="3. Operações de antecipação, crédito, empréstimo, financiamento e/ou de buy now pay later"><strong>3. Operações de antecipação, crédito, empréstimo, financiamento e/ou de buy now pay later</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("anexo-etapas", e)} title="4. Etapas das operações de antecipação, crédito, empréstimo, financiamento e/ou de buy now pay later" alt="4. Etapas das operações de antecipação, crédito, empréstimo, financiamento e/ou de buy now pay later"><strong>4. Etapas das operações de antecipação, crédito, empréstimo, financiamento e/ou de buy now pay later</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("anexo-consulta", e)} title="5. Consulta a beureaus de crédito e ao scr" alt="5. Consulta a beureaus de crédito e ao scr"><strong>5. Consulta a beureaus de crédito e ao scr</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("anexo-cancelamento", e)} title="6. Cancelamento" alt="6. Cancelamento"><strong>6. Cancelamento</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("anexo-liquidacao", e)} title="7. Liquidação" alt="7. Liquidação"><strong>7. Liquidação</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={(e) => ScrollTo("anexo-limites", e)} title="8. Limites de responsabilidade" alt="8. Limites de responsabilidade"><strong>8. Limites de responsabilidade</strong></a>
                        </li>
                        <div className="color">
                            <span>Última atualização: 01 de Agosto de 2024</span>
                        </div>
                    </ul>
                </div>
                <div className="term" data-wow-delay=".7s">
                    <h1>Termos e condições de uso - Eyecare Financia</h1>
                    <p>Olá, seja bem-vindo à plataforma Eyecare Financia!</p>
                    <p>Ficamos felizes por você estar aqui e esperamos que você aproveite tudo o que temos para oferecer.</p>
                    <p>A seguir a EYECARE DN INTERMEDIAÇÕES LTDA., com sede da Rua dos Pinheiros 623, conjunto 121/122, CEP 05422-012-, inscrita no CNPJ sob o nº 53.757.913/0001-13 (“Eyecare”) apresenta as regras a serem observadas durante o acesso e utilização da Plataforma Eyecare Financia. Por isso, é indispensável que todos os interessados em utilizar nossos serviços leiam atentamente e compreendam todas as disposições destes Termos e Condições de Uso da Plataforma Eyecare Financia(“TCU”).</p>
                    <p>Caso tenha dúvidas ou precise tratar de qualquer assunto relacionado a este TCU, entre em contato conosco de segunda a sexta-feira, das 09h às 18h, pelo nosso e-mail atendimento@eyecarehealth.com.br.</p>

                    <h3 id="aceitacao">1. Aceitação</h3>
                    <p>1.1.  Aceitação e Anuência. Ao assinar a Proposta de Adesão, acessar e/ou utilizar a Plataforma Eyecare Financia, a pessoa jurídica fornecedora de produtos e/ou prestadora de serviços de saúde e/ou bem-estar qualificada na Proposta de Adesão a este TCU (“Usuário”), adere e aceita, sem ressalvas, este Termos e Condições de Uso da Plataforma Eyecare Financia.</p>
                    <p>1.2.  Não aceitação. Caso o Usuário não concorde com quaisquer dos termos e condições estabelecidos neste TCU, este não deverá utilizar a Plataforma Eyecare Financia.</p>

                    <h3 className="spc" id="servicos">2. Serviços disponibilizados na plataforma Eyecare Financia</h3>
                    <p>2.1. Cancelamento ou Fornecimento de Novos Serviços.  A Eyecare poderá, a seu critério e a qualquer tempo, mediante comunicação prévia, cancelar o fornecimento de qualquer Serviço, sem que seja devida qualquer indenização ao Usuário, assim como disponibilizar novos Serviços. O cancelamento de qualquer Serviço pela Eyecare deverá ser informado com antecedência mínima de 2 (dois) dias. A utilização dos novos Serviços pelo Usuário implicará na aceitação de todos os termos e condições que venham a ser estabelecidos pela Eyecare e informados ao Usuário. </p>
                    <div className="row">
                        <div className="col-md-11 offset-md-1">
                            <p>a. Eyecare Financia. A Eyecare em conjunto com seus Parceiros Correspondentes Bancários, disponibiliza ao Usuário e/ou aos clientes do Usuário, conforme o caso, através de instituições financeiras parceiras devidamente autorizadas a funcionar pelos órgãos competentes (“Financeiras Parceiras”), operações de antecipação, crédito, empréstimo, financiamento e/ou de buy now pay later para o pagamento de serviços prestados pelo Usuário aos seus clientes. Os termos e condições para utilização do Eyecare Financia estão definidos no Anexo I a este TCU;</p>
                        </div>
                    </div>
                    <p>2.2. A Eyecare, que é uma empresa de tecnologia no setor de saúde ocular, em conjunto com seus Parceiros Correspondentes Bancários, disponibilizará ao Usuário os seguintes serviços de intermediação e gestão de pagamentos por meio da Plataforma Eyecare Financia (“Serviços”):</p>
                    
                    <h3 id="abertura">3. Abertura e movimentação de conta de movimentação restrita</h3>
                    <p>3.1. Abertura de Conta. Para viabilizar a prestação dos Serviços, o Usuário está ciente e concorda que após a aprovação de seu cadastro na Plataforma Eyecare Financia, seja aberta conta de pagamento de sua titularidade na Stark Bank Instituição de Pagamentos (“Conta de Recebíveis”), inscrita no CNPJ nº 20.018.183/0001-80, ou de outra instituição financeira indicada pela Eyecare, para recebimento de todos os valores que lhes são devidos pelos seus clientes e que sejam pagos mediante utilização dos Serviços objeto do TCU. A Conta de Recebíveis deverá ser mantida aberta enquanto o Usuário mantiver em vigor a contratação dos Serviços Plataforma Eyecare Financia.</p>
                    <p>3.2. Movimentação restrita. A Conta de Recebíveis deverá ser utilizada pelo Usuário exclusivamente para fins de recebimento dos valores pagos por seus clientes mediante utilização dos Serviços, assim como para o pagamento dos valores devidos à Eyecare Financia. As movimentações a débito na Conta de Recebíveis serão realizadas exclusivamente pelos Parceiros Correspondentes Bancários, observando as regras de divisão definidas na Plataforma Eyecare Financia.</p>
                    <p>3.3. Débito de valores. Os Parceiros Correspondentes Bancários ficam desde já autorizados a debitar do saldo da Conta de Recebíveis quaisquer valores devidos pelo Usuário à Eyecare e a terceiros por ele indicados, inclusive, mas sem limitação, a remuneração da Eyecare, conforme disposto neste TCU e no Termo de Adesão comercial, bem como royalties e valores devidos pela aquisição de produtos de fornecedores. Na hipótese de inexistência de saldo para tanto, seja por bloqueio judicial ou outro motivo, o Usuário deverá reembolsar à Eyecare, e/ou aos seus Parceiros, os valores a ela devidos, corrigidos de acordo com a variação do IPCA/IBGE desde a data em que o valor seria devido até a data do reembolso, acrescido de juros de 1% ao mês, sem prejuízo da possibilidade de cancelamento, pela Eyecare, do acesso à Plataforma Eyecare Financia..</p>
                    <p>3.4. Resgate de saldo. O repasse automático do saldo da Conta de Recebíveis para outra conta de titularidade do Usuário indicada na Plataforma Eyecare Financia será realizado gratuitamente de acordo com periodicidade definida na Proposta de Adesão. Repasses extraordinários, assim entendidos aqueles feitos fora do período previamente acordado, estão sujeitos a cobrança de tarifas para sua realização. A Eyecare Financia concluirá o resgate no prazo de até 24 (vinte e quatro) horas úteis da solicitação do Usuário.  Em havendo lançamentos a débito a serem realizados na Conta de Recebíveis, os valores respectivos, acrescidos das tarifas incidentes, serão deduzidos do saldo da Conta de Recebíveis antes da efetivação da transferência.</p>
                    <p>3.5. Linha de crédito. A abertura da Conta de Recebíveis não implica na criação de qualquer tipo de linha de crédito em favor do Usuário. Os valores mantidos na Conta de Recebíveis não estão sujeitos a qualquer tipo de correção, atualização, rentabilidade ou juros.</p>
                    <p>3.6. Mandato. O Usuário, nomeia e constitui a Eyecare, e os Parceiros Correspondentes Bancários por Ela indicados, como seus bastantes procuradores, outorgando-lhe poderes específicos para praticar todos os atos necessários à prestação dos serviços objeto do TCU e de seus Anexos, podendo (i) solicitar a abertura e movimentação da Conta de Recebíveis ; (ii) recepcionar e implementar chaves, também conhecidos como tokens de identificação, de configuração das instituições financeiras e de pagamento parceiras. Os poderes deste mandato perduram durante a vigência deste TCU.</p>
                    <p>3.7. Da troca de conta. À Eyecare, e aos seus Parceiros Correspondentes Bancários, será facultado, a seu livre critério, a possibilidade de substituir a instituição financeira utilizada como domicílio bancário da Conta de Recebíveis, mediante comunicação prévia de 5 dias. Para tanto, o Usuário, nomeia e constitui a Eyecare, bem como seus Parceiros Correspondentes Bancários, como seus bastante procuradores, outorgando-lhe poderes específicos para praticar todos os atos necessários à prestação dos serviços objeto do TCU e de seus Anexos, podendo (i) solicitar a abertura e movimentação da nova Conta de Recebíveis; (ii) recepcionar e implementar chaves, também conhecidos como tokens de identificação, de configuração das instituições financeiras e de pagamento parceiras. Quando da substituição, a Eyecare obriga-se e se responsabiliza por transferir o saldo da Conta de Recebíveis (anteriormente aberta) para a nova conta. Os demais direitos e deveres dispostos neste TCU à respeito da Conta de Recebíveis serão mantidos para a nova conta. </p>
                    <p>3.8. Domicílio Bancário. O Usuário é responsável por manter atualizado seu Domicílio Bancário na Plataforma Eyecare Financia. Caso a instituição do Domicílio Bancário declare-se impedida, por qualquer motivo, de dar cumprimento às ordens de crédito emitidas pela Plataforma Eyecare Financia, deverá o Usuário providenciar sua regularização ou, ainda, indicar e cadastrar novo Domicílio Bancário, no prazo de até 2 (dois) dias úteis, sendo certo que os pagamentos anteriormente à alteração efetuada e ainda não repassados ao Usuário serão creditados na nova conta de Domicílio Bancário. A Eyecare, bem como seus Parceiros, estão autorizados a solicitar a retenção até a regularização do Domicílio Bancário cadastrado na Plataforma Eyecare Financia ou resolução da questão que esteja impedindo a transferência dos valores para a conta indicada pelo Usuário. Os pagamentos realizados ao Usuário, previstos nesta Cláusula, serão isentos de quaisquer ônus, penalidades ou encargos.</p>
                    <p>3.8.1. Dia de não funcionamento bancário. Na hipótese de a data prevista para o crédito do valor líquido ser considerada feriado ou dia de não funcionamento bancário na praça de compensação da conta do Domicílio Bancário do Usuário, o pagamento será realizado no primeiro dia útil subsequente.</p>
                    
                    <h3 className="spc" id="informacoes-gerais">4. Informações gerais sobre a plataforma Eyecare Financia</h3>
                    <p>4.1. Plataforma Eyecare Financia. A Plataforma Eyecare Financia é um ambiente digital que permite ao Usuário utilizar os Serviços para disponibilizar aos seus clientes meios de pagamento com segurança e facilidade, oferecendo uma experiência de navegação única. Para acessar a Plataforma Eyecare Financia e utilizar suas funcionalidades, o Usuário deverá dispor de dispositivos e equipamentos compatíveis (computador, notebook, tablet e/ou telefone celular), conectados à internet, com programa antivírus e firewall habilitados, softwares e navegadores devidamente atualizados, além da adoção de medidas de segurança cibernética mínimas, como o uso de senha forte, exercícios de conscientização de segurança sobre phishing e sobre ataques cibernéticos.</p>
                    <p>4.2. Aprimoramentos. A Plataforma Eyecare Financia e suas funcionalidades são apresentadas ao Usuário da maneira como estão disponíveis, podendo passar por aprimoramentos e atualizações, sendo que a Eyecare se compromete a: (i) preservar o bom funcionamento da Plataforma, com o uso de links funcionais e layout que respeita a usabilidade e navegabilidade, sempre que possível; (ii) exibir as funcionalidades de maneira clara, completa, precisa e suficiente para que exista a exata percepção das operações realizadas; e (iii) envidar os melhores esforços para garantir o sigilo dos dados inseridos na Plataforma Eyecare Financia. </p>
                    <p>4.3. Adição ou Remoção de funcionalidades. A Eyecare reserva-se o direito de, a seu critério e a qualquer tempo, alterar ou remover funcionalidades da Plataforma Eyecare Financia que não estejam alinhadas com seus interesses, desde que comunicados com 2 dias de antecedência, sem que seja devido ao Usuário qualquer indenização. A Eyecare poderá, ainda, adicionar novas funcionalidades à Plataforma Eyecare Financia, mediante comunicação ao Usuário. </p>
                    <p>4.4. Suspensão temporária dos Serviços. Manutenções preventivas ou corretivas, assim como aprimoramentos, adições ou remoções de funcionalidades poderão causar suspensões temporárias dos Serviços, sem que seja devida qualquer indenização ao Usuário por conta da suspensão temporária do acesso.</p>
                    <p>4.5. Suporte ao Usuário.  A Eyecare oferecerá suporte ao Usuário com relação aos Serviços, o que implica no esclarecimento de dúvidas com relação ao uso da Plataforma Eyecare Financia por meio da Central de Atendimento, de segunda à sexta, das 09h às 18h e, no sábado, das 09h às 12h, através dos canais disponibilizados pela empresa. A Eyecare deverá minimamente disponibilizar atendimento via e-mail: atendimento@eyecarehealth.com.br.</p>
                    
                    <h3 className="spc" id="cadastro-e-uso">5. Cadastro e uso da plataforma Eyecare Financia</h3>
                    <p>5.1.  Cadastro do Usuário. Para usar os Serviços e funcionalidades da Plataforma Eyecare Financia, o Usuário deverá efetuar seu cadastro na Plataforma Eyecare Financia, informando todos os dados solicitados. O fornecimento de dados incompletos ou incorretos poderá impedir a utilização da Plataforma Eyecare Financia pelo Usuário.</p>
                    <p>5.2. Cadastro de operadores. Ao se cadastrar na Plataforma Eyecare Financia, o Usuário designará operadores que utilizarão a Plataforma Eyecare Financia em seu nome e benefício, responsabilizando-se integralmente pelos atos ou omissões praticados por estes. No ato do cadastro, o Usuário deverá informar CPF, nome completo, e-mail e telefone celular dos operadores designados.</p>
                    <p>5.3. Maiores de 18 anos. A utilização da Plataforma somente será permitida para maiores de 18 (dezoito) anos e com plena capacidade civil. Isso é válido para o Usuário ou para os operadores por ele designados. Caso o Usuário não esteja de acordo com o exigido, não deverá prosseguir com a criação da conta de acesso ou utilizar os Serviços.</p>
                    <p>5.4. Aprovação de cadastro. A aprovação do cadastro do Usuário depende da análise e avaliação de seu perfil tanto pela Eyecare Financia, seus Parceiros Correspondentes Bancários, como pelas Financeiras e Instituições de Pagamentos Parceiras. É facultado à Eyecare e aos seus parceiros recusar o cadastro de Usuário ou de pessoa por ele designada sem a necessidade de apresentação de justificativa, assim como a disponibilização parcial dos Serviços ao Usuário.</p>
                    <p>5.5. Veracidade de dados cadastrais. O Usuário está ciente que é o único responsável pela veracidade, completude, exatidão e atualização das informações cadastrais, responsabilizando-se por qualquer prejuízo decorrente da falsidade ou desatualização das informações. O Usuário responderá em âmbito cível e criminal, pela veracidade, exatidão e autenticidade dos dados informados, bem como pelos atos ou omissões dos operadores por ele designados. A Eyecare, em hipótese alguma, será responsável pela veracidade das informações que o Usuário disponibilizar. Todas as informações estão sujeitas às medidas de segurança que impeçam o acesso, o uso e a divulgação não autorizados. Para saber mais sobre a forma que a Eyecare coleta e processa suas informações, por favor, acesse a nossa Política de Privacidade disponibilizada em nosso site.</p>
                    <p>5.6. Verificação de veracidade. A Eyecare se reserva o direito de verificar, a qualquer momento, a veracidade das informações fornecidas pelo Usuário, bem como solicitar, a seu exclusivo critério, esclarecimentos e/ou apresentação de documentação suplementar que julgar necessária para a comprovação das informações prestadas, podendo, inclusive, recusar, suspender ou cancelar o cadastro se houver recursa ou impossibilidade do Usuário de realizar ou apresentar os esclarecimentos ou documentos exigidos pela Eyecare.</p>
                    <p>5.7. Atualização de dados cadastrais. O Usuário deverá comunicar à Eyecare quaisquer alterações em seus dados cadastrais ou dos operadores por ele designados no prazo de 05 (cinco) dias. De tempos em tempos a Eyecare poderá, a seu exclusivo critério, solicitar a atualização dos dados cadastrais, podendo a recursa ser penalizada com o bloqueio de acesso à Plataforma Eyecare Financia até a devida regularização da situação.</p>
                    <p>5.8. Autorização para consulta a bancos de dados. O Usuário está ciente e concorda que a Eyecare, e/ou seus Parceiros Correspondentes Bancários, poderão consultar quaisquer bancos de dados, inclusive o Sistema de Informações de Crédito (“SCR”), para confirmar e obter informações a seu respeito, incluindo informações de crédito e judiciais.</p>
                    <p>5.9. Login e Senha. O login e senha de acesso à Plataforma Eyecare são únicos, pessoais e intransferíveis, não devendo ser compartilhados com terceiros. A guarda e sigilo de tais dados é de inteira responsabilidade do Usuário e dos operadores por ele designados. Toda e qualquer operação realizada na Plataforma Eyecare com estes dados será considerada uma operação legítima realizada pelo Usuário.</p>
                    <p>5.9.1. O Usuário deverá comunicar imediatamente à Eyecare qualquer evento de perda, roubo, furto, extravio, suspeita ou confirmação de vazamento dos dados de acesso à Plataforma Eyecare Financia. A Eyecare não se responsabiliza por quaisquer atos praticados na Plataforma Eyecare Financia em nome do Usuário antes da efetiva comunicação. Para casos de esquecimento de senha, é possível criar uma nova senha de acesso mediante solicitação informando o endereço de e-mail utilizado no cadastro inicial do Usuário na Plataforma. Senhas antigas não poderão ser recuperadas.. A Eyecare não se responsabiliza por quaisquer atos praticados na Plataforma Eyecare Financia em nome do Usuário antes da efetiva comunicação. Para casos de esquecimento de senha, é possível criar uma nova senha de acesso mediante solicitação informando o endereço de e-mail utilizado no cadastro inicial do Usuário na Plataforma. Senhas antigas não poderão ser recuperadas.</p>
                    <p>5.10.  Procuração. O Usuário nomeia e constitui a Eyecare a sua bastante procuradora, a quem confere plenos poderes para: (i) cadastrar e credenciar o Usuário junto às Financeiras Parceiras e Instituições de Pagamento Parceiras; (ii) recepcionar e implementar chaves (também conhecidos como tokens de identificação) de configuração das Financeiras Parceiras e das Instituições de Pagamento Parceiras na Plataforma Eyecare Financia, possibilitando assim, ao Usuário, a oferta dos Serviços; (iii) representar a vontade do Usuário perante as Financeiras Parceiras e Instituições de Pagamento Parceiras, podendo, entre outras ações, realizar solicitações de crédito ou financiamento para os clientes do Usuário, de pagamento para os clientes do Usuário, de cancelamento de operações referentes aos Serviços, bem como as demais ações para o pleno funcionamento da Plataforma Eyecare Financia. Tais poderes somente poderão ser praticados pela Eyecare durante a vigência deste Contrato ou enquanto perdurarem as obrigações acordadas com a Eyecare, podendo, inclusive, substabelecer os exatos poderes acima detalhados para seus Parceiros Correspondentes Bancários, no limite e extensão do acima estabelecido.</p>
                    
                    <h3 className="spc" id="cancelamento">6. Cancelamento, suspensão e descontinuação do cadastro</h3>
                    <p>6.1. Do cancelamento. O Usuário tem o direito de solicitar o cancelamento do seu cadastro na Plataforma Eyecare Financia a qualquer momento. Toda e qualquer solicitação de cancelamento deverá, necessariamente, ser feita diretamente através do e-mail: atendimento@eyecarehealth.com.br</p>
                    <p>6.1.1. O Usuário está ciente e concorda que o cancelamento de seu cadastro implica na impossibilidade de acesso à Plataforma e da utilização dos Serviços.</p>
                    <p>6.2. A solicitação de cancelamento feita pelo Usuário é irreversível e importa na imediata exclusão de todas as informações disponibilizadas na conta do Usuário, conforme o caso, incluindo, mas não se limitando, ao histórico de navegação e dados pessoais inseridos na Plataforma Eyecare Financia, com exceção das informações que a Eyecare ou suas parceiras comerciais necessitem manter para cumprimento de obrigações legais e regulatórias ou, ainda, para cumprir seus contratos.</p>
                    <p>6.3. A Eyecare não se responsabiliza por qualquer dano causado ao Usuário oriundo do cancelamento do cadastro solicitado exclusivamente pelo Usuário.</p>
                    <p>6.4. Suspeita de fraude, entre outros comportamentos duvidosos. A Eyecare se reserva o direito de suspender ou cancelar, a qualquer momento, o cadastro do Usuário, bem como seus acessos e usos da Plataforma Eyecare Financia, sem prejuízo do Usuário responder civil e criminalmente por eventuais danos causados, em caso de suspeita de fraude, falsidade no conteúdo das informações, obtenção de benefício ou vantagem de forma ilícita, má utilização ou uso inadequado dos Serviços ou para fins ilícitos, praticados pelos operadores designados pelo Usuário, bem como pelo não cumprimento de quaisquer condições previstas neste TCU ou na legislação aplicável.</p>
                    
                    <h3 className="spc" id="propriedade-intelectual">7. Propriedade intelectual e licença de uso</h3>
                    <p>7.1. Propriedade intelectual. São de titularidade exclusiva da Eyecare todos os direitos de propriedade intelectual relativos às customizações, adaptações, e qualquer conteúdo adicional desenvolvido especificamente pela Eyecare para a Plataforma Eyecare Financia, incluindo, mas não se limitando a, todas as suas funcionalidades, redes, slogans, símbolos, estrutura, conteúdo, textos, imagens, layouts, software, códigos, bases de dados, gráficos, artigos, fotografias, documentação para e a partir da plataforma e todas as informações relativas ao seu uso e funcionamento e demais conteúdos análogos produzidos direta ou indiretamente pela Eyecare ("Propriedade Intelectual da Eyecare").</p>
                    <p>7.1.1. Fica ressalvado que a Eyecare utiliza tecnologia de terceiros no formato white label para a base da Plataforma Eyecare Financia, sendo que todos os direitos de propriedade intelectual relativos à tecnologia base, não desenvolvida pela Eyecare, permanecem de titularidade do respectivo terceiro fornecedor. Esta utilização está sujeita aos termos e condições acordados entre a Eyecare e o terceiro fornecedor.</p>
                    <p>7.1.2. Inclui-se na Propriedade Intelectual da Eyecare, o uso da marca “Eyecare Financia”, nome empresarial ou nome de domínio, além das telas da Plataforma.</p>
                    <p>7.2. Licença de uso. A Eyecare concede ao Usuário autorização para o uso da Plataforma Eyecare Financia durante o prazo de vigência deste TCU, mediante o aceite dos termos e condições aqui estabelecidos.</p>
                    <p>7.2.1. A Propriedade Intelectual da Eyecare é protegida pelas leis de direitos autorais e de propriedade industrial, comprometendo-se o Usuário, por si e pelas pessoas a ele vinculadas que tenham acesso à Plataforma Eyecare Financia, a utilizar a Propriedade Intelectual da Eyecare exclusivamente para os fins previstos neste TCU. Qualquer uso não autorizado da Propriedade Intelectual Eyecare será considerado como violação dos direitos autorais e de propriedade industrial da Eyecare.</p>
                    <p>7.2.2. É proibido ao Usuário e a qualquer pessoa a ele vinculada que tenham acesso à Plataforma Eyecare Financia, copiar, ceder, transferir, explorar, reproduzir, modificar, adaptar, traduzir, publicar, transmitir, distribuir, executar, fazer upload, exibir, licenciar, vender, explorar, remover, ocultar, descompilar e fazer engenharia reversa da Propriedade Intelectual da Eyecare, de qualquer forma, total ou parcialmente, gratuita ou onerosamente, provisória ou permanentemente, sem o consentimento prévio e expresso da Eyecare. É vedado copiar dados extraídos da Plataforma Eyecare Financia, exceto aqueles relativos ao uso da solução pelo próprio Usuário.</p>
                    <p>7.3.  Das melhorias sugeridas. Todos os feedbacks, opiniões, sugestões de melhoria ou outras ideias fornecidas pelo Usuário à Eyecare não conferirão ao Usuário qualquer titularidade sobre os direitos de Propriedade Intelectual da Eyecare, mesmo que elas sejam eventualmente implementadas. Assim, todas as melhorias, opiniões, sugestões, ideias, comentários, feedbacks são desde já cedidos pelo Usuário à Eyecare, de forma gratuita, irrevogável, irretratável, total, perpétua, sem que seja devido ao Usuário qualquer remuneração, reconhecimento, pagamento ou indenização.</p>
                    
                    <h3 className="spc" id="remuneracao-e-tarifas">8. Remuneração e tarifas</h3>
                    <p>8.1. Tarifas. Em contrapartida à utilização da Plataforma Eyecare Financia e dos Serviços, o Usuário poderá pagar à Eyecare as Tarifas especificadas no Termo de Adesão a este TCU, observando os prazos e demais condições nela estabelecidos.</p>
                    <p>8.1.1. Atraso. Em caso de atraso no pagamento, aplicar-se-á multa de 2% (dois por cento) sobre o valor devido, bem como juros mensais de 1% (um por cento) sobre o período em atraso.</p>
                    <p>8.2. Alteração de tarifas. A Eyecare Financia poderá alterar, a qualquer momento, as tarifas aplicáveis à utilização da Plataforma Eyecare e dos Serviços, bem como instituir novas modalidades de remuneração pelo uso da Plataforma Eyecare Financia e de seus Serviços, mediante envio de comunicação com 5 (cinco) dias de antecedência ao Usuário. Caso o Usuário não esteja de acordo com as alterações, poderá rescindir este TCU e deixar de utilizar a Plataforma Eyecare Financia e/ou o Serviço cuja remuneração tenha sido alterada. A continuidade do uso de nossos produtos e serviços será considerada concordância tácita às alterações realizadas.</p>
                    <p>8.3. Nota Fiscal. A Eyecare emitirá e encaminhará ao Usuário as notas fiscais correspondentes aos serviços prestados pela Eyecare e/ou seus Parceiros. </p>
                    
                    <h3 className="spc" id="responsabilidades">9. Responsabilidades</h3>
                    <p>9.1. Responsabilização das partes. Cada parte será responsável pelas perdas e danos diretos porventura causados por si, bem como por seus empregados, contratados, representantes, prepostos ou operadores designados, à outra parte (parte inocente) ou a terceiros.</p>
                    <p>9.2. Indenizações. As Partes se comprometem a indenizar, em até 5 dias úteis, bem como defender e manter a outra Parte, seus sócios, empregados, diretores e colaboradores indenes, de qualquer dano direto efetivamente incorrido decorrente de: (i) descumprimento, erro e/ou falsidade de qualquer informação ou declaração fornecida pela Parte obrigada a indenizar nos termos deste TCU; (ii) descumprimento de obrigação que, nos termos deste Contrato, seja de sua responsabilidade; ou (iii) qualquer mora ou falha da Parte em cumprir suas obrigações, declarações e garantias previstas neste Contrato.</p>
                    <p>9.3. Limitação de Responsabilidade. O Usuário reconhece que a Eyecare não se responsabiliza por problemas, erros, danos ou prejuízos advindos (i) de negligência, imprudência ou imperícia do Usuário e daqueles por ele designados na utilização da Plataforma Eyecare Financia ou na prestação de Serviços a clientes do Usuário; (ii) em virtude do uso indevido ou fora das especificações e instruções fornecidas pela Eyecare; e/ ou (iii) de fatores alheios ao controle da Eyecare, a exemplo de vírus, cavalos-de-troia, códigos maliciosos e assemelhados no ambiente computacional, invasões ou atos de hackers, falhas de energia elétrica, de comunicação ou de infraestrutura, de sistema operacional, de hardware e outros assemelhados. A Eyecare adota todas as medidas ao seu alcance para evitar bugs, falhas, defeitos ou invasões na Plataforma Eyecare Financia e nos servidores em que se encontra hospedada a Plataforma Eyecare Financia, no entanto, o Usuário está ciente da impossibilidade de softwares e/ou sistemas informáticos estarem totalmente livres de erros, bugs, defeitos ou invasões, de maneira que a Eyecare não garante que o uso da Plataforma Eyecare Financia será ininterrupto ou livre de erros, bugs ou invasões. O Usuário exime a Eyecare de qualquer responsabilidade proveniente de tais fatos e/ou atos.</p>
                    <p>9.4. Limites de responsabilidade em relação a serviços de terceiros. O Usuário está ciente que a Eyecare é uma empresa de tecnologia voltada para saúde ocular e que não detém qualquer controle ou ingerência sobre os serviços ofertados por terceiros na Plataforma Eyecare Financia, sendo estes de responsabilidade das Financeiras e Instituições de Pagamentos Parceiras e, ainda, Parceiros Correspondentes Bancários, de modo que não pode ser responsabilizada por eventuais falhas, atrasos, interrupções dos serviços disponíveis na Plataforma Eyecare Financia prestados por parceiros. Para não restar dúvidas, as responsabilidades da Eyecare não poderão em nenhuma hipótese ser confundidas com as responsabilidades dos seus parceiros comerciais, não podendo, portanto, ser responsabilizada pelo Usuário por: (i) congelamento, atrasos ou não execução dos repasses e liquidações dos pagamentos, (ii) falhas ou interrupções na utilização dos serviços de pagamento, entre outros aplicáveis.</p>
                    <p>9.5. Exclusão de polo passivo. Na ocorrência de qualquer demanda proposta contra as Partes deste contrato, a Parte que deu causa ao dano obriga-se a integrar o polo passivo, bem como, quando aplicável, a requerer a exclusão da outra Parte da lide, oferecendo, para tanto, as garantias necessárias e assumindo os custos do processo.</p>
                    
                    <h3 className="spc" id="protecao-de-dados">10. Proteção de dados e confidencialidade</h3>
                    <p>10.1. Confidencialidade de dados pessoais. Ressalvado o quanto disposto nas normas aplicáveis às Partes e às instituições financeiras e de pagamentos parceiras da Eyecare relativamente ao tratamento e utilização de dados pessoais e informações, sem prejuízo das demais disposições deste TCU, cada uma das Partes se obriga a manter em absoluto sigilo e confidencialidade todas as informações, dados ou especificações da outra Parte a que tiver acesso ou que porventura venha a conhecer ou ter ciência relativamente aos clientes do Usuário, utilizando tais informações exclusivamente para os fins estabelecidos neste TCU.</p>
                    <p>10.2. Da coleta de dados. A Eyecare realizará o tratamento de dados do Usuário e de seus clientes com a finalidade de (i) compartilhá-los com as Instituições de Pagamentos Parceiras e as Financeiras Parceiras, para a devida prestação dos Serviços; (ii) consecução das obrigações e regulamentações previstas em lei; e (iii) envios de propaganda, marketing e/ou promoções.</p>
                    <p>10.3. Da proteção dos dados. A Eyecare e o Usuário terão o compromisso com a transparência, a privacidade e a segurança dos dados coletados em razão dos Serviços objeto deste TCU durante sua vigência. Cada uma das Partes declara e garante que adota medidas adequadas para proteção de dados pessoais de terceiros, além de adotar precauções suficientes para a execução de suas atividades de acordo com a legislação vigente e requer que seus parceiros comerciais adotem medidas de proteção semelhantes. O Usuário declara que cumpre e cumprirá, durante toda a vigência deste TCU, as disposições da Lei nº 13.709/2018 (“LGPD”), sendo de sua inteira responsabilidade qualquer ato, fato ou omissão que prejudique o correto tratamento e adequada proteção de tais dados.</p>
                    <p>10.4. Das requisições dos titulares dos dados. As Partes têm o dever de cooperar e colaborar mutuamente para atender em prazo razoável requerimentos de titulares de dados pessoais, quando em relação a dados que tenham sido compartilhados entre as mesmas na execução deste contrato. Sempre que solicitado por uma parte, a outra parte deverá providenciar todas as informações solicitadas em prazo razoável, atendendo os prazos previstos em lei ou regulamentação, quando aplicável, de modo a permitir que a parte demandada pelo titular de dados possa garantir o cumprimento dos seus direitos. Caso algum titular dos dados pessoais tratados no âmbito da execução deste contrato faça alguma requisição a quaisquer das partes no exercício de seus direitos previstos na LGPD ou em qualquer outra legislação ou regulamentação pertinente, como, por exemplo, sem limitação, solicite a alteração, atualização, correção, acesso, ou exclusão de seus dados pessoais, as partes deverão comunicar tal fato imediatamente entre si e proceder ao atendimento da requisição feita pelo titular dos dados pessoais, de forma gratuita. Em conformidade com as melhores práticas de mercado, as partes concordam em cumprir com a legislação e normas infralegais aplicáveis, informando aos titulares dos dados pessoais sobre o procedimento detalhado para o exercício de qualquer dos direitos expostos nesta cláusula.</p>
                    <p>10.5. Exclusão de dados. Os dados pessoais coletados serão utilizados e mantidos durante o período de vigência do TCU, ou em caso de necessidade de cumprimento de obrigação legal ou regulatória, pelos prazos necessários para o exercício de direitos em processos judiciais, administrativos e arbitrais. Na hipótese de rescisão deste TCU e, ausente qualquer base legal para manutenção dos dados pessoais prevista na LGPD, as Partes comprometem-se a eliminar de seus registros e sistemas todos os dados pessoais a que tiverem acesso ou que porventura venham a conhecer ou ter ciência em decorrência dos Serviços deste TCU. </p>
                    
                    <h3 className="spc" id="prazos-e-recisao">11. Prazos e recisão contratual</h3>
                    <p>11.1. A vigência deste TCU em relação ao Usuário se inicia com o seu aceite, podendo a Eyecare e o Usuário, a qualquer momento, pôr fim à relação contratual sem a necessidade de expressar causa alguma, o que implicará no cancelamento do acesso à Plataforma Eyecare Financia com a consequente interrupção da prestação dos Serviços objeto deste TCU. O Usuário poderá solicitar acesso às suas informações através de relatórios pelo e-mail: atendimento@eyecarehealth.com.br.</p>
                    <p>11.2. Verificada a violação a qualquer das disposições deste TCU pelo Usuário, bem como nos seguintes casos, a Eyecare poderá rescindir este TCU e cancelar o acesso do Usuário à Plataforma Eyecare Financia de forma imediata:</p>
                    <div className="row">
                        <div className="col-md-11 offset-md-1">
                            <p>a. requerimento ou decretação de falência, recuperação judicial ou extrajudicial, liquidação ou dissolução de qualquer das partes;</p>
                            <p>b. conhecimento de fatos ou circunstâncias que desabonem a idoneidade comercial do Usuário ou comprometam a sua capacidade econômica, financeira ou técnica;</p>
                            <p>c. na ocorrência de hipótese de caso fortuito ou de força maior que persistir por mais de 30 (trinta) dias, tornando impossível a execução deste TCU pelas Partes.</p>
                        </div>
                    </div>
                    
                    <h3 className="spc" id="prevencao-a-corrupcao">12. Prevenção à corrupção, lavagem de dinheiro e financiamento do terrorismo</h3>
                    <p>12.1. As Partes declaram, de forma irrevogável e irretratável, uma à outra, que seus sócios quotistas, conselheiros, diretores, administradores, empregados, prestadores de serviços, inclusive, seus subcontratados e prepostos, conhecem e cumprem integralmente o disposto nas legislações aplicáveis às suas atividades, que tratam do combate à corrupção e suborno, bem como aos crimes de lavagem de dinheiro, de terrorismo e seu financiamento, entre outros, a ocultação de bens.</p>
                    <p>12.1.1. As Partes garantem, mutuamente, que atuarão de maneira a evitar qualquer conduta indevida, irregular ou ilegal e que adotarão medidas efetivas a fim de impedir qualquer ação, uma em nome da outra e/ou qualquer ato que venha a favorecer, de forma direta ou indireta, uma à outra ou qualquer umas das empresas dos seus respectivos conglomerados econômicos, contrariando as legislações que lhes sejam aplicáveis.</p>
                    <p>12.1.2. As Partes asseguram, uma à outra, que possuem políticas, processos e procedimentos anticorrupção e de prevenção à lavagem de dinheiro e ao financiamento do terrorismo, em conformidade com as legislações que lhes sejam aplicáveis, e garantem mutuamente que empenham esforços no seu cumprimento, por seus sócios quotistas, conselheiros, diretores, administradores, empregados e prestadores de serviços, inclusive, seus subcontratados e prepostos.</p>
                    <p>12.1.3. Caso qualquer uma das Partes venha a ser envolvida em alguma situação ligada à corrupção ou suborno, lavagem de dinheiro, financiamento do terrorismo ou qualquer outro crime ou contravenção em decorrência de ação praticada pela outra Partes ou seus sócios quotistas, conselheiros, diretores, administradores, empregados e prestadores de serviços, inclusive, seus subcontratados e prepostos, a Parte causadora da referida situação se compromete a assumir os ônus decorrentes, inclusive quanto a apresentação de informações e documentos que possam auxiliar a outra Parte em sua defesa.</p>
                    <p>12.1.4. Na execução do presente Contrato é vedado às Partes, bem como, quando agindo em nome e em benefício da Parte em questão, seus respectivos conselheiros, sócios, diretores, colaboradores, agentes, empregados, subcontratados, fornecedores, investidores e terceiros:</p>
                    <div className="row">
                        <div className="col-md-11 offset-md-1">
                            <p>a. Prometer, oferecer ou dar, direta ou indiretamente, vantagem indevida a agente público ou a quem quer que seja, ou a terceira pessoa relacionada a uma das Partes;</p>
                            <p>b. Criar, de modo fraudulento, irregular ou ilegal, pessoa jurídica para celebrar o presente TCU; </p>
                            <p>c. Obter vantagem ou benefício indevido, de modo fraudulento, de modificações ou prorrogações do presente TCU, sem autorização em lei, nos respectivos instrumentos contratuais;</p>
                            <p>d. Manipular ou fraudar o equilíbrio econômico-financeiro do presente TCU; ou</p>
                            <p>e. De qualquer maneira fraudar o presente TCU; assim como incorrer em ações ou omissões que constituam prática de corrupção, lavagem de dinheiro ou financiamento do terrorismo ou demais atos lesivos nos termos da Lei nº 9.613/1998, Lei nº 12.846/2013 e do Decreto nº 8.420/2015, bem como em demais leis ou regulamentos aplicáveis.</p>
                        </div>
                    </div>

                    <h3 className="spc" id="disposicoes-gerais">13. Disposições gerais</h3>
                    <p>13.1. Das Declarações. Cada Parte declara que tem poder e autoridade para cumprir com o presente TCU e que o seu aceite seguido da utilização da Plataforma Eyecare Financia não violarão, conflitarão ou resultarão em violação ou rescisão de quaisquer compromissos firmados no qual possa ser parte ou pelo qual possa estar obrigada.</p>
                    <p>13.2. Não criação de vínculo. Este TCU não cria qualquer outra modalidade de vínculo entre o Usuário e a Eyecare, inclusive, sem limitação, sociedade, mandato, parceria, associação, joint-venture, consórcio, grupo econômico, vínculo empregatício ou similar. A Eyecare permanecerá uma entidade independente e autônoma.</p>
                    <p>13.3. Da cadeia de consumo. O Usuário responsabiliza-se por todos os bens e serviços por ele prestados ou vendidos, pelo resultado e satisfação do cliente em relação a estes e pela sua política comercial, reconhecendo que a Eyecare não é parte da cadeia de fornecimento ou de consumo de produtos ou serviços adquiridos pelos clientes do Usuário, mas tão somente uma plataforma de tecnologia de pagamentos, razão pela qual o Usuário deverá assumir todo e qualquer prejuízo sofrido pela Eyecare, além de assumir o polo passivo de toda e qualquer demanda judicial que recair sobre a Eyecare</p>
                    <p>13.4. Completo acordo. Este Instrumento constitui o acordo integral entre as Partes, com relação à matéria aqui tratada, prevalecendo sobre qualquer outro documento ou acordo verbal anteriormente firmado por estas. Os termos aqui descritos não anulam nem revogam outros termos disponíveis ao Usuário em qualquer aplicação da Eyecare e são complementares às condições específicas de eventual outra contratação entre o Usuário e a Eyecare.</p>
                    <p>13.5. Impostos. Cada uma das Partes arcará com os impostos em que figure como contribuinte e/ou responsável tributário, conforme a legislação aplicável.</p>
                    <p>13.6. Tolerância. Eventual tolerância, atraso ou abstenção das Partes no exercício de qualquer direito previsto neste TCU ou em lei não importará em sua renúncia, novação ou alteração tácita da presente avença.</p>
                    <p>13.7. Código de Ética e Políticas. O Usuário compromete-se a respeitar, cumprir e fazer cumprir, no que couber, o Código de Ética da Eyecare, assim como as demais Políticas estabelecidas pela Eyecare, todos disponíveis no site www.eyecarehealth.com.br.</p>
                    <p>13.8. Cessão. O Usuário não poderá ceder ou transferir os direitos e as obrigações dispostos neste TCU sem a anuência prévia e por escrito da Eyecare. A Eyecare poderá, a qualquer instante, a seu exclusivo critério, ceder ou transferir os direitos e as obrigações deste TCU, além de adicionar ou excluir parceiros comerciais, bem como substituir aqueles com quem guarda relação para a prestação dos Serviços, mediante comunicação prévia de pelo menos 5 (cinco) dias de antecedência.</p>
                    <p>13.9. Disposições Nulas. Se qualquer disposição do presente TCU for considerada nula, ilegal ou inexequível nos termos da legislação pertinente, a disposição em questão será ineficaz tão somente na medida da nulidade, ilegalidade ou inexequibilidade daquela disposição, e não afetara quaisquer outras disposições aqui contidas.</p>
                    <p>13.10. Alterações deste TCU. Este TCU poderá ser alterado pela Eyecare, a qualquer tempo, mediante comunicação ao Usuário com pelo menos 5 (cinco) dias de antecedência.  Caso Usuário não concorde com as alterações, deverá deixar de usar a Plataforma Eyecare Financia e os Serviços, sendo certo que a manutenção do uso da Plataforma Eyecare Financia implicará na concordância tácita com as alterações feitas.</p>
                    <p>13.11. Das disputas. Esse TCU é regido pelas leis da República Federativa do Brasil. Todas as controvérsias deste documento serão solucionadas pelo foro da Comarca de São Paulo, Estado de São Paulo, Brasil, com exclusão de qualquer outro, por mais privilegiado que seja ou venha a ser.</p>
                    <p>13.12. Assinatura Eletrônica. Alternativamente ao aceite e uso da Plataforma Eyecare Financia por parte do Usuário ou à assinatura da Proposta de Adesão, este TCU poderá também ser considerado como aceito e válido entre as Partes quando assinado eletronicamente por meio de plataforma eletrônica. As Partes declaram, de maneira inequívoca, que tal modalidade de assinatura é juridicamente válida, exequível e suficiente para vinculá-las a todos os termos e condições deste documento, desde que firmadas pelos seus respectivos representantes legais nos termos do artigo 10, §2o da Medida Provisória 2.200-2, de 24 de agosto de 2001 e do artigo 6o do Decreto no 10.278/2020. As Partes renunciam ao direito de exigir a entrega das vias originais impressas assinadas deste documento e ao direito de recusar ou contestar a validade das assinaturas eletrônicas. Ainda que alguma das Partes venha a assinar digitalmente este TCU em local diverso, para todos os fins, o local de celebração deste documento é a Cidade de São Paulo, Estado de São Paulo, conforme abaixo indicado. Ademais, será considerada a data de assinatura deste TCU, para todos os fins e efeitos, a data em que a última das assinaturas digitais foi realizada, não obstante a data de assinatura indicada abaixo. Os signatários deste TCU que o assinaram por meio de certificado digital certificado pela ICP-Brasil declaram que estão e sempre estiveram em posse de seu certificado digital e que não o transferiram ou deram acesso ao seu certificado digital a qualquer terceiro, bem como realizaram pessoalmente o procedimento de validação da assinatura digital deste TCU na plataforma selecionada.</p>

                    <h3 className="spc" id="anexo-I">Anexo I - Eyecare FInancia</h3>
                    <p>Este Anexo I é parte integrante e indissociável dos Termos e Condições de Uso da Plataforma Eyecare Financia e define as condições específicas de utilização do Serviço Eyecare Financia.</p>

                    <h3 className="spc" id="anexo-solucao">1. Eyecare Financia - Solução</h3>
                    <p>1.1. Eyecare Financia. A Eyecare Financia disponibiliza ao Usuário, através de Financeiras Parceiras, operações de antecipação, crédito, empréstimo, financiamento e/ou de buy now pay later aos clientes do Usuário para o pagamento de serviços por ele prestados. 
                        A Eyecare atua como intermediária entre Usuário e parceiros correspondentes bancários ou plataforma de tecnologia das seguintes Financeiras Parceiras:</p>
                    <table>
                        <tr>
                            <td>Correspondente Bancário</td>
                            <td>
                                <tr className="linha">BANCO VOTORANTIM S.A., com sede na Avenida das Nações Unidas, nº 14.171, Torre A, 18º andar, Vila Gertrudes, São Paulo/SP, CEP 04794-000, inscrita no CNPJ/ME sob o nº 59.588.111/0001-03 (“BANCO VOTORANTIM-I”)</tr>
                                <tr className="linha">BANCO BV S.A., com sede na Avenida das Nações Unidas, nº 14.171, Torre A, 12º andar, parte, Vila Gertrudes, São Paulo/SP, CEP 04794-000, inscrita no CNPJ/ME sob o nº 01.858.774/0001-10 (“BANCO VOTORANTIM-II”)</tr>
                                <tr className="linha">PARCELEX CORRESPONDENTE BANCÁRIO S.A., sociedade anônima, inscrita no CNPJ/MF sob o no 14.054.623/0001-51, com sede na Av. Ataulfo de Paiva, n° 1.079, salas 707, 708 e 709, Leblon, CEP 22440-034, Cidade do Rio de Janeiro, Estado do Rio de Janeiro (“PARCELEX”)</tr>
                            </td>
                        </tr>
                        <tr>
                            <td>Plataforma de Tecnologia</td>
                            <td>
                                <tr className="linha">OPEN CO TECNOLOGIA S.A., sociedade por ações devidamente constituída e validamente existente segundo as leis do Brasil, com sede na cidade de São Paulo, Estado de São Paulo, Rua Inácio Pereira da Rocha, 514, Pinheiros, CEP 05435-000, inscrita no Cadastro Nacional da Pessoa Jurídica do Ministério da Economia (“CNPJ/ME”) sob o no 20.955.843/0001-59 (“OPEN CO”)</tr>
                                <tr className="linha">PAGALEVE TECNOLOGIA FINANCEIRA LTDA., com sede na Capital do Estado de São Paulo, na Rua Harmonia 590, sala 51, Sumarezinho, CEP 05435-000, inscrita no CNPJ/ME sob o no 42.563.672/0001-55 (“PAGUELEVE”)</tr>
                                <tr className="linha">KOIN ADMINISTRADORA DE CARTÕES E MEIOS DE PAGAMENTO S.A., que tem sede na cidade de São Paulo, Estado de São Paulo, Avenida Paulista, 2421, 13o andar, Bela Vista, inscrita no CNPJ/MF sob o no. 17.991.841/0001-00 (“KOIN”)</tr>
                            </td>
                        </tr>
                    </table>
                    
                    <h3 className="spc" id="anexo-aceitacao">2. Aceitação</h3>
                    <p>2.1. Aceite dos termos e condições. Para utilizar o Serviços Eyecare Financia, o Usuário declara ter lido, concordado e aceitado o TCU e o disposto neste Anexo I.</p>
                    <p>2.2. Aceite dos termos e condições de uso das Financeiras Parceiras. Para a utilização dos serviços das Financeiras Parceiras, o Usuário declara, referente a(o):</p>
                    <div className="row">
                        <div className="col-md-11 offset-md-1">
                            <p>(I) BANCO VOTORANTIM-I: ter lido, concordado e aceitado os termos e condições de uso deste TCU, que regula por completo a utilização desta Financeira Parceira pelo Usuário.</p>
                            <p>(II) BANCO VOTORANTIM-II: ter lido, concordado e aceitado os termos e condições de uso deste TCU, que regula por completo a utilização desta Financeira Parceira pelo Usuário.</p>
                            <p>(III) PARCELEX: ter lido, concordado e aceitado os termos e condições da PARCELEX disponibilizado em www.flipsaude.com.br/tcu-parcelex, que regula por completo a utilização desta Financeira Parceira pelo Usuário.</p>
                            <p>(IV) PAGALEVE: ter lido, concordado e aceitado os termos e condições da PAGALEVE disponibilizado em www.pagaleve.com.br/termos-de-uso/, que regula por completo a utilização desta Financeira Parceira pelo Usuário.</p>
                            <p>(V) KOIN: ter lido, concordado, aceitado e firmado contrato particular com a KOIN, que regula por completo a utilização desta Financeira Parceira pelo Usuário.</p>
                            <p>(VI) OPEN CO: ter lido, concordado, aceitado e firmado contrato particular com a OPEN CO, que regula por completo a utilização desta Financeira Parceira pelo Usuário.</p>
                        </div>
                    </div>

                    <h3 className="spc" id="anexo-operacoes">3. Operações de antecipação, crédito, empréstimo, financiamento e/ou de buy now pay later</h3>
                    <p>3.1. Disponibilização de propostas de operações. Ao inserir os dados do cliente do Usuário na Plataforma Eyecare Financia, as Financeiras Parceiras poderão disponibilizar, separada e individualmente, na Plataforma Eyecare Financia, opções de propostas de operações de antecipação, crédito, empréstimo, financiamento e/ou de buy now pay later para pagamento ao Usuário dos serviços contratados pelo cliente. A disponibilização das propostas está sujeita a análise dos dados e informações fornecidos pelo Usuário sobre seu cliente na Plataforma Eyecare Financia, bem como da política de crédito de cada Financeira Parceira. Caso o perfil de crédito do cliente do Usuário não atenda aos critérios mínimos estipulados pelas Financeiras Parceiras, estas terão a opção de não ofertar qualquer proposta de antecipação, crédito, empréstimo, financiamento e/ou de buy now pay later na plataforma.3.1. Disponibilização de propostas de operações. Ao inserir os dados do cliente do Usuário na Plataforma Eyecare Financia, as Financeiras Parceiras poderão disponibilizar, separada e individualmente, na Plataforma Eyecare Financia, opções de propostas de operações de antecipação, crédito, empréstimo, financiamento e/ou de buy now pay later para pagamento ao Usuário dos serviços contratados pelo cliente. A disponibilização das propostas está sujeita a análise dos dados e informações fornecidos pelo Usuário sobre seu cliente na Plataforma Eyecare Financia, bem como da política de crédito de cada Financeira Parceira. Caso o perfil de crédito do cliente do Usuário não atenda aos critérios mínimos estipulados pelas Financeiras Parceiras, estas terão a opção de não ofertar qualquer proposta de antecipação, crédito, empréstimo, financiamento e/ou de buy now pay later na plataforma.</p>
                    
                    <h3 className="spc" id="anexo-etapas">4. Etapas das operações de antecipação, crédito, empréstimo, financiamento e/ou de buy now pay later</h3>
                    <p>4.1. A disponibilização de propostas de operações de antecipação, crédito, empréstimo, financiamento e/ou de buy now pay later seguirá os seguintes passos:</p>
                    <p>4.1.1. 1ª Etapa – Simulação e Pré-Oferta. O Usuário irá preencher os campos disponíveis na Plataforma Eyecare Financia para a análise do perfil de crédito do seu cliente. Após a inserção dos dados, as Financeiras Parceiras avaliarão a possibilidade de oferta dos seus serviços. Em caso positivo, será disponibilizada na Plataforma Eyecare Financia uma ou mais pré-ofertas de operações de antecipação, crédito, financiamento e/ou de buy now pay later, sujeitas a futura análise e aprovação (“Pré-Oferta”). </p>
                    <p>4.1.2. 2ª Etapa – Contratação. Após a apresentação das Pré-ofertas, o Usuário deverá encaminhá-las aos seus clientes. Os clientes, por sua vez, analisarão as propostas, selecionando a que mais lhe interessa. Após a seleção, receberão a oferta final (“Oferta”) e o passo a passo para finalizar a contratação da operação com a Financeira Parceira. As informações relativas à contratação da Oferta, como, por exemplo, taxa de juros, custo efetivo total, imposto sobre operações financeiras, quantidade de parcelas, prazos de pagamento, taxa de cadastro, entre outras, serão disponibilizadas ao cliente do Usuário no processo de contratação.</p>
                    <p>4.1.2.1. A Financeira Parceria poderá solicitar informações e documentos adicionais ao cliente do Usuário. A critério da Financeira Parceira, após avaliação final de todas as informações e documentos disponibilizados pelo cliente do Usuário, as condições da Oferta poderão sofrer alterações em relação às condições da Pré-Oferta. A Financeira Parceira poderá, ainda, desde que antes da liquidação financeira da operação, negar-se a concretizar a operação, encerrando-se assim a sua contratação.</p>

                    <h3 className="spc" id="anexo-consulta">5. Consulta a beureaus de crédito e ao scr</h3>
                    <p>5.1. Consultas de dados. A Eyecare Financia, seus Parceiros Correspondentes Bancários e as Financeiras Parceiras poderão realizar consultas a bancos de dados a partir dos dados fornecidos pelo cliente do Usuário para verificar a existência de débitos e responsabilidades decorrentes de operações com características de crédito, as informações e os registros de medidas judiciais, inclusive dados que constem ou venham a constar do Sistema de Informações de Crédito (“SCR”) e no Cadastro de Clientes do Sistema Financeiro Nacional (“CCS”), ambos geridos pelo BCB, ou dos sistemas que venham a complementá-los ou a substituí-los, observadas a confidencialidade e proteção de dados previstas no TCU e neste Anexo I. </p>
                    <p>5.2. Obtenção de consentimento. Diante da possibilidade de consulta ao score de crédito e ao SCR para disponibilização de propostas de crédito, é necessário que o Usuário obtenha de seu cliente autorização expressa para a realização das consultas. A autorização obtida do cliente deve ser redigida em linguagem de fácil compreensão e conter, de maneira expressa, (i) a qualificação das Financeiras Parceiras qualificadas na cláusula 1.1 deste Anexo; (ii) a finalidade e o uso das informações do score de crédito e do SCR; (iii) as formas de consulta às informações ao SCR; e (iv) os procedimentos a serem observados para a correção e a exclusão de informações constantes do sistema, o cadastramento de medida judicial, o registro de manifestação de discordância quanto às informações constantes do sistema e esclarecimentos quanto ao funcionamento do SCR. O Usuário responsabiliza-se e exime a Eyecare de todo e qualquer prejuízo causado em caso de inobservância destas condições. </p>
                    <p>5.3. Guarda de documentos. O Usuário se obriga a manter a guarda da autorização para consulta, em meio físico ou eletrônico, para que se permita comprovar a sua autenticidade, pelo prazo de 5 anos, além de disponibilizá-la à Eyecare ou às Financeiras Parceiras sempre que solicitado.</p>

                    <h3 className="spc" id="anexo-cancelamento">6. Cancelamento</h3>
                    <p>6.1. Cancelamento. Após a contratação da Oferta e desde que a correspondente liquidação ao Usuário não tenha ocorrido, o Usuário, e apenas ele, poderá solicitar diretamente à Financeira Parceira, por meio da Plataforma Eyecare Financia, o cancelamento da operação de crédito. Neste caso, a comunicação e o consentimento do cliente com relação ao cancelamento serão de responsabilidade do Usuário, uma vez que este último é o único que guarda relação comercial com o cliente. A política de cancelamento de cada Financeira Parceira é única, podendo assim variar de instituição para instituição. Logo, o Usuário, antes de prosseguir com o cancelamento, deve certificar-se que entendeu e consentiu com as regras de cancelamento. O Usuário é o único responsável pelo cancelamento e deverá formalizar à Eyecare a sua vontade de cancelamento para que seja dada sequência ao processo.</p>

                    <h3 className="spc" id="anexo-liquidacao">7. Liquidação</h3>
                    <p>7.1. Da liquidação. A política de liquidação das operações contratadas pelos clientes do Usuário pode variar conforme cada Financeira Parceira. O prazo para a liquidação ao Usuário será de até 10 (dez) dias úteis, e o valor líquido será sujeito ao desconto das taxas aplicadas pela respectiva Financeira Parceira. Todavia, considerando que todas as operações serão realizadas exclusivamente com o Banco BV, onde o MDR (Merchant Discount Rate) é zero, não haverá desconto de taxas sobre o valor líquido.</p>
                    <p>Caso operações sejam realizadas com outras Financeiras Parceiras além do Banco BV, ou caso o MDR do Banco BV venha a ser alterado, o valor líquido será ajustado para refletir as taxas aplicadas pela respectiva Financeira Parceira. Nesses casos, o presente TCU será alterado, momento no qual o Usuário será devidamente notificado e deverá anuir com os novos termos.</p>
                    <p>. Adicionalmente, cada Financeira Parceira solicitará, dependendo do caso, além das informações relativas à simulação e contratação da operação, informações e documentos comprobatórios da prestação do serviço contratado. As informações e documentos comprobatórios são, mas não se limitam a: contrato de prestação de serviço assinado entre Usuário e cliente, nota fiscal de prestação de serviço, entre outros. Adicionalmente, a Financeira Parceira poderá, dependendo do caso, solicitar informações adicionais, desde que relacionadas ao objeto do contrato, para realizar a liquidação do crédito ao Usuário.</p>

                    <h3 className="spc" id="anexo-limites">8. Limites de Responsabilidade</h3>
                    <p>8.1.  Limites de responsabilidade. A Eyecare, na qualidade de empresa de tecnologia e intermediária entre Usuário e Parceiros Correspondentes Bancários, não poderá ser responsabilizada pela prestação do serviço realizado pelas Financeiras Parceiras e Correspondentes Bancários. As operações de antecipação, crédito, empréstimo, financiamento e/ou de buy now pay later disponibilizadas por meio da Plataforma Eyecare Financia são de exclusiva responsabilidade da Financeira Parceira que a oferece. A Eyecare, portanto, não será, em nenhuma hipótese, responsabilizada pelo: (i) cancelamento de contratos de antecipação, crédito, empréstimo, financiamento e/ou de buy now pay later por qualquer que seja o motivo; (ii) congelamento, atrasos ou não execução dos repasses e liquidações dos contratos de antecipação, crédito, empréstimo, financiamento e/ou de buy now pay later ora contratados; e (iii) falhas ou interrupções na contratação dos financiamentos.</p>
                </div>
            </div>
        </TermsStyled>

    );
}