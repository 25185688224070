import styled from 'styled-components';

export const ProductContainer = styled.div`
    background-color: #fff;  
    .desktop {
        @media (max-width: 999px){
            display: none;
        }
    }

    .mobile {
        @media (min-width: 1000px){
            display: none;
        }
    }

    .container {
        width: 80%;
        margin: 0 auto;
        @media (max-width: 600px){
            width: 95%;
        }

        .eyeHealthInfo {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 5% auto !important;

            @media (max-width: 1000px){
                flex-direction: column;
                align-items: center;
            }

            > div {
                width: 40%;
                margin: 5%;
                @media (max-width: 1000px){
                    width: 100%;
                    text-align: center;
                }
            }

            .didYouKnow {
                h3 {
                    font-size: 30px;
                    font-weight: 800;                              
                }

                ul { 
                    list-style: none;
                    margin: 10% auto;

                    li {
                        margin: 10% auto;
                        span {
                        font-size: 60px;
                        font-weight: 800;
                        color: var(--primary);
                        @media (max-width: 600px){
                            font-size: 50px;
                        }
                        }

                        p {
                            font-size: 20px;
                        }
                    }
                }
            }

            .eyeImg {
                display: flex;
                flex-direction: row;
                justify-content: center;
                img {
                    margin: 0 auto;
                    border-radius: 20px;
                    @media(max-width: 1000px){
                        width: 60%;
                        height: 40%;
                    }
                }
            }
        }


        .buttonContractContainer { 
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            margin-bottom: 20% !important;
            button {
                transition: .3s;
                :hover {
                    background-color: var(--blue);
                    transition: .3s;
                }
            }
        }

        h2 {
            font-size: 40px;
            font-weight: 800;
            text-align: center;
            margin: 10% auto;
            width: 70%;

            @media (min-width: 1440px){
                font-size: 45px !important;
            }

            @media (max-width: 1000px){
                font-size: 30px !important;
                margin-top: 25% !important;
            }

            @media (max-width: 600px){
                font-size: 25px !important;
                width: 90%;
            }

            @media (max-width: 400px){
                font-size: 15px;
                width: 100%;
            }
        }

        .containerTitle {
            font-size: 40px;
            font-weight: 800;
            text-align: center;
            margin: 0 auto;
            margin-top: 10% !important;
            width: 70%;

            @media (max-width: 1000px) {
                font-size: 2px !important;
            }
        }

        .inCompany {
            font-size: 13px;
            color: var(--brand-color-lighten-1);
            
            @media(max-width: 600px){
                margin: 0 auto;
                width: 80%;
            }
        }

        .benefits {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin: 5% auto;
            @media (max-width: 1000px){
                flex-direction: column;
            }
            
        }

        .contract {
            background-color: var(--secondary);
            margin: 15% 0;
            border-radius: 20px;
            height: 379px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @media (min-width: 1440px){
                margin: 5% 0 !important;
            }
            @media (max-width: 1000px){
                margin: 50% 0 !important;
                height: 400px;
            }
            @media (max-width: 330px){
                margin: 75% 0 !important;
            }

            h3 {
                font-size: 35px;
                width: 80%;
                font-weight: 800;
                text-align: center;
                margin-bottom: 5%;
            }

            @media (max-width: 1000px){
                button {
                    width: 50%;
                }
            }

            @media (max-width: 400px){
                h3 {
                    font-size: 20px;
                }
                button {
                    width: 80%;
                }
            }
        }
    }

    .Teleoftal {
        width: 80%;
        margin: 10% auto;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        background-color: var(--secondary);
        border-radius: 20px;
        padding: 5% 5%;

        @media (max-width: 600px){
            width: 90%;
        }

        .TeleoftalContent {
            display: flex;
            flex-direction: row;
            justify-content: space-around;

            @media(max-width: 1000px){
                flex-direction: column;
            }
        }

        .TeleoftalImg {
            display: flex;
            @media (max-width: 1000px) {
                flex-direction: column;
                align-items: center;
                img {
                    width: 100% !important;
                    height: 80% !important;
                }
            }
            @media (max-width: 500px) {
                    margin: 15% auto !important;
            }
            
            .oftalTitle {
                        text-align: center;
                        h3 {
                            font-size: 30px;
                            color: var(--primary);
                            margin: 0 auto;
                            @media (max-width: 500px) {
                                font-size: 25px;    
                            }
                        }

                        p {
                            font-size: 20px;
                            margin: 5% auto;
                            width: 80%;
                            @media (max-width: 500px) {
                                font-size: 15px;
                                margin-bottom: 15%;
                            }
                        }
                    }
        }

        .TeleoftalImg, .TeleoftalText {
                    width: 50%;
                    margin: 2%;
                    @media(max-width: 1000px){
                            margin: 0 auto;
                            width: 100%;
                        }
                }

                .TeleoftalText {
                    h3 {
                        color: var(--primary);
                        font-size: 35px;
                        font-weight: 800;
                        margin-bottom: 10%;
                        @media(max-width: 1000px){
                            font-size: 25px;
                        }
                    }

                    ul {
                        list-style: none;
                        h4 {
                            font-size: 20px;
                            color: var(--primary);
                        }

                        p {
                            font-size: 18px;
                            margin-bottom: 10%;
                        }
                    }
                }

                .oftalBottom {
                    display: flex;
                    width: 100%;
                    
                    padding: 5%;
                    border-radius: 20px;
                    flex-direction: row;
                    justify-content: space-between;
                    background-color: var(--primary);
                    align-items: center;
                    @media(max-width: 1000px){
                        flex-direction: column;
                        justify-content: center;
                    }

                    > div {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        width: 50%;
                        
                        @media(max-width: 1000px){                                     
                            align-items: center !important;
                            width: 100% !important;                      
                        }

                        h3 {
                            font-size: 35px;
                            color: #fff;
                            @media(max-width: 1000px){                                     
                                text-align: center !important;
                                margin: 0 auto;
                            }
                            @media(max-width: 1000px){
                                font-size: 30px;
                                margin-bottom: 5%;
                            }

                            @media(max-width: 600px){
                                font-size: 25px;
                                margin-bottom: 5%;
                            }

                            @media(max-width: 400px){
                                font-size: 20px;
                                margin-bottom: 5%;
                            }
                        }
                    }

                    div:nth-child(2){
                        justify-content: end;
                        height: 100%;
                        @media(max-width: 1000px){                                     
                            justify-content: center;
                        }

                        button {
                            color: var(--primary);
                            font-weight: 700;
                        }
                    }
                }
    }

    .Glauco {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        background-color: var(--brand-color-lighten-3);
        border-radius: 20px;
        padding: 5% 5%;
        width: 80%;
        @media(max-width: 600px){
            width: 90% !important;
        }

            h2 {
                color: var(--brand-color-3);
                margin: 1% auto;
                font-size: 40px;
                text-align: center;
                @media(min-width: 1000px){
                    width: 70%;
                }
                @media(max-width: 1000px){
                    font-size: 25px;
                }
            }

            h3 { color: var(--brand-color-3); }

            .subtitle {
                text-align: center;
                margin-bottom: 10%;
                
                @media(min-width: 1000px){
                    font-size: 20px;
                    padding: 1% 10%;
                }
                @media(max-width: 1000px){
                    font-size: 18px;
                    padding: 2% 0%;
                }
            }

            .GlaucoContent {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;

                @media(max-width: 1000px){
                    flex-direction: column-reverse;
                }

                .GlaucoImg, .GlaucoText {
                    width: 50%;
                    @media(max-width: 1000px){
                            margin: 0 auto;
                            width: 100%;
                    }
                }

                .GlaucoText {
                    h3 {
                        font-size: 35px;
                        font-weight: 800;
                        margin-bottom: 10%;
                        @media(max-width: 1000px){
                            font-size: 25px;
                        }
                    }

                    ul {
                        list-style: none;
                        h4 {
                            font-size: 20px;
                            color: var(--brand-color-3);
                        }

                        p {
                            font-size: 18px;
                            margin-bottom: 10%;
                        }
                    }
                }

                .GlaucoImg {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    
                    @media(min-width: 1200px){
                        .img-left{
                            margin-right: 40%;
                        }

                        .img-right{
                            margin-left: 40%;
                        }

                    }
                    @media (max-width: 1000px) {
                        flex-direction: column !important;
                        align-items: center;

                        img {
                            width: 120% !important;
                        }
                    }

                    @media (max-width: 500px) {
                        margin: 15% auto !important;
                    }

                    .glaucoTitle {
                        text-align: center;
                        h3 {
                            font-size: 30px;
                            color: var(--brand-color-3);
                            margin: 0 auto;
                            @media (max-width: 500px) {
                                font-size: 25px;    
                            }
                        }

                        p {
                            font-size: 20px;
                            margin: 5% auto;
                            width: 80%;
                            @media (max-width: 500px) {
                                font-size: 15px;
                                margin-bottom: 15%;
                            }
                        }
                    }
                        
                    span { 
                            overflow: visible !important;                                  
                            }
                    
                }

        }
        .appContainer {
                display: flex;                    
                justify-content: space-between;
                align-items: center;
                background-color: var(--brand-color-3);
                width: 100%;
                border-radius: 20px;
                
                @media(min-width: 1000px){
                    padding: 5%;
                    flex-direction: row;
                }

                @media(max-width: 1000px){
                    padding: 5% 1%;
                    flex-direction: column;
                }
                
                > h3 {
                    font-size: 30px;
                    color: #fff;
                    
                    @media (min-width: 1440px){
                        font-size: 35px;
                    }

                    @media(min-width: 1000px){
                        width: 50%;
                    }

                    @media(max-width: 1000px){
                        width: 90%;
                        margin-bottom: 5%;
                        text-align: center;
                    }
                }

                .store {
                    display: flex;
                    justify-content: space-evenly;
                    flex-direction: row;
                    width: 50%;
                    @media(max-width: 1000px){
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        margin: 0 auto;
                    }
                    
                }
            
            }
    }

    .plansContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        @media(max-width: 1000px){
            flex-direction: column;
        }
    }
                
    .cardContainer {
        display: flex;
        flex-direction: row;
        width: 85%;
        margin: 0 auto;
        margin-bottom: 1% !important;
        margin-top: -5% !important;
        display: flex;
        justify-content: center;
        @media(max-width: 1000px){
            flex-direction: column;
        }

        & > div {
            & > div {
                height: 100%;
                width: 90%;
                text-align: center;
            }
            h4 { 
                font-size: 25px;
            }

            p {
                font-size: 18px;
            }
        }
    }

    .timeEconomy {
        margin-top: -2% !important;
        margin-bottom: 7% !important;
        margin: 0 auto;
        width: 75%;

        p {
            color: var(--brand-color-lighten-1);
        }
    }
`;

export const Banner = styled.div`
    position: relative;
    width: 100%;
    height: 70vh;
    background-repeat: no-repeat;    
    background-position-x: center;
    background-position-y: center;
    background-attachment: fixed;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 2;
    clip-path: inset(0);

    @media(max-width: 1000px){
        justify-content: center;
    }

    @media(max-width: 700px){
        justify-content: center;
    }

    @media(max-width: 900px){
        justify-content: center;
    }

    @media(max-width: 450px){
        justify-content: center;
    }

    div {
        width: 40%;
        display: flex;
        flex-direction: column;
        margin-left: 15%;
        @media(max-width: 1000px){
            justify-content: center;
            width: 100%;
            text-align: center;
            margin: 0 auto !important;
            padding: 5%;
            align-items: center;
        }

        h1 {
            color: #fff;
            font-weight: 800;
            font-size: 40px;
            margin-bottom: 3%;
            text-shadow: 5px 5px 5px rgba(0, 0, 0, .4);
            @media(max-width: 1200px){
                font-size: 30px;
            }
        }

        p {
            color: #fff;
            font-weight: 700;
            margin-bottom: 7%;
            font-size: 17px;
            text-shadow: 5px 5px 5px rgba(0, 0, 0, .4);
            @media(max-width: 1200px){
                font-size: 14px;
            }
        }
        
        button {
            z-index: 2;
            transition: .3s;
            
            }
        button:hover {
            transition: .3s;
            background-color: var(--blue );
        }

        @media(min-width: 1440px){
            width: 35%;
        }
    }
`

export const ProductGradient = styled.span`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(180deg,rgba(12,29,89,0.62) 12.16%,rgba(12,29,89,0) 44.08%);
`;

export const ProductBannerBackgroundImage = styled.img`
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    object-fit: cover;
    z-index: -1;
`;

export const ResearchContainer = styled.div`
    background-image: url(${ props => props.image });
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: center;
    width: 80%;
    height: 370px;
    margin: 10% auto;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: ${ props => props.justifyContent };
    @media(max-width: 1000px){
        height: 450px;
        display: flex;
        align-items: end;
        padding: 2%;
    }

    div {
        background-color: var(--dark-blue);
        border-radius: 20px;
        width: 47%;
        height: 75%;
        color: #fff;
        margin: 0 2%;
        padding: 3%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media(max-width: 1000px){
            width: 100%;
            height: 50%;
            padding: 8%;
        }

        h5 {
            color: var(--brand-color-lighten-1);
            font-size: 18px;
            @media(max-width: 1000px){
                font-size: 11px;
            }
        }

        p {
            color: #fff;
            height: 60%;
            font-size: 22px;
            font-weight: 800;
            @media(max-width: 1000px){
                font-size: 16px;
            }
        }

        a {
            color: var(--brand-color-lighten-1);
            text-decoration: underline;
        }
    }
`;

export const ImageContainerDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 280px;
    border-radius: 20px;
    background-image: url(${ props => props.image }); 
    background-repeat: no-repeat;   
    background-size: contain;
    background-position-x: center;

    animation: zoom-out .6s ;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    animation-fill-mode: forwards;

    @media (min-width: 1000px) {
        :hover {
        animation: zoom-in .6s ;
        -webkit-animation-fill-mode: forwards;
        -moz-animation-fill-mode: forwards;
        animation-fill-mode: forwards; 
    }
    }

    @media (max-width: 1000px) {
        background-position-y: bottom;
    }
    
    @media only screen and (max-width: 600px) {
        height: 150px;
    }
                
    

    @keyframes zoom-in {
        0% {
            transform: scale(1);
        }

        100% {
            transform: scale(1.2);
        }
    }

    @keyframes zoom-out {
        0% {
            transform: scale(1.2);
        }

        100% {
            transform: scale(1);
        }
    }
`

export const Container = styled.div`
    display: flex;
    flex-direction: ${ props => props.direction };
    width: 80%;
    margin: 8% auto;
    @media (max-width: 1000px){
        flex-direction: column-reverse !important;
        width: 100%;
    }

    div {
        width: 50%;
        margin: 0 auto;

        @media (max-width: 1000px){
            width: 65%;
            &:nth-child(1) {
                width: 90%;
            }
        }

        ul {
            list-style: none;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 5%;
            
            li {
                margin-bottom: 5%;
                display: flex;
                align-items: center;

                .icon {
                    display: flex;
                    flex-direction: row;
                    height: 100%;
                    justify-content: left;
                    align-items: flex-start;
                    margin: 0 !important;
        
                    @media (min-width: 800px) {
                        width: 5% !important;
                    }

                    @media (max-width: 800px) {
                        width: 15% !important;
                    }
                }

                .listContent {
                    display: flex;
                    margin: 0 !important;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: flex-start;
                    text-align: start;
                    width: 100%;
                    span {
                        @media (min-width: 1000px) {
                            margin-left: 3%;
                        }
                    }
                }
            }
        }
    }

    h3 {
        width: 80%;
        font-weight: 800;
        @media (max-width: 1000px){
            margin-top: 5% !important;
            width: 100%;
            font-size: 20px;
            width: 100%;
        }
    }
`;
export const ImageBackground = styled.div`
    background-image: url(${ props => props.image });
    background-repeat: no-repeat;
    border-radius: 20px 20px 0px 0px;
    padding: 100px;
    background-size: 110%;
    transition: .5s;
    background-position-x: center;

    :hover {
        transition: .5s;
        background-size: 130%;
        background-position-x: center;
    }
`;

export const BenefitCardComponent = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    width: 100%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    margin: 1.5%;
    margin-top: -5% !important;
    
    @media(max-width: 1000px){
        margin: 5% auto !important;
    }

    .textContainer {
        padding: 5%;
        h4 {
            font-weight: 800;
            text-align: center;
            padding: 5%;     
            @media(min-width: 1600px){
                font-size: 24px !important;
            }

            @media(min-width: 1440px){
                font-size: 22px !important;
            }

            @media(max-width: 1440px){
                font-size: 17px !important;
            }

            @media(max-width: 1000px){
                font-size: 30px !important;
            }

            @media(max-width: 600px){
                font-size: 20px !important;
            }
        }

        p {
            padding: 5%;
            text-align: center;
            @media(min-width: 1440px){
                font-size: 16px !important;
            }
        }
    }
`;

export const PlansCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 5% 3%;
    border-radius: 20px;
    align-items: center;
    margin: 0% 1%;
    margin-bottom: 4% !important;
    height: 500px;
    transition: .6s;

    @media(min-width: 1600px){
        height: 600px;
    }

    @media(max-width: 1600px){
        height: 520px;
        margin: 5% 1%;
    }

    :hover {
        transition: .6s;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    }

    .quantity {
        color: var(--primary);
        background-color: var(--brand-color-extra-light-2);
        border-radius: 20px;
        padding: 3% 5%;
        margin-bottom: 5%;
    }

    h4 {
        font-size: 40px;
        font-weight: 800;
    }

    p {
        font-size: 18px;
        font-weight: 800;
    }

    ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        margin: 10% auto;
        li {
            display: flex;
            flex-direction: row !important;
            align-items: center;
            margin: 2% 0%;

            > div {
                margin: 0% 1%;
            }
        }
    }

    button {
        transition: .3s;
        :hover {
            transition: .3s;
            background-color: var(--blue);
        }
    }
`;