import styled from "styled-components";

export const TitleFlex = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
    margin-left: 15%;
    flex-direction: row;
    h2 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 800;
        font-size: 45px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: var(--dark);
        @media(max-width: 1000px) {
            font-size: 30px;
        }
    }
`;
