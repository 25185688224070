import { CardContainer } from "./styles"

export default function NewsCard({ text, image, link }) {
    return (
        <CardContainer href={link} target="_blank" rel="noopener noreferrer">
            <p>{text}</p>
            <div className="imgContainer">
                <img
                    src={ image }
                    width="70%"
                    height="85%"
                    alt=""
                ></img>
            </div>
        </CardContainer>

    )
}