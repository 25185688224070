import ProductBanner from "../../components/ProductBanner"
import ResearchCard from "../../components/ResearchCard"
import CarouselAutomatic from "../../components/CarouselAutomatic"
import Card from '../../components/Card'
// import FAQ from "../../components/FAQ"
import Button from "../../components/Button"
import ContentContainer from "../../components/ContentContainer"
import BenefitCard from "../../components/BenefitCard"

import businessBanner from '../../assets/images/Products/business-banner.jpg'
import eyeTiredness from '../../assets/images/Products/eye-tiredness.png';
import rh from '../../assets/images/Products/Resources/rh.png';
import eyecare from '../../assets/images/Products/Resources/eyecare.png';
import work from '../../assets/images/Products/work.png';
import quality from '../../assets/images/Products/quality.png';
import higherProductivity from '../../assets/images/Products/higher-productivity.png';
import productivity from '../../assets/images/Products/productivity.png';




import { ProductContainer } from "./styles"

export default function Companies() {

    return (
        <ProductContainer>
            <ProductBanner
                title='Ofereça o maior benefício de saúde visual do Brasil'
                text='O Benefício Visão é a solução ideal para a sua empresa garantir bem estar visual e alta produtividade dos seus colaboradores'
                image={businessBanner}
                href='/produtos/empresas/quero-contratar?type=empresa'
            />

            <div className="container">
                <h2>Um benefício de saúde ocular feito por especialistas para a sua empresa</h2>
            </div>

            <div className="cardContainer">
                <Card
                    svg={
                        <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.25 25C0.25 11.1929 11.4429 0 25.25 0C39.0571 0 50.25 11.1929 50.25 25C50.25 38.8071 39.0571 50 25.25 50C11.4429 50 0.25 38.8071 0.25 25Z" fill="#FFF9E5" />
                            <path d="M25.25 27C26.3546 27 27.25 26.1046 27.25 25C27.25 23.8954 26.3546 23 25.25 23C24.1454 23 23.25 23.8954 23.25 25C23.25 26.1046 24.1454 27 25.25 27Z" stroke="#FFCC18" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M25.25 32C21.25 32 17.917 29.667 15.25 25C17.917 20.333 21.25 18 25.25 18C29.25 18 32.583 20.333 35.25 25C34.83 25.736 34.392 26.414 33.939 27.033M28.25 32L30.25 34L34.25 30" stroke="#FFCC18" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>}
                    title="Clínica oftalmológica digital"
                    text='Acesso 24/7 a especialistas, garantindo atendimento humanizado e desfechos clínicos favoráveis'
                />

                <Card
                    svg={
                        <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.75 25C0.75 11.1929 11.9429 0 25.75 0C39.5571 0 50.75 11.1929 50.75 25C50.75 38.8071 39.5571 50 25.75 50C11.9429 50 0.75 38.8071 0.75 25Z" fill="#FFF9E5" />
                            <path d="M34.75 30H26.75L23.25 25H16.75M34.75 20H26.75L23.255 25" stroke="#FFCC18" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M31.75 23L34.75 20L31.75 17M31.75 33L34.75 30L31.75 27" stroke="#FFCC18" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    }
                    title="Triagem para todos os colaboradores"
                    text='Uso de ferramenta proprietária para triagem oftalmológica, iniciando o atendimento antes mesmo da consulta'
                />

                <Card
                    svg={
                        <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.25 25C0.25 11.1929 11.4429 0 25.25 0C39.0571 0 50.25 11.1929 50.25 25C50.25 38.8071 39.0571 50 25.25 50C11.4429 50 0.25 38.8071 0.25 25Z" fill="#FFF9E5" />
                            <path d="M19.25 17L16.25 27V29.5M31.25 17L34.25 27V29.5M23.25 29H27.25" stroke="#FFCC18" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M30.75 33C32.683 33 34.25 31.433 34.25 29.5C34.25 27.567 32.683 26 30.75 26C28.817 26 27.25 27.567 27.25 29.5C27.25 31.433 28.817 33 30.75 33Z" stroke="#FFCC18" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M19.75 33C21.683 33 23.25 31.433 23.25 29.5C23.25 27.567 21.683 26 19.75 26C17.817 26 16.25 27.567 16.25 29.5C16.25 31.433 17.817 33 19.75 33Z" stroke="#FFCC18" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    }
                    title="Cuidado contínuo e concierge"
                    text='Acompanhamento contínuo O2O com equipe especializada e benefícios como descontos em óculos e lentes de contato'
                />

                <Card
                    svg={
                        <svg width="51" height="50" viewBox="0 0 51 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.75 25C0.75 11.1929 11.9429 0 25.75 0C39.5571 0 50.75 11.1929 50.75 25C50.75 38.8071 39.5571 50 25.75 50C11.9429 50 0.75 38.8071 0.75 25Z" fill="#FFF9E5" />
                            <path d="M27.75 16V20C27.75 20.2652 27.8554 20.5196 28.0429 20.7071C28.2304 20.8946 28.4848 21 28.75 21H32.75" stroke="#FFCC18" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M28.75 30V27M30.75 34H20.75C20.2196 34 19.7109 33.7893 19.3358 33.4142C18.9607 33.0391 18.75 32.5304 18.75 32V18C18.75 17.4696 18.9607 16.9609 19.3358 16.5858C19.7109 16.2107 20.2196 16 20.75 16H27.75L32.75 21V32C32.75 32.5304 32.5393 33.0391 32.1642 33.4142C31.7891 33.7893 31.2804 34 30.75 34ZM22.75 30V25V30ZM25.75 30V29V30Z" stroke="#FFCC18" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    }
                    title="Relatórios e insights para o RH"
                    text='Relatórios sobre a saúde ocular dos colaboradores atendidos, incluindo insights para suporte à tomada de decisão'
                />
            </div>

            <div className="container">
                <p className="inCompany" >O cliente pode optar pelo benefício in company, através do qual levamos uma equipe especializada e tecnologia de ponta para acompanhar a saúde ocular na sua empresa.</p>
            </div>

            <ResearchCard
                title="Segundo a Sociedade Brasileira de Oftalmologia"
                text="Após a pandemia, 90% dos brasileiros que utilizam computadores por mais de 3 horas por dia sofrem de cansaço visual."
                image={eyeTiredness}
                link="https://www.sboportal.org.br/regulamentos/covid-19-e-uso-excessivo-de-computadores"
                justifyContent='right'
            />

            <div className="container">
                <h2>Conheça os recursos do Benefício Visão</h2>
            </div>

            <ContentContainer
                title="Relatório estruturado com dados e insights para o RH"
                options={["Avaliação das pessoas com problemas visuais ",
                    "Impacto financeiro das ações realizadas",
                    "Avaliação da satisfação e recomendação dos colaboradores",]}
                image={rh}
                svg={<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.5 23.75C18.7132 23.75 23.75 18.7132 23.75 12.5C23.75 6.2868 18.7132 1.25 12.5 1.25C6.2868 1.25 1.25 6.2868 1.25 12.5C1.25 18.7132 6.2868 23.75 12.5 23.75Z" stroke="#FFCC18" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8.75 12.5L11.25 15L16.25 10" stroke="#FFCC18" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>}
                direction="row"
            />

            <ContentContainer
                title="Recursos especializados no universo da saúde ocular"
                options={["Atendimento de saúde ocular O2O e 24/7 para a sua equipe",
                    "Descontos em óticas parceiras",
                    "Acesso a aplicativos de acompanhamento, se necessário"]}
                image={eyecare}
                direction="row-reverse"
                svg={<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.5 23.75C18.7132 23.75 23.75 18.7132 23.75 12.5C23.75 6.2868 18.7132 1.25 12.5 1.25C6.2868 1.25 1.25 6.2868 1.25 12.5C1.25 18.7132 6.2868 23.75 12.5 23.75Z" stroke="#FFCC18" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M8.75 12.5L11.25 15L16.25 10" stroke="#FFCC18" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>}
            />

            <div className="container">
                <div className="buttonContractContainer">
                    <Button
                        text="Quero contratar"
                        theme="primary"
                        width="300px"
                        height="50px"
                        route="/produtos/empresas/quero-contratar?type=empresa"
                    />
                </div>
            </div>

            <div className="container">
                <h2>Por que oferecer o Benefício Visão?</h2>
            </div>

            <div className="container">
                <div className="benefits">
                    <BenefitCard
                        title="Redução do passivo trabalhista"
                        text="Colaboradores foram bastante expostos a telas durante a pandemia, gerando mais queixas oftalmológicas e demandas judiciais"
                        image={work}
                    />

                    <BenefitCard
                        title="Melhoria da qualidade de vida"
                        text="Houve um aumento significativo no número de problemas oftalmológicos não corrigidos, o que afeta diretamente a qualidade de vida dos colaboradores"
                        image={quality}
                    />

                    <BenefitCard
                        title="Aumento da produtividade"
                        text="O Benefício Visão garante o cuidado contínuo da saúde ocular dos seus colaboradores, que terão maior conforto visual e mais produtividade"
                        image={higherProductivity}
                    />
                </div>
            </div>

            <div className="container">
                <CarouselAutomatic />
            </div>

            <ResearchCard
                title="Segundo a Academia Americana de Oftalmologia"
                text="A cada $1 investido em saúde visual, as empresas ganham $12 em produtividade."
                image={productivity}
                link="https://www.aaojournal.org/article/S0161-6420%2815%2900366-8/fulltext"
                justifyContent='left'
            />
            {/* <FAQ /> */}

            <div className="container">
                <div className="contract">
                    <h3>Contrate o maior benefício visual do Brasil e melhore a saúde e a produtividade dos seus colaboradores</h3>
                    <Button
                        text="Quero contratar"
                        theme="primary"
                        width="25%"
                        height="15%"
                        route="/produtos/empresas/quero-contratar?type=empresa"
                    />
                </div>
            </div>
        </ProductContainer>

    )
}