import Button from '../Button'
import { Banner, ProductBannerBackgroundImage, ProductGradient, BannerSpacing }  from "./styles"

export default function ProductBanner( { title, text, image, href } ){

    return(
        <Banner>
            <ProductBannerBackgroundImage src={image} />
            <ProductGradient />
            <div>
                <h1>{ title }</h1>
                <p>{ text }</p>
                <Button
                    id="quero-contratar-pb"
                    text='Quero contratar'
                    theme='primary'
                    width='50%'
                    height='50px'
                    route={href}
                />
            </div>
        </Banner>
    )
}