import styled from "styled-components";

export const TermsContainer = styled.div`
    margin-top: 5%;

    @media(max-width: 1000px) {
        margin-top: 25%;
    }

   .container-text {
       display: flex;
       align-items: center;
       justify-content: center;
       width: 50%;
       margin: 0 auto;
       margin-top: 7%;
       flex-direction: column;
       @media(max-width: 1000px) {
           width: 80%;
       }
       p {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: var(--dark);
       }

       .updated {
           margin-bottom: 5%;
       }

       .subtitle {
            font-style: normal;
            font-weight: 800;
            font-size: 25px;
            line-height: 130%;
            letter-spacing: -0.02em;
            margin-top: 5%;
            margin-bottom: 5%;
       }

       .div-links {
           margin-top: 5%;
           margin-right: auto;
           div {
               margin-bottom: 12%;
           }
           a {
                display: block;
                margin-bottom: 5%;
                text-decoration-line: underline !important;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 130%;
                letter-spacing: -0.02em;
                color: #305BF2;
           }
       }
   }
`;

export const TitleFlex = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
    margin-left: 15%;
    flex-direction: row;
    h2 {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 800;
        font-size: 45px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: var(--dark);
        @media(max-width: 1000px) {
            font-size: 30px;
        }
    }
`;

export const TermsStyled = styled.div`
    table{
        border: 1px solid;
        font-size: 12px;
        td{
            margin: 5px !important;
            border-right: 1px solid;
            border-bottom: 1px solid;
        }
        tr.linha{
            display: block;
            padding: 5px !important;
            text-align: justify;
            :not(:last-of-type){
                border-bottom: 1px solid;
            }
        }
    }
    .container {
        display: flex;
        flex-direction: row;
        align-items: top;
        justify-content: center;
        margin: 0 auto;
        width: 100%;
        margin-top: 5%;
        @media(max-width: 1000px) {
            flex-direction: column;
            margin-top: 15%;
        }

        h1 {
            @media(max-width: 1000px) {
                margin-top: 5%;
            }
            font-weight: 800;
            font-size: 40px;
            line-height: 130%;
            letter-spacing: -0.02em;
            color: var(--black);
        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 130%;
            letter-spacing: -0.02em;
        }

        h3 {
            margin-top: 2%;
            font-style: normal;
            font-weight: 800;
            font-size: 22px;
            line-height: 130%;
            letter-spacing: -0.02em;
        }

        h1, h3, p {
            margin-bottom: 5%;
        }

        .list {
            width: 25%;
            @media(max-width: 1000px) {
                width: 100%;
            }
            margin: 0 auto;

            .menu {
                ::-webkit-scrollbar {
                    width: 3px;
                }

                ::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 0px grey; 
                    border-radius: 10px;
                }
                
                ::-webkit-scrollbar-thumb {
                    background: #305bf28f; 
                    border-radius: 10px;
                    transition: all 0.5s;
                }

                ::-webkit-scrollbar-thumb:hover {
                    background: var(--primary); 
                }
                position: sticky;
                top: 130px;
                overflow-y: auto;
                height: 100vh;
                padding-left: 0 !important;
                list-style: none;
                .second{
                    margin-top: 40px !important;
                    &.title{
                        font-size: 20px
                    }
                }
                a {
                    cursor: pointer;
                    transition: all 0.5s;
                    text-decoration: none;
                        :hover,
                        :active,
                        &.active{
                            strong{
                                color: var(--primary);
                                font-weight: 600;
                                text-decoration:none;
                            }
                        }
                    strong{
                        transition: all 0.5s;
                        font-weight: 600;
                    }
                }
                .list-item {
                    margin-bottom: 12%;

                    li {
                        margin-top: 5%;
                    }
                }
            }

            .color {
                padding: 5%;
                font-weight: 600;
                background-color: #ffebcc;
            }
        }

        .term {
            margin-left: 25px;
            @media(max-width: 1000px) {
                width: 90%;
            }
            width: 75%;

            .link-styled {
                color: var(--brand-color-3);
                font-weight: 600;
            }
        }
    }
`;