import styled from "styled-components";

export const HomeContainer = styled.div`
    .carouselTestimonial {
        width: 80%;
        margin: 0 auto;
    }

    .lgpd {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin: 0 auto;
        width: 80%;
        margin-top: -4%;
        color: var(--brand-color-lighten-1);
        svg {
            margin-right: 7px;
        }

        @media(max-width: 1000px) {
            margin-top: 28%;
        }

        @media(max-width: 650px){
            margin-top: -15% !important;
        }
    }
`;

export const BannerStyle = styled.div`
    width: 82%;
    height: 65%;
    padding-bottom: 100px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;

    @media(max-width: 1000px ){
        flex-direction: column-reverse;
        padding-top: 5%;
    }

    @media(max-width: 650px ){
        flex-direction: column-reverse;
        padding-top: 10% !important;
    }

    @media(max-width: 520px ){
        flex-direction: column-reverse;
        padding-top: 20% !important;
    }

    @media(max-width: 400px ){
        flex-direction: column-reverse;
        padding-top: 28% !important;
    }

    & > div:nth-child(1){
        display: flex;
        flex-direction: column;
        margin-right: 4%;

        .downButton {
            width: 5%;
            margin-top: 20%;
            background-color: unset;
            border: none;

            @media (max-width: 1335px){
                margin-top: 15%;
            }

            @media (max-width: 1290px){
                margin-top: 10%;
            }

            @media(max-width: 1000px ){
                display: flex;
                margin: auto;
                align-items: baseline;
                justify-content: center;
                padding: 5% 0%;
            }

            svg {
                transition: .6s;
                animation: jump 1.2s infinite;
                animation-delay: 1s;
                position: absolute;
                
                path {
                    transition: .6s;
                }
            }

            :hover {
                svg {
                    transition: .6s;
                    path {
                        transition: .6s;
                        fill: #fff;
                    }
                }
            }

            @keyframes jump{
                0% {
                    margin-top: 0px;         
                }
                50% {
                    margin-top: 8px;
                }
                100% {
                    margin-top: 0px;
                }
            }
        }
    }

    div {
        h2 {
            font-size: 60px;
            margin-top: 10%;
            font-weight: 800;
            @media (max-width: 1000px){
                text-align: center;
                font-size: 65px;
            }
            @media (max-width: 680px){
                font-size: 50px;
            }

            @media (max-width: 520px){
                font-size: 40px;
            }

            @media (max-width: 420px){
                font-size: 38px;
            }
        }

        h4 {
            font-weight: 500;
            font-size: 25px;
            margin-top: 5%;
            @media (max-width: 1000px){
                text-align: center;
                font-size: 40px;
            }
            @media (max-width: 850px){
                font-size: 35px;
            }

            @media (max-width: 745px){
                font-size: 30px;
            }

            @media (max-width: 620px){
                font-size: 25px;
            }
        }
        
        button {
            margin-top: 10%;
            transition: .3s;

            @media (min-width: 1440px){
                font-size: 16px;
            }

            &:hover {
                transition: .3s;
                background-color: var(--blue);
            }

            @media(max-width: 1000px ){
                display: none;
            }
         }
    }

    .imageContainer {
        display: grid;
        column-gap: 10px;
        

        & > div:nth-child(1) {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 5;
        }

        & > div:nth-child(2) {
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 2;
            grid-row-end: 6;
        }

        & > div:nth-child(3) {
            grid-column-start: 3;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 5;
        }

        img {
            width: 100% !important;
            height: 90% !important;
            border-radius: 20px !important;
        }
    }

    .subtitle {
        position: absolute;
        margin-top: 40%;
        margin-bottom: 80% !important;
        font-size: 40px;
        max-width: 35%;
        font-weight: 800;

        @media (max-width: 1600px){
            max-width: 40%;
        }

        @media (max-width: 1335px){
            margin-top: 45%;
            font-size: 35px;
        }

        @media (max-width: 1230px){
            margin-top: 44%;
            font-size: 30px;
        }

        @media (max-width: 1190px){
            margin-top: 44%;
            font-size: 30px;
        }

        @media (max-width: 1000px){
            display: none;
        }
    }
`

export const MobileSubtitle = styled.h2`
    text-align: center;
    font-size: 40px;
    margin: 0% 4%;
    padding-bottom: 50px;
    @media (max-width: 520px){
        font-size: 30px;
    }
    
    @media (min-width: 1000px){
        display: none;
    }
`;

export const Divider = styled.div`
    margin: 5% auto;
    width: 80%;
    text-align: center;
    margin-top: 10%;
    margin-bottom: 10%;

    @media (max-width: 1200px){
        .desktopMap { display: none }
        .mobileMap {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            .mapTitle {
                margin: 15% 0%;
            }
        }
    }

    @media (min-width: 1201px){
        .mobileMap { display: none }
    }
`;

export const CardContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 5%;
    @media(max-width: 1000px) {
        flex-direction: column;
    }
`;

export const SliderContainer = styled.div`
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .slider{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin: 5% auto;
        padding-top: 5% !important;
        align-items: center;

        @media (max-width: 1000px) {
            flex-direction: column;
            height: 100%;
           
            
            div {
                margin: 10% auto !important;
            }

            img {
                width: 100% !important;
            }
        }   

        div {
            margin: 0 auto;
            display: flex;
            align-items: center;
        }
    }
`;