import { useState } from "react";
import InputMask from "react-input-mask";

export function PhoneInput({ setProps, value }) {
    const [mask, setMask] = useState("(99) 9999-9999");

    return (
        <InputMask type="text" mask={mask} value={value}
            onKeyPress={e => {
                if (e.target.value.replace(/\D/g, '').length === 10) {
                    setMask("(99) 9999-99999");
                }
                setProps(e.target.value)
            }}
            onChange={e => {
                if (e.target.value.replace("_", "").length === 14) {
                    setMask("(99) 9999-9999");
                }
                setProps(e.target.value)
            }} id="telefone" name="telefone" required />
    );
}