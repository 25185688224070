import styled from "styled-components";

export const NewsLetterContainer = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr;
    /* flex-direction: row; */
    width: 80%;
    margin: 5% auto;
    padding: 6%;
    border-radius: 20px;
    background-color: var(--secondary);

    @media (max-width: 1000px ){
        flex-direction: column;
        padding: 6% 4%;
    }

    .blue {
        margin-top: 5%;
        color: var(--brand-color-lighten-1);
        font-size: 14px;
    }

    .flex-text {
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: left;
        width: 45%;
        @media(max-width: 1000px) {
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            width: 100%;
        }

        h4 {
            font-size: 30px;
            @media (max-width: 1000px ){
                font-size: 25px;
                text-align: center;
                padding-bottom: 5%;
            }

            @media (max-width: 460px ){
                font-size: 20px;
                text-align: center;
            }
        }

        p {
            @media (max-width: 1000px ){
                display: none;
            }
        }
    }

    .form {
        margin-left: 5%;
    }
`;