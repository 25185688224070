import styled from "styled-components";

export const FooterStyle = styled.footer`
    padding: 5% 7% 2%;
    margin-top: 20%;
    background: var(--dark-blue);

    a {
        text-decoration: none !important;
    }

    @media (max-width: 1000px) {
        .grid {
            margin: 0 !important;
            padding-top: 0 !important;
        }

        .main-grid {
            flex-direction: column;
        }

        .grid-icons {
            flex-direction: column;
        }

        .push {
            margin-left: 0 !important;
            margin-top: 0 !important;
            margin-bottom: 20%;
        }

        .svg {
            margin-left: 0 !important;
        }

       .div-contact {
           margin: 0;
           margin-top: 0;
       }

       .div-contact:last-child {
           margin-bottom: 15%;
       }

       .title {
           padding-top: 30px !important;
       }

       .grid-copyright {
           margin: 50px 0;
       }

       .reverse-mobile {
            flex-direction: column-reverse;
       }
    }

    .social-media {
        margin-top: -12px;
        z-index: 1;
    }

    .svg {
        margin-right: 7%;
        margin: 5%;
    }

    .push {
        margin-top: -30px;
        margin-left: auto;
        z-index: 1;
    }

    .main-grid {
        margin-bottom: 4%;
    }

    .decorate {
        text-decoration-line: underline !important;
        font-size: 16px;
        color: var(--white);
    }

    .text-footer {
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: -0.02em;
    }

    .title {
        font-size: 14px;
        color: #8EA5F7;
        padding-top: 50px;
        margin: 24px 0;
    }

    .simple-text-link {
        font-size: 16px;
        color: var(--white);
        margin: 24px 0;
        display: block;
    }

    .div-contact {
        margin-top: 24px;
        .contact-text {
            font-size: 16px;
            color: var(--white);
            display: block;
        }
    }

    .text-copyright {
        font-size: 14px;
        line-height: 130%;
        color: var(--white);
    }

    button:hover {
        transition: .3s;
        background-color: var(--blue);
        color: var(--white);
    }

    button {
        @media(max-width: 1000px) {
            width: 50%;
            margin-bottom: -10% !important;
        }
        @media (min-width: 600px) {
            width: 60%;
        }
        @media(min-width: 900px) {
            width: 110%;
        }
    }
`;

export const DivColorsFooter = styled.div`
    .colors {
        width: 20%;
        height: 10px;
        top: 0px;
    }

    .dark-orange {
        left: 0px;
        background: #FF6B00;
    }

    .light-orange {
        left: 20%;
        background: #FF9900;
    }

    .yellow {
        left: 20%;
        background: #FFCC18;
    }

    .light-blue {
        left: 20%;
        background: #00C2FF;
    }

    .dark-blue {
        left: 20%;
        background: #305BF2;
    }
`;