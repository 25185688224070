import { css } from 'styled-components'


export const  primary = css`
    background-color: var(--primary);
    color: #fff;
`

export const secondary = css`
    background-color: var(--white);
    color: var(--dark-blue);
`

export const light = css`
    background-color: var(--brand-color-extra-light-1);
    color: var(--dark-blue);
`

export const darkBlue = css`
    background-color: var(--dark-blue);
    color: #fff;
`

export const transparent = css`
        background-color: rgba(0, 0, 0, 0);
        color: #fff;
`

export const transparentBlue = css`
        background-color: rgba(0, 0, 0, 0);
        color: var(--primary);
`



