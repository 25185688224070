import { TermsStyled } from "./styles";
import ScrollTo from "../../utils/scrollTo";

export default function UseTerms() {
    return (
        <TermsStyled>
            <div className="container">
                <div className="list" data-wow-delay=".6s">
                    <ul className="menu">
                        <li className="list-item">
                            <a onClick={() => ScrollTo("abrangencia")} title="1. Abrangência" alt="1. Abrangência"><strong>1. Abrangência</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={() => ScrollTo("funcionalidade")} title="2. Funcionalidade" alt="2. Funcionalidade"><strong>2. Funcionalidade</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={() => ScrollTo("acesso-aos-servicos")} title="3. Acesso aos serviços" alt="3. Acesso aos serviços"><strong>3. Acesso aos serviços</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={() => ScrollTo("das-informacoes-dos-usuarios")} title="4. Das informações dos usuários" alt="4. Das informações dos usuários"><strong>4. Das informações dos usuários</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={() => ScrollTo("das-obrigacoes")} title="5. Das obrigações do usuário" alt="5. Das obrigações do usuário"><strong>5. Das obrigações do usuário</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={() => ScrollTo("das-obrigacoes-aos-medicos")} title="6. Das obrigações dos médicos e demais profissionais da área da saúde" alt="6. Das obrigações dos médicos e demais profissionais da área da saúde"><strong>6. Das obrigações dos médicos e demais profissionais da área da saúde</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={() => ScrollTo("propriedade-intelectual")} title="7. Propriedade intelectual" alt="7. Propriedade intelectual"><strong>7. Propriedade intelectual</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={() => ScrollTo("publicidade-divulgacao")} title="8. Publicidade, divulgação e outros websites" alt="8. Publicidade, divulgação e outros websites"><strong>8. Publicidade, divulgação e outros websites</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={() => ScrollTo("suporte")} title="9. Suporte" alt="9. Suporte"><strong>9. Suporte</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={() => ScrollTo("da-responsabilidade-da-eyecare")} title="10. Da responsabilidade da Eyecare" alt="10. Da responsabilidade da Eyecare"><strong>10. Da responsabilidade da Eyecare</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={() => ScrollTo("disposicoes-finais")} title="11. Disposições finais" alt="11. Disposições finais"><strong>11. Disposições finais</strong></a>
                        </li>
                        <li className="list-item">
                            <a onClick={() => ScrollTo("legislacao-aplicavel")} title="12. Legislação aplicável" alt="12. Legislação aplicável"><strong>12. Legislação aplicável</strong></a>
                        </li>
                        <div className="color">
                            <span>Última atualização: 22 de fevereiro de 2021</span>
                        </div>
                    </ul>
                </div>
                <div className="term" data-wow-delay=".7s">
                    <h1>Termos de uso</h1>
                    <p>Prezado usuário,</p>
                    <p>Agradecemos a você pelo acesso às plataformas da Eyecare!</p>
                    <p>Antes de começar a navegar pela nossa plataforma e pelos nossos aplicativos, você deve ler e compreender nossos termos de uso e a nossa política de privacidade, que está disponível em <a href="https://www.eyecarehealth.com.br" title="https://www.eyecarehealth.com.br" alt="https://www.eyecarehealth.com.br">www.eyecarehealth.com.br</a>, prosseguindo somente se estiver de acordo com estes documentos.</p>
                    <h3 id="abrangencia">1. Abrangência</h3>
                    <p>1.1.	Este Termos de Uso se aplica ao website, aos aplicativos (Glauco, Eyecare B.I. e qualquer outro que seja desenvolvido futuramente), e demais plataformas (doravante denominadas indiscriminadamente de “Plataforma” ou “Plataformas”) vinculados à Eyecare DN Soluções Digitais Ltda., sociedade empresária de responsabilidade limitada inscrita no CNPJ/ME sob o nº 35.800.459/0001-54, com sede na Alameda Ministro Rocha Azevedo, 859, apto. 31, sala 01, Cerqueira César, São Paulo, SP, CEP 01410-003 (doravante denominada “Eyecare”), e suas controladoras, controladas, filiais, subsidiárias, ou empresas do mesmo grupo econômico.</p>
                    <p>1.2.	Ao usar as Plataformas, você concorda com os Termos de Uso. Em caso de acesso, você estará vinculado aos Termos de Uso da Plataforma como eles existiam quando utilizou a Plataforma pela última vez.</p>
                    <p>1.3.	A Eyecare reserva o direito de fazer alterações a estes Termos de Uso a qualquer momento. O usuário deverá verificar periodicamente a existência de atualizações ou alterações nos Termos de Uso.</p>
                    <p>1.4.	O usuário não deverá utilizar as Plataformas caso não concorde com os Termos de Uso.</p>
                    <h3 className="spc" id="funcionalidade">2. Funcionalidade</h3>
                    <p>2.1.	A Eyecare possui diversas Plataformas com funcionalidades online ligadas à Oftalmologia, que foram programadas para atender a médicos, pacientes, empresas do ramo de saúde, farmácias e operadoras de saúde.</p>
                    <p>2.2.	As Plataformas deverão ser acessadas através de dispositivos móveis ou por computadores, e cuja disponibilização poderá se dar através de aplicativos em lojas virtuais. As Plataformas têm como objetivo permitir a integração entre paciente, médicos e outros interessados nas atividades e funcionalidades das Plataformas.</p>
                    <div className="row">
                        <div className="col-md-11 offset-md-1">
                            <p>2.2.1.	O aplicativo Glauco é voltado a pacientes com glaucoma, permitindo maior controle tanto do paciente como dos cuidadores sobre o tratamento dispendido.</p>
                            <p>2.2.2.	O aplicativo Eyecare B.I. é voltado ao profissional médico, permitindo maior controle, e, consequentemente, otimização da gestão da clínica, oferecendo alternativas para melhorar o rendimento.</p>
                        </div>
                    </div>
                    <p>2.3.	Todas as orientações, tratamentos e condutas médicas realizadas através das Plataformas são de responsabilidade pessoal do médico que a realizou, nos termos da Resolução CFM 2.217/2018 (Código de Ética Médica), não sendo a Eyecare responsável pelas orientações transmitidas.</p>
                    <h3 className="spc" id="acesso-aos-servicos">3. Acesso aos serviços</h3>
                    <p>3.1.	A fim de acessar e usar os sistemas e serviços da Eyecare, o usuário deverá manter e operar o software e hardware necessários e atualizados em seus dispositivos. O usuário é responsável por adquirir, instalar e manter todo o software e hardware necessários para acessar aplicativo e usar os serviços, sem imputar qualquer dificuldade técnica que venha a ser enfrentada decorrente de tal uso à Eyecare.</p>
                    <p>3.2.	O uso dos sistemas da Eyecare exige o prévio cadastramento do usuário, a ser realizado através do preenchimento de formulário eletrônico na própria Plataforma.</p>
                    <p>3.3.	O usuário deverá efetuar um cadastro para utilização da Plataforma, com o fornecimento de informações pessoais, que poderão incluir nome completo, CPF, RG, e-mail, telefone, dentre outras informações. Isto feito, será gerado um login e senha de acesso à Plataforma da Eyecare. O usuário será responsável por qualquer atividade que ocorra através de sua conta e concorda em não ceder a terceiros, a qualquer título, seu nome de usuário e senha ou qualquer direito sobre a conta. Cabe ao usuário a responsabilidade por manter sua senha em sigilo e protegida.</p>
                    <p>3.4.	Ao iniciar o uso da Plataforma da Eyecare, o usuário autoriza a ativação do sistema de geolocalização de seu aparelho, permitindo o acesso ao local em que se encontra. O sistema de geolocalização poderá ser desativado pelo usuário a qualquer momento, o que implicará automaticamente na restrição de acesso a algumas ferramentas da Plataforma. </p>
                    <p>3.5.	A Eyecare não se responsabiliza por qualquer falha no recebimento de alertas e mensagens decorrentes de sua impossibilidade de acesso à internet, por qualquer motivo, como esgotamento do pacote de dados, área sem sinal de recepção de dados, ou qualquer outro que decorra da falta de meios de acesso do usuário.</p>
                    <p>3.6.	Ao aceitar os Termos de Uso, o usuário autoriza o recebimento de mensagens publicitárias e quaisquer outros alertas que possam ser enviados diretamente pelo aplicativo, e-mail ou outro sistema.</p>
                    <p>3.7.	A Eyecare poderá cobrar pelo uso das Plataformas ou de determinadas funcionalidades dentro das Plataformas.</p>
                    <p>3.8.	Caso o usuário deseje cancelar seu cadastro na Eyecare, basta encaminhar um e-mail para <a href="mailto:sistemas@eyecarehealth.com.br" title="sistemas@eyecarehealth.com.br" alt="sistemas@eyecarehealth.com.br" rel="noreferrer" target="_blank" className="link-styled">sistemas@eyecarehealth.com.br.</a></p>
                    <h3 className="spc" id="das-informacoes-dos-usuarios">4. Das informações dos usuários</h3>
                    <p>4.1.	Além das informações prestadas pelos usuários, as Plataformas coletam informações a respeito da navegação do usuário pelas páginas com o intuito de realizar uma análise comportamental, tanto da parte de saúde como de interesses globais, registram o seu interesse pelos serviços utilidades disponíveis e utilizam serviços para enviar aos usuários mensagens publicitárias sobre os produtos e funcionalidades disponíveis.</p>
                    <div className="row">
                        <div className="col-md-11 offset-md-1">
                            <p>4.1.1.	O usuário concorda com a coleta de informações estabelecidas acima, e com a sua utilização para que a Eyecare aprimore seus serviços, e ofereça produtos ou serviços que sejam de maior interesse aos usuários.</p>
                            <p>4.1.2.	Além disso, a Eyecare poderá compartilhar as informações coletadas com seus parceiros, de acordo com seus interesses comerciais. </p>
                        </div>
                    </div>
                    <p>4.2.	São informações coletadas, dentre outras possíveis: endereço IP do usuário, cookies, atributos de software e hardware, informações de geolocalização, além de análise dos interesses dos usuários de acordo com o padrão de navegação.</p>
                    <p>4.3.	As informações de navegação também podem servir para identificar o retorno do usuário à Plataforma. É possível ao usuário, a seu critério, excluir os cookies diretamente do seu navegador.</p>
                    <p>4.4.	Cookies podem ser eliminados, recusados ou bloqueados pelo usuário a qualquer momento no seu navegador. O usuário também poderá utilizar navegadores anônimos, contudo, alguns serviços e atividades da Eyecare ser prejudicados se utilizados desta forma.</p>
                    <h3 className="spc" id="das-obrigacoes">5. Das obrigações do usuário</h3>
                    <p>5.1.	O usuário concorda em:</p>
                    <p>(a)	Não utilizar a Plataforma ou qualquer conteúdo dele, bem como os serviços disponibilizados pela Eyecare, para qualquer fim ilícito;<br />
                        (b)	Não tornar disponíveis ou fazer o upload de arquivos, dados ou quaisquer outros materiais não pertencentes ou licenciados ao usuário;<br />
                        (c)	Não disponibilizar através da Plataforma ou fazer upload de arquivos ou outros dados ou materiais que saiba conter vírus, bug, dados corrompidos ou outros itens prejudiciais à Plataforma ou demais usuários;<br />
                        (d)	Não interferir com o funcionamento normal da Plataforma;<br />
                        (e)	Não interferir no uso e acesso de outros usuários da Plataforma; e<br />
                        (f)	Não publicar, postar, distribuir ou disseminar materiais ou informações difamatórias, transgressoras, obscenas, indecentes ou ilegais através da Plataforma ou dos demais canais da Eyecare.</p>
                    <p>5.2.	Ao utilizar a Plataforma para qualquer de suas funcionalidades, o usuário declara que todas as informações prestadas no cadastro e uso da ferramenta são totalmente verídicas, responsabilizando-se integralmente pelas consequências decorrentes de qualquer informação que não condiga com a realidade dos fatos.</p>
                    <p>5.3.	A Eyecare reserva-se o direito de suspender ou bloquear imediatamente o acesso à Plataforma e remover quaisquer informações ou dados que considere uma violação de qualquer uma destas condições gerais de uso, sem aviso prévio. Reserva-se o direito, ainda, de disponibilizar tais informação aos órgãos da Administração Pública quando solicitado, judicial ou extrajudicialmente.</p>
                    <p>5.4.	O usuário é o único responsável por reparar danos que venha a gerar à Eyecare, aos demais usuários ou ainda a terceiro mediante a utilização do Sistema, devendo arcar com todo e qualquer prejuízo, de qualquer natureza que venha a ser causado.</p>
                    <h3 className="spc" id="das-obrigacoes-aos-medicos">6. Das obrigações dos médicos e demais profissionais da área da saúde</h3>
                    <p>6.1.	Além das obrigações de usuários, os médicos e demais profissionais da área da saúde, como técnicos de enfermagem, tecnólogos em oftalmologia, dentre outros (doravante denominados “Profissionais da Saúde”), que utilizem a Plataforma para desenvolver suas atividades profissionais devem realizar um cadastro específico na Plataforma.</p>
                    <p>6.2.	Os Profissionais da Saúde devem ter capacidade civil, habilitação técnica no respectivo conselho de classe e não estarem impedidos de praticar a Medicina ou os atos a ela relacionados, bem como possuir smartphone, tablet ou computador compatível com a Plataforma.</p>
                    <p>6.3.	Os Profissionais da Saúde deverão cumprir todos os requisitos legais correspondentes ao local de sua atuação para exercício da Medicina ou de sua atividade. </p>
                    <p>6.4.	Caso não seja possível confirmar as informações prestadas pelo médico, poderá a Eyecare bloquear o acesso do Profissional da Saúde, a seu exclusivo critério.</p>
                    <div className="row">
                        <div className="col-md-11 offset-md-1">
                            <p>6.4.1.	Após receber a documentação solicitada, a Eyecare efetuará análise das informações prestadas e documentos encaminhados, podendo aceitar ou recusar a solicitação de cadastro dos Profissionais da Saúde. A Eyecare também poderá realizar a checagem a respeito de eventual especialização informada, antecedentes criminais e quaisquer outras verificações que considerar oportunas ou que sejam exigidas pela legislação.</p>
                        </div>
                    </div>
                    <p>6.5.	O perfil dos Profissionais da Saúde é exclusivo e intransferível. Os Profissionais da Saúde comprometem-se, mediante aceitação dos Termos de Uso, a não compartilhar sua conta com terceiros, sendo vedada a transferência a qualquer pessoa, sob pena de cancelamento imediato, além de encaminhamento do caso às autoridades públicas para análise de penalidades criminais e civis aplicáveis.</p>
                    <div className="row">
                        <div className="col-md-11 offset-md-1">
                            <p>6.5.1.	A Eyecare reserva o direito de solicitar documentos adicionais para confirmação de cadastros, bem como outros métodos de identificação e autenticação dos Profissionais da Saúde como, por exemplo, reconhecimento facial, observados os direitos relativos à privacidade de dados pessoais. Mesmo após a confirmação do cadastro, é possível o cancelamento da conta caso sejam verificadas incongruências no processo de verificação. </p>
                        </div>
                    </div>
                    <p>6.6.	Não se estabelece entre os Profissionais da Saúde e a Eyecare qualquer vínculo de natureza societária, empregatícia e/ou econômica, sendo certo que os Profissionais da Saúde são livres para cessar a sua utilização da Plataforma a qualquer momento, ao seu livre e exclusive critério. </p>
                    <p>6.7.	Os Profissionais da Saúde devem respeitar as regras e condições deste Termo e da legislação aplicável, inclusive a Resolução CFM 2.217/2018 (Código de Ética Médica) no caso de médicos, a Resolução 564/2017 do COFEN (Código de Ética dos Profissionais de Enfermagem) no caso dos profissionais de enfermagem, ou as demais legislações éticas, e suas alterações, pertinentes aos profissionais da saúde, comprometendo-se a:</p>
                    <p>(a)	Agir perante a Eyecare e aos demais usuários com boa-fé, diligência, profissionalismo e respeito;<br />
                        (b)	Obedecer a todas as exigências legais e regulatórias relacionadas às atividades desenvolvidas;<br />
                        (c)	Responsabilizar-se integralmente pela prestação do serviço de atendimento à saúde de seus pacientes; e<br />
                        (d)	Não divulgar login e senha, impedindo o uso de tais informações por terceiros e responsabilizando-se pelas consequências pelo acesso indevido.</p>
                    <div className="row">
                        <div className="col-md-11 offset-md-1">
                            <p>a. É de exclusiva responsabilidade dos Profissionais da Saúde a utilização da Plataforma por terceiros a eles vinculados, como no caso de acesso por secretárias, assessores ou outros colaboradores. </p>
                        </div>
                    </div>
                    <h3 className="spc" id="propriedade-intelectual">7. Propriedade intelectual</h3>
                    <p>7.1.	A Plataforma, que inclui o Aplicativo, sua estrutura e todas as funcionalidades nele contidas, bem como toda informação, dados, textos, imagens e gráficos e todos os componentes empregados no Eyecare são protegidos por direitos autorais e outros direitos de propriedade intelectual. O usuário reconhece que a Eyecare detém todos os direitos, título e interesses relativos ao conteúdo e que não vai, através do uso das Plataformas, adquirir quaisquer direitos próprios nele.</p>
                    <p>7.2.	Nenhum direito, título ou interesse de qualquer conteúdo é transferido ao usuário, seja como resultado de acessar tal conteúdo ou de outra forma. O conteúdo das Plataformas não pode ser copiado, reproduzido, modificado, publicado, carregado, transmitido, executado ou distribuído de forma alguma, e o usuário concorda em não modificar, alugar, arrendar, emprestar, vender, distribuir, transmitir, difundir ou criar obras derivadas com base no conteúdo das Plataformas, total ou em parte, por qualquer meio.</p>
                    <p>7.3.	A licença para o uso da Eyecare é concedida de forma não exclusiva, não transferível, não sublicenciável, podendo ser revogável a qualquer momento em relação a todo e qualquer conteúdo fornecido ao usuário para o uso exclusivo no processamento de sistemas internos, sem prévio aviso. A Eyecare reserva todo e qualquer direito sobre suas marcas, registradas ou não, direitos autorais, e outros direitos de propriedade intelectual, ficando vedada sua utilização sem a sua expressa autorização, sob pena de configuração de ilícitos civis e criminais.</p>
                    <h3 className="spc" id="publicidade-divulgacao">8. Publicidade, divulgação e outros websites</h3>
                    <p>8.1.	Ao utilizar a Plataforma o usuário concorda em receber mensagens da Eyecare com veiculação de publicidade própria ou de produtos e serviços de terceiros.</p>
                    <p>8.2.	É de total responsabilidade do usuário o acesso ao conteúdo de quaisquer websites fora da Plataforma, mesmo que decorrentes de links ou propagandas que surjam dentro da Plataforma. A inclusão destes links dentro da Plataforma não implica no endosso pela Eyecare de quaisquer produtos, serviços, conteúdo, informação ou materiais oferecidos ou acessados pelo usuário nos websites de terceiros. </p>
                    <div className="row">
                        <div className="col-md-11 offset-md-1">
                            <p>8.2.1.	Quaisquer opiniões ou recomendações expressas nos referidos outros websites de terceiros são unicamente aquelas dos provedores independentes e não são as opiniões ou recomendações da Eyecare.</p>
                        </div>
                    </div>
                    <p>8.3.	Todos os direitos autorais e marcas comerciais acessíveis através dos links da Eyecare são de propriedade dos respectivos donos dos websites ou dos seus licenciadores.</p>
                    <h3 className="spc" id="suporte">9. Suporte</h3>
                    <p>9.1.	Em caso de problemas técnicos, o usuário deverá contatar a Eyecare através do e-mail <a href="mailto:sistemas@eyecarehealth.com.br" title="sistemas@eyecarehealth.com.br" alt="sistemas@eyecarehealth.com.br" rel="noreferrer" target="_blank" className="link-styled">sistemas@eyecarehealth.com.br.</a></p>
                    <p>9.2.	A Eyecare não se responsabiliza por qualquer falha no envio de alertas e mensagens ao usuário, sendo seu dever dispor de modo alternativo de controle de suas rotinas médicas e farmacológicas.</p>
                    <h3 className="spc" id="da-responsabilidade-da-eyecare">10. Da responsabilidade da Eyecare</h3>
                    <p>10.1. A Eyecare não será responsável por qualquer dano decorrente do uso, mau uso ou da impossibilidade de uso das Plataformas.</p>
                    <p>10.2. A Eyecare e seus prestadores de serviços, licenciadores e fornecedores se isentam de todas as garantias e condições relacionadas ao conteúdo das Plataformas. Nenhum conselho ou informação, oral ou escrita, obtidos da Eyecare deve, de maneira alguma, criar qualquer garantia não declarada expressa e explicitamente neste acordo.</p>
                    <p>10.3. Até ao limite máximo permitido por lei, em hipótese alguma a Eyecare e seus provedores de serviços, licenciados ou fornecedores serão responsabilizados por quaisquer danos diretos, indiretos, punitivos, incidentais, especiais, consequentes, exemplares ou outros tipos de danos, incluindo, entre outros, danos por cobertura ou perda de uso, receita ou lucros, decorrentes de ou de alguma forma relacionados diretamente ao uso ou desempenho das Plataformas, ou por qualquer conteúdo, seja com base em contrato, negligência, responsabilidade objetiva, falta de qualquer recurso para atingir sua finalidade essencial, mesmo que a Eyecare ou qualquer fornecedor da Eyecare tenha sido avisado da possibilidade de danos.</p>
                    <h3 className="spc" id="disposicoes-finais">11. Disposições finais</h3>
                    <p>11.1.	A Eyecare tem o direito de alterar estas condições e seu acesso à Plataforma ou a quaisquer serviços a qualquer momento, por qualquer razão, sem aviso prévio.</p>
                    <p>11.2.	A Eyecare pode mudar, suspender, terminar ou descontinuar qualquer aspecto da Plataforma a qualquer tempo, incluindo disponibilidade de qualquer serviço, informações, características ou funcionalidades acessíveis por meio do aplicativo. A Eyecare pode também impor limitações a certas características, funcionalidades ou serviços ou restringir o acesso do usuário a partes ou a todo o aplicativo sem prévia notificação e sem que isso implique em alguma responsabilidade da Eyecare por qualquer prejuízo que venha a sofrer.</p>
                    <p>11.3.	Quaisquer melhorias ou adições à Plataforma estarão sujeitas a estes Termos de Uso, ou à versão que venha a substituir estes Termos de Uso. </p>
                    <p>11.4.	Cabe à Eyecare controlar, alterar a aparência, desenvolvimento e operações da Plataforma, bem como estabelecer e mudar os procedimentos para o contato do usuário, sem prévia notificação.</p>
                    <h3 className="spc" id="legislacao-aplicavel">12. Legislação aplicável</h3>
                    <p>12.1. O presente Termo de Uso e todas as relações dele decorrentes são submetidas às leis da República Federativa do Brasil, sendo eleito o foro da Comarca da Capital do estado de São Paulo para dirimir qualquer controvérsia decorrente deste instrumento.</p>
                </div>
            </div>
        </TermsStyled>

    );
}