import styled from "styled-components";

export const TermsStyled = styled.div`
    .container {
        display: flex;
        flex-direction: row;
        align-items: top;
        justify-content: center;
        margin: 0 auto;
        width: 80%;
        margin-top: 5%;
        @media(max-width: 1000px) {
            flex-direction: column;
            margin-top: 15%;
        }

        h1 {
            @media(max-width: 1000px) {
                margin-top: 5%;
            }
            font-weight: 800;
            font-size: 45px;
            line-height: 130%;
            letter-spacing: -0.02em;
            color: var(--black);
        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 130%;
            letter-spacing: -0.02em;
        }

        h3 {
            margin-top: 2%;
            font-style: normal;
            font-weight: 800;
            font-size: 22px;
            line-height: 130%;
            letter-spacing: -0.02em;
        }

        h1, h3, p {
            margin-bottom: 5%;
        }

        .list {
            width: 25%;
            @media(max-width: 1000px) {
                width: 80%;
            }
            margin: 0 auto;

            .menu {
                list-style: none;
                a {
                    cursor: pointer;
                }
                .list-item {
                    margin-bottom: 12%;

                    li {
                        margin-top: 5%;
                    }
                }
            }

            .color {
                padding: 5%;
                font-weight: 600;
                background-color: #ffebcc;
            }
        }

        .term {
            margin-left: 12%;
            @media(max-width: 1000px) {
                width: 90%;
            }
            width: 75%;

            .link-styled {
                color: var(--brand-color-3);
                font-weight: 600;
            }
        }

        table {
            width: 100%;
            border-collapse: collapse;
            border: 1px solid;
            margin-top: 5%;
            margin-bottom: 5%;
        }

        tr, td {
            border: 1px solid black;
            padding: 0.75rem;
            font-size: 0.8rem;
        }

        .td-title {
            border: 1px solid black;
            background-color: #4372C4;
            text-align: center;
            color: white;
            font-weight: 400;
        }

        .tr-head {
            background-color: #B4C6E7;
        }
    }
`;