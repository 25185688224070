import styled from 'styled-components';

export const ProductContainer = styled.div`
    background-color: #fff;  
    .desktop {
        @media (max-width: 999px){
            display: none;
        }
    }

    .mobile {
        @media (min-width: 1000px){
            display: none;
        }
    }

    .container {
        width: 80%;
        margin: 0 auto;
        @media (max-width: 600px){
            width: 95%;
        }

        .eyeHealthInfo {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 5% auto !important;

            @media (max-width: 1000px){
                flex-direction: column;
                align-items: center;
            }

            > div {
                width: 40%;
                margin: 5%;
                @media (max-width: 1000px){
                    width: 100%;
                    text-align: center;
                }
            }

            .didYouKnow {
                h3 {
                    font-size: 30px;
                    font-weight: 800;                              
                }

                ul { 
                    list-style: none;
                    margin: 10% auto;

                    li {
                        margin: 10% auto;
                        span {
                        font-size: 60px;
                        font-weight: 800;
                        color: var(--primary);
                        @media (max-width: 600px){
                            font-size: 50px;
                        }
                        }

                        p {
                            font-size: 20px;
                        }
                    }
                }
            }

            .eyeImg {
                display: flex;
                flex-direction: row;
                justify-content: center;
                img {
                    margin: 0 auto;
                    border-radius: 20px;
                    @media(max-width: 1000px){
                        width: 60%;
                        height: 40%;
                    }
                }
            }
        }


        .buttonContractContainer { 
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            margin-bottom: 20% !important;
            button {
                transition: .3s;
                :hover {
                    background-color: var(--blue);
                    transition: .3s;
                }
            }
        }

        h2 {
            font-size: 40px;
            font-weight: 800;
            text-align: center;
            margin: 10% auto;
            width: 70%;

            @media (min-width: 1440px){
                font-size: 45px !important;
            }

            @media (max-width: 1000px){
                font-size: 30px !important;
                margin-top: 25% !important;
            }

            @media (max-width: 600px){
                font-size: 25px !important;
                width: 90%;
            }

            @media (max-width: 400px){
                font-size: 15px;
                width: 100%;
            }
        }

        .containerTitle {
            font-size: 40px;
            font-weight: 800;
            text-align: center;
            margin: 0 auto;
            margin-top: 10% !important;
            width: 70%;

            @media (max-width: 1000px) {
                font-size: 2px !important;
            }
        }

        .inCompany {
            font-size: 13px;
            color: var(--brand-color-lighten-1);
            
            @media(max-width: 600px){
                margin: 0 auto;
                width: 80%;
            }
        }

        .benefits {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin: 5% auto;
            @media (max-width: 1000px){
                flex-direction: column;
            }
            
        }

        .contract {
            background-color: var(--secondary);
            margin: 15% 0;
            border-radius: 20px;
            height: 379px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @media (min-width: 1440px){
                margin: 5% 0 !important;
            }
            @media (max-width: 1000px){
                margin: 50% 0 !important;
                height: 400px;
            }
            @media (max-width: 330px){
                margin: 75% 0 !important;
            }

            h3 {
                font-size: 35px;
                width: 80%;
                font-weight: 800;
                text-align: center;
                margin-bottom: 5%;
            }

            @media (max-width: 1000px){
                button {
                    width: 50%;
                }
            }

            @media (max-width: 400px){
                h3 {
                    font-size: 20px;
                }
                button {
                    width: 80%;
                }
            }
        }
    }

    .Teleoftal {
        width: 80%;
        margin: 10% auto;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        background-color: var(--secondary);
        border-radius: 20px;
        padding: 5% 5%;

        @media (max-width: 600px){
            width: 90%;
        }

        .TeleoftalContent {
            display: flex;
            flex-direction: row;
            justify-content: space-around;

            @media(max-width: 1000px){
                flex-direction: column;
            }
        }

        .TeleoftalImg {
            display: flex;
            @media (max-width: 1000px) {
                flex-direction: column;
                align-items: center;
                img {
                    width: 100% !important;
                    height: 80% !important;
                }
            }
            @media (max-width: 500px) {
                    margin: 15% auto !important;
            }
            
            .oftalTitle {
                        text-align: center;
                        h3 {
                            font-size: 30px;
                            color: var(--primary);
                            margin: 0 auto;
                            @media (max-width: 500px) {
                                font-size: 25px;    
                            }
                        }

                        p {
                            font-size: 20px;
                            margin: 5% auto;
                            width: 80%;
                            @media (max-width: 500px) {
                                font-size: 15px;
                                margin-bottom: 15%;
                            }
                        }
                    }
        }

        .TeleoftalImg, .TeleoftalText {
                    width: 50%;
                    margin: 2%;
                    @media(max-width: 1000px){
                            margin: 0 auto;
                            width: 100%;
                        }
                }

                .TeleoftalText {
                    h3 {
                        color: var(--primary);
                        font-size: 35px;
                        font-weight: 800;
                        margin-bottom: 10%;
                        @media(max-width: 1000px){
                            font-size: 25px;
                        }
                    }

                    ul {
                        list-style: none;
                        h4 {
                            font-size: 20px;
                            color: var(--primary);
                        }

                        p {
                            font-size: 18px;
                            margin-bottom: 10%;
                        }
                    }
                }

                .oftalBottom {
                    display: flex;
                    width: 100%;
                    
                    padding: 5%;
                    border-radius: 20px;
                    flex-direction: row;
                    justify-content: space-between;
                    background-color: var(--primary);
                    align-items: center;
                    @media(max-width: 1000px){
                        flex-direction: column;
                        justify-content: center;
                    }

                    > div {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        width: 50%;
                        
                        @media(max-width: 1000px){                                     
                            align-items: center !important;
                            width: 100% !important;                      
                        }

                        h3 {
                            font-size: 35px;
                            color: #fff;
                            @media(max-width: 1000px){                                     
                                text-align: center !important;
                                margin: 0 auto;
                            }
                            @media(max-width: 1000px){
                                font-size: 30px;
                                margin-bottom: 5%;
                            }

                            @media(max-width: 600px){
                                font-size: 25px;
                                margin-bottom: 5%;
                            }

                            @media(max-width: 400px){
                                font-size: 20px;
                                margin-bottom: 5%;
                            }
                        }
                    }

                    div:nth-child(2){
                        justify-content: end;
                        height: 100%;
                        @media(max-width: 1000px){                                     
                            justify-content: center;
                        }

                        button {
                            color: var(--primary);
                            font-weight: 700;
                        }
                    }
                }
    }

    .Glauco {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        background-color: var(--brand-color-lighten-3);
        border-radius: 20px;
        padding: 5% 5%;
        width: 80%;
        @media(max-width: 600px){
            width: 90% !important;
        }

            h2 {
                color: var(--brand-color-3);
                margin: 1% auto;
                font-size: 40px;
                text-align: center;
                @media(min-width: 1000px){
                    width: 70%;
                }
                @media(max-width: 1000px){
                    font-size: 25px;
                }
            }

            h3 { color: var(--brand-color-3); }

            .subtitle {
                text-align: center;
                margin-bottom: 10%;
                
                @media(min-width: 1000px){
                    font-size: 20px;
                    padding: 1% 10%;
                }
                @media(max-width: 1000px){
                    font-size: 18px;
                    padding: 2% 0%;
                }
            }

            .GlaucoContent {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;

                @media(max-width: 1000px){
                    flex-direction: column-reverse;
                }

                .GlaucoImg, .GlaucoText {
                    width: 50%;
                    @media(max-width: 1000px){
                            margin: 0 auto;
                            width: 100%;
                    }
                }

                .GlaucoText {
                    h3 {
                        font-size: 35px;
                        font-weight: 800;
                        margin-bottom: 10%;
                        @media(max-width: 1000px){
                            font-size: 25px;
                        }
                    }

                    ul {
                        list-style: none;
                        h4 {
                            font-size: 20px;
                            color: var(--brand-color-3);
                        }

                        p {
                            font-size: 18px;
                            margin-bottom: 10%;
                        }
                    }
                }

                .GlaucoImg {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    
                    @media(min-width: 1200px){
                        .img-left{
                            margin-right: 40%;
                        }

                        .img-right{
                            margin-left: 40%;
                        }

                    }
                    @media (max-width: 1000px) {
                        flex-direction: column !important;
                        align-items: center;

                        img {
                            width: 120% !important;
                        }
                    }

                    @media (max-width: 500px) {
                        margin: 15% auto !important;
                    }

                    .glaucoTitle {
                        text-align: center;
                        h3 {
                            font-size: 30px;
                            color: var(--brand-color-3);
                            margin: 0 auto;
                            @media (max-width: 500px) {
                                font-size: 25px;    
                            }
                        }

                        p {
                            font-size: 20px;
                            margin: 5% auto;
                            width: 80%;
                            @media (max-width: 500px) {
                                font-size: 15px;
                                margin-bottom: 15%;
                            }
                        }
                    }
                        
                    span { 
                            overflow: visible !important;                                  
                            }
                    
                }

        }
        .appContainer {
                display: flex;                    
                justify-content: space-between;
                align-items: center;
                background-color: var(--brand-color-3);
                width: 100%;
                border-radius: 20px;
                
                @media(min-width: 1000px){
                    padding: 5%;
                    flex-direction: row;
                }

                @media(max-width: 1000px){
                    padding: 5% 1%;
                    flex-direction: column;
                }
                
                > h3 {
                    font-size: 30px;
                    color: #fff;
                    
                    @media (min-width: 1440px){
                        font-size: 35px;
                    }

                    @media(min-width: 1000px){
                        width: 50%;
                    }

                    @media(max-width: 1000px){
                        width: 90%;
                        margin-bottom: 5%;
                        text-align: center;
                    }
                }

                .store {
                    display: flex;
                    justify-content: space-evenly;
                    flex-direction: row;
                    width: 50%;
                    @media(max-width: 1000px){
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        margin: 0 auto;
                    }
                    
                }
            
            }
    }

    .plansContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        @media(max-width: 1000px){
            flex-direction: column;
        }
    }
                
    .cardContainer {
        display: flex;
        flex-direction: row;
        width: 85%;
        margin: 0 auto;
        margin-bottom: 1% !important;
        margin-top: -5% !important;
        display: flex;
        justify-content: center;
        @media(max-width: 1000px){
            flex-direction: column;
        }

        & > div {
            & > div {
                height: 100%;
                width: 90%;
                text-align: center;
            }
            h4 { 
                font-size: 25px;
            }

            p {
                font-size: 18px;
            }
        }
    }

    .timeEconomy {
        margin-top: -2% !important;
        margin-bottom: 7% !important;
        margin: 0 auto;
        width: 75%;

        p {
            color: var(--brand-color-lighten-1);
        }
    }
`;