import React from "react";
import { Helmet } from "react-helmet";
import { Grid } from '@mui/material'
import Button from '../../components/Button';
import Card from '../../components/Card';
import Map from '../../components/Map';
import NewsLetter from '../../components/NewsLetter';
import ProductsContainer from '../../components/ProductsContainer';
import CarouselAutomatic from '../../components/CarouselAutomatic';
import CardDynamicMessage from '../../components/CardDynamicMessage';

import banner01 from '../../assets/images/Home/Banner-01.png';
import banner02 from '../../assets/images/Home/Banner-02.png';
import banner03 from '../../assets/images/Home/Banner-03.png';
import modal from '../../assets/images/Home/modal.png';
import cascavel from '../../assets/images/Home/instituto-cascavel.png';
import zeizz from '../../assets/images/Home/zeizz.png';

import { 
    HomeContainer, 
    BannerStyle, 
    MobileSubtitle, 
    Divider, 
    CardContainer, 
    SliderContainer 
} from './styles';

export default function Home() {
    return (
        <HomeContainer>
            <main>
                <BannerStyle>
                    <div>
                        <h2>De olho no futuro</h2>
                        <h4>Investimos no futuro da oftalmologia para que todos possam ver melhor</h4>

                        <Button
                            text="Quero contratar"
                            theme="primary"
                            width="35%"
                            height="10%"
                            route="/produtos/empresas/quero-contratar?type=hospital"
                        />

                        <button className='downButton' onClick={() => document.getElementById("products").scrollIntoView({ block: 'end', behavior: 'smooth' })}>
                        <svg width="60" height="60" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 35C0 15.67 15.67 0 35 0C54.33 0 70 15.67 70 35C70 54.33 54.33 70 35 70C15.67 70 0 54.33 0 35Z" fill="#E7ECFD" />
                            <path d="M25 36.6666L35 46.6666M35 23.3333V46.6666V23.3333ZM45 36.6666L35 46.6666L45 36.6666Z" stroke="#305BF2" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                        </button>
                    </div>

                    <div className='imageContainer' >
                        <div>
                        <img
                            src={banner01}
                            alt="Eyecarehealth 1"
                            width="200px"
                            height="500px"
                        ></img>
                        </div>
                        <div>
                        <img
                            src={banner02}
                            alt="Eyecarehealth 2"
                            width="200px"
                            height="500px"
                        ></img>
                        </div>
                        <div>
                        <img
                            src={banner03}
                            alt="Eyecarehealth 3"
                            width="200px"
                            height="500px"
                        ></img>
                        </div>
                    </div>
                    <h2 className='subtitle'>Soluções em oftalmologia para todo o ecossistema</h2>
                </BannerStyle>

                <MobileSubtitle>
                Soluções em oftalmologia para todo o ecossistema
                </MobileSubtitle>


                <CardContainer id="products">
                <Card svg={<svg width="71" height="70" viewBox="0 0 71 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.333252 35C0.333252 15.67 16.0033 0 35.3333 0C54.6632 0 70.3333 15.67 70.3333 35C70.3333 54.33 54.6632 70 35.3333 70C16.0033 70 0.333252 54.33 0.333252 35Z" fill="#E7ECFD" />
                    <path d="M46.9999 26.6667H23.6666C21.8256 26.6667 20.3333 28.1591 20.3333 30V45C20.3333 46.841 21.8256 48.3334 23.6666 48.3334H46.9999C48.8409 48.3334 50.3333 46.841 50.3333 45V30C50.3333 28.1591 48.8409 26.6667 46.9999 26.6667Z" stroke="#305BF2" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M20.3333 36.6667C24.9859 39.0111 30.1233 40.2324 35.3333 40.2324C40.5432 40.2324 45.6806 39.0111 50.3333 36.6667M28.6666 26.6667V23.3333C28.6666 22.4493 29.0178 21.6014 29.6429 20.9763C30.268 20.3512 31.1159 20 31.9999 20H38.6666C39.5506 20 40.3985 20.3512 41.0236 20.9763C41.6487 21.6014 41.9999 22.4493 41.9999 23.3333V26.6667H28.6666ZM35.3333 35V35.0167V35Z" stroke="#305BF2" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                }
                    title="Para empresas" text="Ofereça acesso 24/7 a clínica oftalmológica digital da Eyecare Health para os seus colaboradores e alavanque o bem estar e a produtividade" button={[{ text: "Veja mais", theme: "primary", width: "161px", height: "53px", route: 'https://conteudo.eyecarehealth.com.br/beneficio.visao' }]} />
                <Card svg={<svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 35C0 15.67 15.67 0 35 0C54.33 0 70 15.67 70 35C70 54.33 54.33 70 35 70C15.67 70 0 54.33 0 35Z" fill="#E7ECFD" />
                    <path d="M23.3333 50V23.3333C23.3333 22.4493 23.6845 21.6014 24.3096 20.9763C24.9348 20.3512 25.7826 20 26.6667 20H43.3333C44.2174 20 45.0652 20.3512 45.6904 20.9763C46.3155 21.6014 46.6667 22.4493 46.6667 23.3333V50M20 50H50H20Z" stroke="#305BF2" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M35 26.6667V33.3334M30 50V43.3334C30 42.4493 30.3512 41.6015 30.9763 40.9763C31.6014 40.3512 32.4493 40 33.3333 40H36.6667C37.5507 40 38.3986 40.3512 39.0237 40.9763C39.6488 41.6015 40 42.4493 40 43.3334V50H30ZM31.6667 30H38.3333H31.6667Z" stroke="#305BF2" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    } title="Para clínicas e hospitais" text="Economize 25% do tempo através de uma plataforma exclusiva para oftalmologia, com estoque, financeiro, agenda e segurança de dados" button={[{ text: "Veja mais", theme: "primary", width: "161px", height: "53px", route: '/produtos/empresas/quero-contratar?type=hospital' }]}/>
                    <Card svg={<svg width="71" height="70" viewBox="0 0 71 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.666748 35C0.666748 15.67 16.3368 0 35.6667 0C54.9967 0 70.6667 15.67 70.6667 35C70.6667 54.33 54.9967 70 35.6667 70C16.3368 70 0.666748 54.33 0.666748 35Z" fill="#E7ECFD" />
                    <path d="M35.6666 38.3333C37.5075 38.3333 38.9999 36.8409 38.9999 35C38.9999 33.159 37.5075 31.6666 35.6666 31.6666C33.8256 31.6666 32.3333 33.159 32.3333 35C32.3333 36.8409 33.8256 38.3333 35.6666 38.3333Z" stroke="#305BF2" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M52.3333 35C47.8883 42.7783 42.3333 46.6666 35.6667 46.6666C29 46.6666 23.445 42.7783 19 35C23.445 27.2216 29 23.3333 35.6667 23.3333C42.3333 23.3333 47.8883 27.2216 52.3333 35Z" stroke="#305BF2" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    } title="Para o meu cuidado ocular" text="Seja protagonista da sua saúde visual, com a garantia  de suporte contínuo e especializado em toda a jornada de tratamento" button={[{ text: "Veja mais", theme: "primary", width: "161px", height: "53px", route: '/produtos/meu-cuidado-ocular/quero-contratar' }]}></Card>
            </CardContainer>
                
            <SliderContainer>
                <div className="slider">
                <div>
                    <img src={modal} alt="Modal" width="230%" height="100%" ></img>
                </div>

                <div>
                    <img src={cascavel} alt="Instituto cascavel" width="100%" height="100%" ></img>
                </div>

                <div>
                    <img src={zeizz} alt="zeizz" width="100%" height="100%" ></img>
                </div>
                </div>
                </SliderContainer>

                <ProductsContainer />
                <p className='lgpd'> 
                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.25 13L11.75 15.5L16.75 10.5" stroke="#8EA5F7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M13 1.75C15.9198 4.33322 19.7306 5.67821 23.625 5.5C24.192 7.42877 24.3655 9.45183 24.1352 11.449C23.9049 13.4461 23.2754 15.3766 22.2843 17.1257C21.2931 18.8747 19.9605 20.4067 18.3656 21.6306C16.7707 22.8545 14.946 23.7453 13 24.25C11.0541 23.7453 9.22939 22.8545 7.63447 21.6306C6.03955 20.4067 4.70692 18.8747 3.71578 17.1257C2.72464 15.3766 2.0952 13.4461 1.86488 11.449C1.63456 9.45183 1.80805 7.42877 2.37504 5.5C6.26945 5.67821 10.0802 4.33322 13 1.75" stroke="#8EA5F7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                Todos os produtos da Eyecare Health estão em conformidade com a LGPD.
                </p>

                <Map />
                <div className='carouselTestimonial'>
                <CarouselAutomatic />
                </div>


                {/* <FAQ /> */}

                <Grid container>
                    <CardDynamicMessage />
                </Grid>

                <NewsLetter />
            </main>

            </HomeContainer>
    )
}