import { useEffect } from 'react'
import { NewsLetterContainer } from './styles'

export default function NewsLetter() {
    // useEffect(() => {
    //     if (window.RDStationForms) {
    //         new window.RDStationForms('cadastro-newsletter-704ce9963e67ac681e0e', 'UA-174858441-1').createForm();
    //     }
    // }, []);

    useEffect(() => {
        window.hbspt && window.hbspt.forms.create({
            region: "na1",
            portalId: "39956071",
            formId: "477d6d1d-6fff-42f1-8f35-f144e875b3aa",
            target: '#newsletter-form'
        })
        
    }, [window.hbspt])

    return (
        <NewsLetterContainer>
            <div className='flex-text'>
                <h4>Inscreva-se em nossa Newsletter</h4>
                <p className='blue'>
                    Ao clicar em &quot;Enviar&quot;, você permite que a Eyecare Health armazene e processe
                    as informações submetidas para envio do conteúdo solicitado. Você pode se descadastrar
                    a qualquer momento.
                </p>
            </div>

            {/* <div className='form'>
                <div role="main" id="cadastro-newsletter-704ce9963e67ac681e0e" />
            </div> */}
            <div id='newsletter-form'></div>
        </NewsLetterContainer>
    );
}