import Select from "react-select";

export default function Select2({ options, setProps, value, id }) {
    return (
        <Select className="select2"
            instanceId={id}
            id={id}
            isClearable={true}
            isSearchable={true}
            noOptionsMessage={() => 'Não encontrado'}
            options={options}
            onChange={e => setProps(e)}
            value={value}
            placeholder="Selecione uma opção..."
        />
    );
}