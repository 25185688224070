import { TestimonialStyled, NameStyled, StyledTitle } from "./styles";

export default function Testimonial({ svg, text, photo, name, title }) {
    return (
        <TestimonialStyled>
            <div>
                <div className="container-svg">
                    <div className="div-svg">
                        {svg}
                    </div>
                </div>
                <div className="container">
                    <div className="div-text">
                        <h3>
                            {text}
                        </h3>
                    </div>
                </div>
                <div className="photo">
                    {photo}
                </div>
                <div className="flex-text">
                    <div>
                        <NameStyled>
                            {name}
                        </NameStyled>
                    </div>
                    <div>
                        <StyledTitle>
                            {title}
                        </StyledTitle>
                    </div>
                </div>
            </div>
        </TestimonialStyled >
    );
}