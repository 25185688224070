import { BenefitCardComponent, ImageBackground } from "./styles";

export default function BenefitCard( { image, title, text }){

    return(
        <BenefitCardComponent>
            <ImageBackground image={ image } />

            <div className="textContainer">
                <h4>{ title }</h4>
                <p>{ text }</p>
            </div>
        </BenefitCardComponent>
    )
}