import { useState } from 'react'
import styled from 'styled-components'
import { ProductContainerStyle, ProductsImageContainer } from "./styles"
import Button from '../Button'
import Slider from 'react-touch-drag-slider'

import products from '../../utils/json/products'

const ProductsDiv = styled.div`${ProductContainerStyle}`

export default function ProductsContainer() {

    const [activeProduct, setActiveProduct] = useState(products[0])
    const [actualIndex, setActualIndex] = useState(0)

    function changeProduct(position) {
        if (position === 'next') {
            if (actualIndex < products.length - 1) {
                setActiveProduct(products[1 + actualIndex])
                setActualIndex(1 + actualIndex)
            } else {
                setActiveProduct(products[0])
                setActualIndex(0)
            }
        } else if (position === 'previous') {
            if (actualIndex !== 0) {
                setActiveProduct(products[actualIndex - 1])
                setActualIndex(actualIndex - 1)
            } else {
                setActiveProduct(products[products.length - 1])
                setActualIndex(products.length - 1)
            }
        }
    }

    return (
        <ProductsDiv>
            <div className='buttonContainer'>
                {products.map((product, index) => {
                    return (
                        <div onClick={() => {
                            setActiveProduct(product)
                            setActualIndex(index)
                        }
                        } key={index} >
                            <Button
                                text={product.name}
                                theme={activeProduct.name == product.name ? 'light' : 'transparent'}
                                width="100%"
                                height="100%"
                            />
                        </div>
                    )
                })}
            </div>

            <div className='content'>
                <span className='changeButton' onClick={() => changeProduct('previous')}>
                    <svg width="34" height="34" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.5 22C5.5 31.1127 12.8873 38.5 22 38.5C31.1127 38.5 38.5 31.1127 38.5 22C38.5 12.8873 31.1127 5.5 22 5.5C12.8873 5.5 5.5 12.8873 5.5 22Z" stroke="white" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M23 17L18 22" stroke="white" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M23 27L18 22" stroke="white" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </span>
                <div>

                    <ul>
                        <li className='customer'>{activeProduct.name} • {activeProduct.customer}</li>
                        <li><h3>{activeProduct.title}</h3></li>
                        {activeProduct.features.map((feature, index) => {
                            return (
                                <li key={index}>
                                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13 24.25C19.2132 24.25 24.25 19.2132 24.25 13C24.25 6.7868 19.2132 1.75 13 1.75C6.7868 1.75 1.75 6.7868 1.75 13C1.75 19.2132 6.7868 24.25 13 24.25Z" stroke="#305BF2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M9.25 13L11.75 15.5L16.75 10.5" stroke="#305BF2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                    <span>{feature}</span>
                                </li>)
                        })}
                    </ul>
                </div>

                <ProductsImageContainer image={activeProduct.image}>
                </ProductsImageContainer>

                <span className='changeButton' onClick={() => changeProduct('next')}>
                    <svg width="34" height="34" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M38.5 22C38.5 31.1127 31.1127 38.5 22 38.5C12.8873 38.5 5.5 31.1127 5.5 22C5.5 12.8873 12.8873 5.5 22 5.5C31.1127 5.5 38.5 12.8873 38.5 22Z" stroke="white" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M21 17L26 22" stroke="white" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M21 27L26 22" stroke="white" strokeWidth="2.75" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </span>

            </div>

            <div className='bottomContainer'>
                <Button
                    text='Saiba mais'
                    theme='transparentBlue'
                    width="20%"
                    height="100%"
                    route={activeProduct.page}
                />

                <Button
                    id="quero-contratar-pc1"
                    text='Quero contratar'
                    theme='primary'
                    width="20%"
                    height="100%"
                    route={activeProduct.contract}
                />
            </div>

            <div className='mobile'>
                <Slider
                    activeIndex={0}
                    threshHold={100}
                    transition={0.5}
                    scaleOnDrag={true}
                >
                    {products.map((product, index) => {
                        return (
                            <ul className='card' key={`product-${index}`}>
                                <li className='customer'>{product.customer}</li>
                                <li><h3>{product.name}</h3></li>
                                <li className='imageMobileContainer'>
                                    <img className='imageProducts' src={product.image} alt={product.name} ></img>
                                </li>
                                <li><h4>{product.title}</h4></li>

                                {product.features.map((feature, index) => {
                                    return (
                                        <li key={index}>
                                            <svg width="20" height="20" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M13 24.25C19.2132 24.25 24.25 19.2132 24.25 13C24.25 6.7868 19.2132 1.75 13 1.75C6.7868 1.75 1.75 6.7868 1.75 13C1.75 19.2132 6.7868 24.25 13 24.25Z" stroke="#305BF2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                <path d="M9.25 13L11.75 15.5L16.75 10.5" stroke="#305BF2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>

                                            <span>{feature}</span>
                                        </li>)
                                })}

                                <li className='buttonMobile'>
                                    <Button
                                        id="quero-contratar-pc2"
                                        text='Quero contratar'
                                        theme='primary'
                                        width="100%"
                                        height="100%"
                                        route={product.page}
                                    />
                                    <Button
                                        text='Saiba mais'
                                        theme='transparentBlue'
                                        width="100%"
                                        height="100%"
                                        route={product.contract}
                                    />
                                </li>
                            </ul>
                        )
                    })}
                </Slider>
            </div>
        </ProductsDiv>

    )
}