import { ResearchContainer } from "./styles"

export default function ResearchCard( { title, text, image, justifyContent, link } ){

    return (
        <ResearchContainer image={ image } justifyContent={ justifyContent }>
            <div>
                <h5> { title } </h5>
                <p> { text } </p>
                <a href={ link } target="_blank" rel="noreferrer">Acesse o artigo</a>
            </div>
        </ResearchContainer>
    )
}