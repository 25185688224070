import Button from "../Button";

import { StyledCard, Title, Paragraph } from "./styles"

export default function Card({ svg, title, text, button }) {
    return (
        <StyledCard>
            <div>
                {svg}
            </div>
            <div className="div-text">
                <Title>
                    {title}
                </Title>
            </div>
            <div className="div-text">
                <Paragraph>
                    {text}
                </Paragraph>
            </div>
            {button &&
                <div>
                    <Button text={button[0].text} theme={button[0].theme} width={button[0].width} height={button[0].height} route={button[0].route}></Button>
                </div>
            }
        </StyledCard>
    );
}