import { ContainerStyle } from "./styles"
import FormB2B from "../FormB2B";
import FormB2C from "../FormB2C";

export default function FormContainer({ image, title, text, type, href, textLink }) {
    return (
        <ContainerStyle image={image}>
            <div className="grid-item">
                <h1>{title}</h1>
                <p>{text}</p>
                <div className="link">
                    <a href={href}>{textLink}</a>
                </div>
            </div>

            <div className="grid-form">
                {type == 'b2b' ? (
                    <FormB2B />
                ) : (
                    <FormB2C />
                )}
            </div>
        </ContainerStyle>
    );
}