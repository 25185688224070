import styled from "styled-components"

export const StyledCard = styled.div`
    display: flex;
    flex-direction: column;
    /* justify-content: space-around; */
    align-items: center;
    width: 25%;
    border-radius: 20px;
    margin: auto 1%;
    transition: 3.s;
    transition: .6s;

    @media(min-width: 1440px){
        padding: 40px !important;
        height: 450px !important;
    }

    @media(min-width: 1400px){
        padding: 35px !important;
        height: 400px !important;
    }


    @media(min-width: 1300px){
        padding: 30px;
        height: 370px;
    }

    @media(max-width: 1000px){
        margin: 3% auto;
        padding: 30px !important;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    }

    @media(max-width: 650px){
        margin: 3% auto !important;
    }


    :hover {
        transition: .6s;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    }

    & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 26%;
    }

    button:hover {
        transition: .3s;
        background-color: var(--blue);
    }

    @media(max-width: 1000px) {
        width: 90%;
    }

    .div-text {
        margin-top: 5%;
        margin-bottom: 5%;
    }
`;

export const Title = styled.h4`
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.02em;
    margin-left: 10%;
    margin-right: 10%;
    @media(max-width: 1400px) {
        font-size: 20px !important;
    }
`;

export const Paragraph = styled.p`
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    display: flex;
    text-align: center;
    letter-spacing: -0.02em;

    @media(min-width: 1500px){
        height: 100px !important;
        font-size: 18px !important;
    }

    @media(max-width: 1400px){
        height: 150px;
        font-size: 16px !important;
    }

    @media(max-width: 1130px){
        font-size: 15px;
    }

    @media(max-width: 1000px){
        font-size: 23px !important;
    }

    @media(max-width: 480px){
        font-size: 16px !important;
    }

    @media(max-width: 300px){
        font-size: 13px !important;
    }
`;

