import styled, { css } from 'styled-components'
import { darkBlue } from '../../ThemeStyles'

export const ProductsImageContainer = styled.div`
    height: 300px;
    width: 450px;
    -webkit-border-radius: 20px !important;
    -moz-border-radius: 20px !important;
    border-radius: 40px;
    background-image: url(${ props => props.image });
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;
`;

export const ProductContainerStyle = css`
        margin: 5% auto;
        border-radius: 20px;
        padding: 3% 3%;

        @media (min-width: 1000px){
            ${ darkBlue } 
            width: 80%;
            background-attachment: fixed;
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: 50%;
        }

        @media (min-height: 901px){
            height: 75vh;
        }

        @media (max-height: 900px){
            height: 95vh;
            width: 80%;
        }

        @media (max-width: 999px){
            width: 100% !important;
            margin-bottom: 20% !important;
        }

        .customer {
            color: var(--primary) !important;
        }

        span {
            color: white;
            margin-left: 2%;
        }

       .buttonContainer {
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            width: 85%;
            height: 13%;
            & > div {
                width: 100%;
                padding: 1%;
                @media (max-width: 1090px){
                    & > button {
                    font-size: 10pt;
                    }
                }  
            }
            @media (max-width: 1000px){
                display: none;
            }
        }

       .changeButton { cursor: pointer; }

       .content {
            display: flex;
            flex-direction: col;
            margin-top: 5%;
            align-items: center;
            height: 55%;

            @media (max-width: 1000px){
                display: none;
            }

            div {
                width: 50%;
                display: flex;
                justify-content: center;
                ul {
                    list-style: none;    
                    @media(min-width: 1440px){
                        margin-left: 10%;
                    }
                }

                ul li {
                    margin: 3%;
                    display: flex;
                    align-items: center;

                    h3 {
                        font-size: 40px;
                        color: white;
                    }                 
                }
            }
       }

       .bottomContainer {
            display: flex;
            justify-content: center;
            margin-top: 5%;
            height: 10%;
            @media (max-width: 1000px){
                display: none;
            }

            button {
                margin: 0% 2%;
                transition: .3s;
                border: 3px solid var(--primary);
                &:hover {
                    background-color: var(--blue);
                    border: none;
                    transition: .3s;
                    color: var(--white);
                }

            }

            button:nth-child(1) {
                font-weight: 700;
                :hover {
                    transition: .3s;
                    border: 3px solid var(--primary);
                    color: var(--primary) !important;
                    background-color: #fff !important;
                }
    
            }
        }

        .mobile {
            position: relative;
            display: flex;
            flex-direction: row;        
            justify-content: space-between;  
            
            & > div {
                height: 100vh;
                width: 100%;
            }

            @media (min-width: 1000px){
                display: none !important;
            }

            .slide-inner {
                padding: 0rem !important;
            }

            /* .sc-gsnTZi {
                position: absolute;
            }

            .fMbSfx {
                width: 95%;
            }


            .slide-outer {
                .iIypZI div {
                    padding: 0;
                }
            }

            .slide-outer:first-child {
                margin-right: -15% !important;
                margin-left: -5% !important;
            }

            .slide-outer:nth-child(2) {
                margin-left: 6%;
                margin-right: -15% !important;
            }

            .slide-outer:nth-child(3) {
                margin-left: 15%;
                margin-right: -15% !important;
            }

            .slide-outer:nth-child(4) {
                margin-left: 15%;
                margin-right: -28% !important;
            } */

            .card {
                z-index: 20;
                ${ darkBlue }
                border-radius: 20px;
                width: 80%;
                padding: 18px 10px;

                img {
                    width: 100% !important;
                }
                li {
                    margin: 3% 4%;
                    color: #fff;
                    list-style: none;
                    display: flex;
                    align-items: center;
                    @media (max-width: 600px){
                        font-size: 10pt;
                    }

                    h3 {
                        font-size: 20pt;
                        color: #fff;
                    }

                    h4 {
                        font-size: 17pt;
                        color: #fff;
                    }

                    @media (max-width: 600px){
                        h3 {
                            font-size: 16pt;
                            color: #fff;
                        }

                        h4 {
                            font-size: 12pt;
                            color: #fff;
                        }
                    }
                }

                .imageMobileContainer {
                    display: flex;
                    justify-content: center;
                }

                .imageProducts {
                    width: auto;
                    max-width: 312px;
                    max-height: 200px; 
                }

                .buttonMobile {
                    display: flex;
                    flex-direction: column;
                    margin-top: 10%;
                    height: 15vh;
                }
            }
        }
`

