import { Container, ImageContainerDiv } from "./styles";

export default function ContentContainer({ title, options, image, direction, svg }) {
    return (
        <Container direction={direction}>
            <div className="listContainer">
                <h3>{title}</h3>
                <ul>
                    {options.map((option, index) => {
                        return (
                            <li key={index}>
                                <div className="icon">
                                    {svg}
                                </div>
                                <div className="listContent">
                                    <span>{option}</span>
                                </div>
                            </li>
                        )
                    })}
                </ul>
            </div>

            <ImageContainerDiv image={image}>
            </ImageContainerDiv>
        </Container>
    )
}