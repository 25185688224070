import { TitleFlex } from "./styles"

export default function MainContainer({ title }) {
    return (
        <TitleFlex>
            <h2>
                {title}
            </h2>
        </TitleFlex>
    );
}