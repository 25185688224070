
import image01 from '../../assets/images/Home/Products/visao.jpeg';
import image02 from '../../assets/images/Home/Products/EyecareBi.png';
import image03 from '../../assets/images/Home/Products/Glauco.png';
import image04 from '../../assets/images/Home/Products/teleoftal.png';


export default [
    {
        "name": "Benefício Visão",
        "customer": "Para empresas",
        "title": "Saúde ocular 24/7 para a sua equipe",
        "image": image01,
        "page": "/produtos/empresas",
        "contract": "/produtos/empresas/quero-contratar?type=hospital",
        "features": [
            "O maior benefício de saúde ocular do Brasil",
            "Triagem e acompanhamento contínuo",
            "Clínica digital acessível de qualquer lugar"
            
        ]
    },
    {

        "name": "Eyecare BI",

        "customer": "Para clínicas e hospitais",
        "title": "Plataforma exclusiva para oftalmologia",
        "image": image02,
        "page": "/produtos/empresas/clinicas-e-hospitais",
        "contract": "/produtos/empresas/quero-contratar?type=hospital",
        "features": [
            "Protocolos e bancos de dados oftamológicos",
            "Integração com controle de estoque e financeiro",
            "Acesse a qualquer momente, de qualquer lugar"
            
        ]
    },
    {
        "name": "Glauco",
        "customer": "Para médicos e pacientes",
        "title": "Saúde ocular na palma da mão",
        "image": image03,
        "page": "/produtos/meu-cuidado-ocular",
        "contract": "/produtos/meu-cuidado-ocular/quero-contratar",
        "features": [
            "Assistente virtual dedicado a saúde ocular",
            "Acompanhamento contínuo do tratamento",
            "Transparência na relação médico-paciente"
            
        ]
    },
    {
        "name": "Teleoftamologia",
        "customer": "Para pacientes",
        "title": "Acesse especialistas de onde você estiver",
        "image": image04,
        "page": "/produtos/meu-cuidado-ocular",
        "contract": "/produtos/meu-cuidado-ocular/quero-contratar",
        "features": [
            "Médicos treinados em telemedicina",
            "Agendamento fácil pelo whatsapp",
            "Seja atendido com comodiade e segurança"
            
        ]
    }
]