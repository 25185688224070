import valor from '../../assets/images/AboutUs/News/valor.png';
import uol from '../../assets/images/AboutUs/News/uol.png';
import universo from '../../assets/images/AboutUs/News/universo.png';

export default [
    {
        "text": "Eyecare detecta vista cansada do “home office”",
        "image": valor,
        "link": "https://valor.globo.com/empresas/noticia/2021/12/30/eyecare-detecta-vista-cansada-do-home-office.ghtml"
    },
    {
        "text": "Zeiss lança a ação beneficente “Foco na Infância”",
        "image": uol,
        "link": "https://glamurama.uol.com.br/agenda/zeiss-lanca-a-acao-beneficente-foco-na-infancia/"
    },
    {
        "text": "Healthtechs: soluções que otimizam e ampliam o [...]",
        "image": universo,
        "link": "https://universovisual.com.br/secaodesktop/materias/839/healthtechs-solucoes-que-otimizam-e-ampliam-o-sistema-de-saude"
    }
]