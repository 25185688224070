import { useState } from 'react'
import Logo from '../Logo'
import Button from '../Button'
import { GoChevronDown, GoThreeBars as Menu } from 'react-icons/go'
import { MdClose as Close } from 'react-icons/md'
import { BiPlusCircle as Plus, BiMinusCircle as Minus } from 'react-icons/bi'
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

import styled from 'styled-components'
import { NavStyle } from './styles'

const NavMenu = styled.nav`${NavStyle}`

const NavBar = styled.div`
    transition: all 300ms ${props => props.scrolled ? 'ease-in' : 'ease-out'};
    ${props => props.scrolled ? `
        background-color: #FFF;
        box-shadow: 1px 1px 10px -2px rgba(0,0,0,0.5);
    ` : ''}
`;

export default function Navbar() {

    const [openedMenu, setOpenedMenu] = useState(false);
    const [openedProducts, setOpenedProducts] = useState(false);
    const [openedContent, setOpenedContent] = useState(false);
    const [openedPatients, setOpenedPatients] = useState(false);
    const [scrolled, setScrolled] = useState(false);

    useScrollPosition(({ prevPos, currPos }) => {
        if (Math.abs(currPos.y) > 20 && !scrolled) {
            setScrolled(true);
        }
        else if (Math.abs(currPos.y) <= 20 && scrolled) {
            setScrolled(false);
        }
    });


    return (
        <NavMenu>
            <NavBar scrolled={scrolled} className='desktop' >
                <ul>
                    <li onClick={() => window.location.href = '/'}>
                        <Logo
                            color="#305BF2"
                            width={150}
                            height={30}
                        />
                    </li>
                    <li>
                        <div className="dropdown">
                            <button className="dropbtn">Produtos <GoChevronDown className='icon' /></button>
                            <div className="dropdown-content">
                                <a href="https://conteudo.eyecarehealth.com.br/beneficio.visao">Para empresas</a>
                                <a href="/produtos/empresas/clinicas-e-hospitais">Para clínicas e hospitais</a>
                                <a href="/produtos/meu-cuidado-ocular">Para o meu cuidado ocular</a>
                            </div>
                        </div>
                    </li>
                    <li>
                        <a href="/sobre-nos">Sobre nós</a>
                    </li>
                    <li>
                        <div className="dropdown">
                            <button className="dropbtn">Conteúdo <GoChevronDown className='icon' /></button>
                            <div className="dropdown-content">
                                <a href="https://blog.eyecarehealth.com.br/" target="_blank" rel="noreferrer">
                                    Blog
                                </a>
                            </div>
                        </div>
                    </li>
                </ul>

                <ul className="rightNavMenu">
                    <li>
                        <div className="dropdown">
                            <button className="dropbtn">
                                <span className="blue">
                                    Acesso Pacientes <GoChevronDown className='icon' fill="#305BF2" />
                                </span>
                            </button>
                            <div className="dropdown-content">
                                <a href="/" className="blue">Exames <span className='tag'>Em breve</span></a>
                                <a className="blue" href="https://teleoftalmologia.eyecarehealth.com.br/" target="_blank" rel='noreferrer'>
                                    Teleoftalmologia
                                </a>
                            </div>
                        </div>
                    </li>
                    <li>
                        <a className='blue' href="https://eyecarebi.eyecarehealth.com.br/" target="_blank" rel="noreferrer">
                            Acesso Médicos
                        </a>
                    </li>

                    <li className='buttonContainer'>
                        <Button
                            text="Quero contratar"
                            theme="primary"
                            width="90%;"
                            height="50%"
                            route="/produtos/empresas/quero-contratar?type=hospital"
                        />
                    </li>

                </ul>
            </NavBar>
            <NavBar scrolled={scrolled} className='mobile'>
                <nav>
                    <ul className='topMenu'>
                        <li onClick={() => {
                            window.location.href = '/'; 
                            setOpenedMenu(false)
                                }
                            }
                        >
                            <Logo
                                color="#305BF2"
                                width={120}
                                height={30}
                            />
                        </li>
                        <li>
                            <button onClick={() => setOpenedMenu(!openedMenu)}>
                                {!openedMenu ? (
                                    <Menu
                                        fill="#305BF2"
                                        size={25}
                                        className
                                    />
                                ) : (
                                    <Close
                                        fill="#305BF2"
                                        size={25}
                                    />
                                )}
                            </button>
                        </li>

                    </ul>

                    <ul className={openedMenu ? 'show' : 'hidden'}>
                        <div className="mobileOption">
                            <li className='option'>
                                Produtos
                                <button onClick={() => setOpenedProducts(!openedProducts)}>
                                    {!openedProducts ? (
                                        <Plus
                                            fill="#305BF2"
                                            size={20}
                                        />
                                    ) : (
                                        <Minus
                                            fill="#305BF2"
                                            size={20}
                                        />
                                    )}
                                </button>
                            </li>
                            {openedProducts && (
                                <ul className="optionsSubmenu">
                                    <li onClick={() => setOpenedMenu(false)} ><a href="https://conteudo.eyecarehealth.com.br/beneficio.visao">Para empresas</a></li>
                                    <li onClick={() => setOpenedMenu(false)} ><a href="/produtos/empresas/clinicas-e-hospitais">Para clínicas</a></li>
                                    <li onClick={() => setOpenedMenu(false)} ><a href="/produtos/meu-cuidado-ocular">Para o meu cuidado ocular</a></li>
                                </ul>
                            )
                            }

                        </div>

                        <div className='mobileOption'>
                            <li className='option' onClick={() => setOpenedMenu(false)} >
                                <a href="/sobre-nos">Sobre nós</a>
                            </li>
                        </div>

                        <div className='mobileOption'>
                            <li className='option' >
                                Conteúdos
                                <button onClick={() => setOpenedContent(!openedContent)}>
                                    {!openedContent ? (
                                        <Plus
                                            fill="#305BF2"
                                            size={20}
                                        />
                                    ) : (
                                        <Minus
                                            fill="#305BF2"
                                            size={20}
                                        />
                                    )}
                                </button>
                            </li>
                            {openedContent && (
                                <ul className="optionsSubmenu">
                                    <li onClick={() => setOpenedMenu(false)} >
                                        <a href="https://blog.eyecarehealth.com.br/" target="_blank" rel="noreferrer">
                                            Blog
                                        </a>
                                    </li>
                                </ul>
                            )
                            }
                        </div>

                        <div className='mobileOption'>
                            <li className='option' >
                                <span className='blue'>Acesso Pacientes</span>
                                <button onClick={() => setOpenedPatients(!openedPatients)}>
                                    {!openedPatients ? (
                                        <Plus
                                            fill="#305BF2"
                                            size={20}
                                        />
                                    ) : (
                                        <Minus
                                            fill="#305BF2"
                                            size={20}
                                        />
                                    )}
                                </button>
                            </li>
                            {openedPatients && (
                                <ul className="optionsSubmenu">
                                    <li onClick={() => setOpenedMenu(false)} ><a href="/" className="blue">Exames <span className='tag'>Em breve</span></a></li>
                                    <li onClick={() => setOpenedMenu(false)} >
                                        <a className="blue" href="https://teleoftalmologia.eyecarehealth.com.br/" target="_blank" rel='noreferrer'>
                                            Teleoftalmologia
                                        </a>
                                    </li>
                                </ul>
                            )
                            }
                        </div>

                        <div className='mobileContractButton' onClick={() => setOpenedMenu(!openedMenu)}>
                            <Button
                                text="Quero contratar"
                                theme="primary"
                                width="90%;"
                                height="50%"
                                route="/produtos/empresas/quero-contratar?type=hospital"
                                
                            />
                        </div>
                    </ul>
                </nav>
            </NavBar>
        </NavMenu>
    )
}