import styled from "styled-components"

export const FormStyled = styled.div`
    // background: var(--secondary);
    background: #fff;
    border-radius: 20px;
    padding: 20px;

    @media(max-width: 1000px) {
        width: 90%;
        margin-top: -10%;
    }

    form {
        padding: 10px;

        label {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 130%;
            letter-spacing: -0.02em;
            color: var(--dark);
        }

        .label-check {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 130%;
            letter-spacing: -0.02em;
            color: var(--dark);
            margin-left: 2%;
            @media(max-width: 1000px) {
                font-size: 14px;
            }
        }

        .div-check {
            margin-top: 5%;
        }

        input[type=text], input[type=email], select, textarea {
            width: 100%;
            padding: 12px;
            border: 1px solid #ffff;
            border-radius: 5px; 
            box-sizing: border-box;
            margin-top: 6px;
            margin-bottom: 16px; 
            background: #FFFFFF;
        }

        .select2 {
            margin-top: 6px;
            margin-bottom: 16px;
        }

        [type=checkbox] {
            border: 2px solid #305BF2;
            box-sizing: border-box;
            border-radius: 4px;
            width: 1.5rem;
            height: 1.5rem;
            color: dodgerblue;
            vertical-align: middle;
            -webkit-appearance: none;
            transition: 300ms;
            cursor: pointer;
        }

        [type=checkbox]:checked {
            background-color: currentcolor;
            -webkit-appearance: checkbox;
        }

        p {
            margin-top: 5%;
            margin-bottom: 5%;
            color: var(--brand-color-lighten-1);
            font-size: 14px;
            line-height: 130%;
            text-align: justify;
            letter-spacing: -0.02em;
        }

        button {
            cursor: pointer !important;
        }

        button:last-child {
            @media(max-width: 1000px) {
                margin: 0 auto;
                margin-top: 20% !important;
            } 
        }

        .container-main {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 2%;
            button {
                margin-top: 0% !important;
                margin-bottom: 2% !important;
                padding: 10px;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 150%;
                letter-spacing: -0.02em;
                border: 2px solid #305BF2;
                box-sizing: border-box;
                border-radius: 5px;
                width: 100%;
            }
        }

        .container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            border: 1px solid var(--primary);
            border-radius: 5px;
            margin-top: 2%;
            @media(max-width: 1000px) {
                border: none;
            }
            div {
                width: 100%;
            }
        }

        .container-finish-button {
            display: flex;
            flex-direction: column;
            justify-content: center;
            button {
                margin: 0 auto !important;
                margin-top: 5% !important;
                width: 40%;
                @media(max-width: 1000px) {
                    width: 80%;
                }
            }
        }
    }
`;

export const ButtonFormStyled = styled.input`
    background-color: var(--primary);
    color: #fff;
    width: 221px;
    height: 53px;
    font-weight: 500;
    font-size: 16px;
    border-radius: 50px;
    height: 53px !important;
    border: none;
    cursor: pointer;
    margin: 0 auto;
`;