import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { FormStyled, ButtonFormStyled } from "./styles";
import { PhoneInput } from "../PhoneInput";
import Select2 from "../Select2";

export default function FormB2C() {
    const [nome, setNome] = useState('');
    const [email, setEmail] = useState('');
    const [telefone, setTelefone] = useState('');
    const [empresa, setEmpresa] = useState('');
    const [objetivo, setObjetivo] = useState('');
    const [sobre, setSobre] = useState('');
    const [aceitoEmail, setAceitoEmail] = useState('');

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://d335luupugsy2.cloudfront.net/js/loader-scripts/043ddeca-3c0a-4c3a-89ab-10ffd3c04f6e-loader.js';
        script.async = true;
        document.body.appendChild(script);
    }, []);

    async function handleSubmit(e) {
        e.preventDefault();
        setNome('');
        setEmail('');
        setTelefone('');
        setEmpresa('');
        setSobre('');
        setAceitoEmail(false);
        toast.success('Solicitação cadastrada com sucesso');
        window.location.href = '/cadastro-confirmado/eyecarebi';
    }
    return (
        <FormStyled>
            <form className="" onSubmit={handleSubmit}>
                <label htmlFor="nome">Nome completo</label>
                <input type="text" id="nome" name="nome" value={nome}
                    onChange={e => setNome(e.target.value)} required />

                <label htmlFor="email">Email</label>
                <input type="email" id="email" name="email" value={email}
                    onChange={e => setEmail(e.target.value)} required />

                <label htmlFor="telefone">Telefone para contato</label>
                <PhoneInput setProps={setTelefone} value={telefone} />

                <label htmlFor="nome_empresa">Nome da empresa</label>
                <input type="text" id="nome_empresa" value={empresa}
                    onChange={e => setEmpresa(e.target.value)} name="nome_empresa" required />

                <label htmlFor="objetivo">Objetivo</label>
                <Select2 name="objetivo" setProps={setObjetivo} value={objetivo} options={[{ value: 'Melhorar a gestão da minha clínica ou hospital', label: 'Melhorar a gestão da minha clínica ou hospital' }, { value: 'Agendar demonstração', label: 'Agendar demonstração' }]} />

                <label htmlFor="sobre">Quer nos contar mais sobre isso?</label>
                <textarea name="sobre" value={sobre}
                    onChange={e => setSobre(e.target.value)} id="sobre" cols="30" rows="10"></textarea>

                <div className="div-check">
                    <input type="checkbox" name="opt-in" id="" className="checkbox" value={aceitoEmail} onChange={() => aceitoEmail ? setAceitoEmail(false) : setAceitoEmail(true)} />
                    <label htmlFor="scales" className="label-check">Aceito receber e-mails sobre novidades da Eyecare Health.</label>
                </div>
                <div>
                    <p>
                        Ao clicar no botão abaixo, você permite que a Eyecare Health armazene e processe as informações submetidas para envio do conteúdo solicitado.
                    </p>
                </div>
                <div className="container-finish-button">
                    <ButtonFormStyled id="form-b2c" type="submit" value="Falar com um consultor" />
                </div>
            </form>
        </FormStyled >
    );
}