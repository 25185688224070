import styled from "styled-components";

export const MainStyled = styled.main`
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
`;

export const SectionStyled = styled.section`
    padding: 2rem;
    max-width: 800px;
`;

export const VersionStyled = styled.div`
    font-size: 0.8rem;
    color: #666;
    margin-bottom: 2rem;
`;

export const TitleStyled = styled.div`
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
`;

export const TableStyled = styled.table`
    border-collapse: collapse;
    border: 1px solid;    
`;

export const TrHeaderStyled = styled.tr`
    border: 1px solid; 
    padding: 0.75rem;
    font-weight: bold;
    background-color: #F5F5F5;
`;

export const TrStyled = styled.tr`
    border: 1px solid;    
    padding: 0.75rem;
`;

export const TdStyled = styled.td`
    border: 1px solid;  
    padding: 0.75rem;  
`;

export const ListStyled = styled.ul` 
    margin-bottom: 1rem;
`;
