import { TitleStyled, StyledCard } from "./styles";
import Button from "../Button";
import Typewriter from "typewriter-effect";

export default function CardDynamicMessage() {
    return (
        <StyledCard>
            <div className="title">
                <TitleStyled>
                    Veja de perto o que podemos oferecer para 
                    <Typewriter options={{
                        strings: [' o seu consultório',
                        ' a sua empresa', ' o seu hospital', ' a sua clínica', ' a sua organização'],
                        autoStart: true,
                        loop: true,
                    }}
                    />
                </TitleStyled>
            </div>
            <div className="div-button">
                <Button
                    id="quero-contratar-cdm"
                    text='Quero contratar'
                    theme='primary'
                    width="180px"
                    height="53px"
                    route="/produtos/empresas/quero-contratar?type=hospital"
                />
            </div>
        </StyledCard>
    );
}