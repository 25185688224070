import styled from "styled-components";


export const Divider = styled.div`
    margin: 5% auto;
    width: 80%;
    text-align: center;
    margin-top: 10%;
    margin-bottom: 10%;

    @media (max-width: 1200px){
        .desktopMap { display: none }
        .mobileMap {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;

            .mapTitle {
                margin: 15% 0%;
            }
        }
    }

    @media (min-width: 1201px){
        .mobileMap { display: none }
    }
`;
