import styled from 'styled-components';

export const PlansCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 5% 3%;
    border-radius: 20px;
    align-items: center;
    margin: 0% 1%;
    margin-bottom: 4% !important;
    height: 500px;
    transition: .6s;

    @media(min-width: 1600px){
        height: 600px;
    }

    @media(max-width: 1600px){
        height: 520px;
        margin: 5% 1%;
    }

    :hover {
        transition: .6s;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    }

    .quantity {
        color: var(--primary);
        background-color: var(--brand-color-extra-light-2);
        border-radius: 20px;
        padding: 3% 5%;
        margin-bottom: 5%;
    }

    h4 {
        font-size: 40px;
        font-weight: 800;
    }

    p {
        font-size: 18px;
        font-weight: 800;
    }

    ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        margin: 10% auto;
        li {
            display: flex;
            flex-direction: row !important;
            align-items: center;
            margin: 2% 0%;

            > div {
                margin: 0% 1%;
            }
        }
    }

    button {
        transition: .3s;
        :hover {
            transition: .3s;
            background-color: var(--blue);
        }
    }
`;