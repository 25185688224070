import styled from 'styled-components'
import { primary, secondary, light, darkBlue, transparent, transparentBlue } from '../../ThemeStyles'
import { ButtonStyle } from './styles'
// import { useRouter } from 'next/router'

export default function Button({ id, text, theme, width, height, route, padding }) {
    // const router = useRouter()

    function pushRoute(e) {
        if (route) {
            e.preventDefault();
            window.location.href = route;
        }
        return
    }

    const ButtonComponent = styled.button`
        ${theme === 'primary' && primary}
        ${theme === 'secondary' && secondary}
        ${theme === 'darkBlue' && darkBlue}
        ${theme === 'light' && light}
        ${theme === 'transparent' && transparent}
        ${theme === 'transparentBlue' && transparentBlue}
        width: ${width !== null && width};
        height: ${height !== null && height};
        padding: ${padding || 0};
        ${ButtonStyle}
    `

    return (
        <ButtonComponent id={id} theme="primary" onClick={pushRoute}>
            {text}
        </ButtonComponent>
    )
}