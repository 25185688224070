import React from 'react';
import SuccessIcon from '../../assets/icons/success.svg'
import Button from '../../components/Button';
import { ContainerStyled, TitleStyled, SubTitleStyled } from './styles';

const SuccessFormBV = () => {
    return (
        <ContainerStyled>
            <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 16.0003C0 7.16359 7.16359 0 16.0003 0H32.0007C40.8374 0 48.001 7.16359 48.001 16.0003V32.0007C48.001 40.8374 40.8374 48.001 32.0007 48.001H16.0003C7.16359 48.001 0 40.8374 0 32.0007V16.0003Z" fill="#DBFFF0"/>
                <path d="M8.57159 16.2867C8.57159 12.0261 12.0255 8.57227 16.286 8.57227H31.7149C35.9755 8.57227 39.4294 12.0261 39.4294 16.2867V31.7156C39.4294 35.9762 35.9755 39.43 31.7149 39.43H16.286C12.0255 39.43 8.57159 35.9762 8.57159 31.7156V16.2867Z" fill="#00C773"/>
                <path d="M27.0006 21.8586L22.7148 26.1487L21.429 24.863" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <TitleStyled>Solicitação enviada com sucesso!</TitleStyled>
            <SubTitleStyled>Agradecemos pelo seu tempo, em breve entraremos em contato.</SubTitleStyled>
            <Button 
                theme="primary"
                route="/" 
                text="Voltar para o início"
                padding="0 20px"
            />
        </ContainerStyled>
    )
}

export default SuccessFormBV
