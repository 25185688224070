import { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import { useRouter } from "next/router";

import testimoninial from "../../utils/json/testimonial.json";
import Testimonial from "../Testimonial";

export default function CarouselAutomatic() {
    // const router = useRouter();
    const [color, setColor] = useState('#FFCC18');

    // useEffect(() => {
    //     setColor(() => {
    //         if (router.pathname === '/produtos/empresas') {
    //             return '#FFCC18';
    //         } else if(router.pathname === '/produtos/empresas/clinicas-e-hospitais') {
    //             return '#FF6B00';
    //         }
    //         return '#305BF2';
    //     });
    // }, [router, color]);

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 3
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (

        <Carousel responsive={responsive} infinite={true} autoPlay={true} autoPlaySpeed={10000}
            keyBoardControl={false}
            removeArrowOnDeviceType={["tablet", "mobile", "desktop", "superLargeDesktop"]}
        >
            {testimoninial.map((testimoninial, key) => {
                return (
                    <div key={key}>
                        <Testimonial svg={<svg width="40" height="60" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.1688 0L16.1497 2.5C14.0796 5.5 12.7134 7.75 12.051 9.25C11.1401 10.9167 10.5191 12.5 10.1879 14C11.3471 14.1667 12.3408 14.4583 13.1688 14.875C14.0796 15.375 14.9076 16.125 15.6529 17.125C16.5637 18.375 17.0191 20 17.0191 22C17.0191 23.5 16.6051 25 15.7771 26.5C14.2038 28.8333 12.0096 30 9.19427 30C6.71019 30 4.64013 29.0417 2.98408 27.125C1.32803 25.125 0.5 22.75 0.5 20C0.5 13.4167 3.43949 6.75 9.31847 0H13.1688ZM35.6497 0L38.6306 2.5C36.5605 5.5 35.1943 7.75 34.5318 9.25C33.621 10.9167 33 12.5 32.6688 14C33.7452 14.1667 34.6975 14.4583 35.5255 14.875C36.4363 15.375 37.2229 16.0417 37.8854 16.875C38.9618 18.2083 39.5 19.875 39.5 21.875C39.5 23.4583 39.086 25 38.258 26.5C36.6019 28.8333 34.4076 30 31.6752 30C29.1911 30 27.121 29.0417 25.465 27.125C23.8089 25.2083 22.9809 22.8333 22.9809 20C22.9809 13.4167 25.9204 6.75 31.7994 0H35.6497Z" fill={color} />
                        </svg>} text={testimoninial.text}

                            // photo={<img src={testimoninial.photo} 
                            //             alt="Perfil" 
                            //             width={100} 
                            //             height={100} 
                            //             className="img">
                            //         </img>} 
                            name={testimoninial.name} title={testimoninial.title} />

                    </div>
                );
            })}
        </Carousel>
    );
}